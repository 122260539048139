import { styled } from "@mui/material";
import variableStyles from "../../../../theme/variable-styles";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  padding: "12px",
  margin: "10px 8px 0 0",
  height: "calc(100vh - 85px)",
  borderRadius: "4px",
  overflow: "auto",

  "& .information": {
    display: "flex",
    flexDirection: "column",
    margin: "14px 0",
    alignItems: "center",
    "& .information-name": {
      display: "flex",
      flexDirection: "column",
      marginTop: "14px",
      alignItems: "center"
    }
  },

  "& .info-row": {
    marginBottom: "12px",
    "& .info-title": {
      display: "flex",
      svg: {
        width: "20px",
        height: "20px",
        marginRight: "12px",
        color: "GrayText"
      },
      p: {
        color: "GrayText",
        fontSize: "11px",
        fontWeight: "500",
        padding: "auto 0"
      }
    },
    "& .info-content": {
      marginLeft: "32px",
      fontSize: "13px"
    }
  },
  button: {
    marginBottom: "12px",
    borderRadius: "6px",
    fontWeight: "500"
  },
  "& .react-datepicker__input-container": {
    height: "20px"
  }
}));
