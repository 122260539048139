import { useEffect, useMemo, useState } from "react";
import { BoardProjectWrapper } from "./styles";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";

import StageColumn from "./components/StageColumn/stage_column";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import TaskBox from "./components/TaskBox/task_box";
import { createPortal } from "react-dom";
import ActionProject from "../ActionProject";
import CreateStageModal from "../CreateStageModal/create_stage_modal";
import ManageDragModal from "../ManageDragModal";
import AdvancedOptionsModal from "../AdvancedOptionsModal";
import { CancelDropArguments } from "@dnd-kit/core/dist/components/DndContext/DndContext";
import {
  IRoleBoard,
  IStage,
  IStageByWorkflowResponse
} from "../../../models/Stage";
import AddIcon from "@mui/icons-material/Add";
import { ITaskCard } from "../../../models/Task";
import { IWorkflow } from "../../../models/workflow";
import StageService from "../../../services/api/stage.service";
import { Box, Typography } from "@mui/material";
import {
  EStageType,
  EWorkflowProcess
} from "../../../models/common/models.enum";
import { Modal } from "../../../components";
import useAxios from "../../../components/UseAxios/useAxios";
import { toggleMessage } from "../../../components/Toast/Toast";

interface Props {
  workflow: IWorkflow;
  role: IRoleBoard;
  stages: IStage[];
  tasks: ITaskCard[];
  onReload: () => void;
  onCloseWorkflow: (id: string) => void;
  onUpdateStagePosition: (currentPosition: IStage, newPosition: IStage) => void;
  onUpdateTaskPosition: (
    currentTask: ITaskCard,
    newPosition: IStage,
    index: number
  ) => void;
  onStagesChanged: (newStages: IStage[]) => void;
  onTasksChanged: (newTasks: ITaskCard[]) => void;
}

interface PositionCreateState {
  stageSelected?: IStage;
  isUpdate?: boolean;
  side: number;
}

const BoardProject = ({
  workflow,
  stages,
  tasks,
  role,
  onStagesChanged,
  onTasksChanged,
  onReload,
  onCloseWorkflow,
  onUpdateStagePosition,
  onUpdateTaskPosition
}: Props) => {
  const [activeStage, setActiveStage] = useState<IStage | null>(null);
  const [activeTask, setActiveTask] = useState<ITaskCard | null>(null);
  const [tasksLastChanged, setTasksLastChanged] = useState<ITaskCard[] | null>(
    null
  );
  const [openCreateStage, setOpenCreateStage] = useState(false);
  const [openUpdateStage, setOpenUpdateStage] = useState(false);
  const [positionSelectedStage, setPositionCreateStage] =
    useState<PositionCreateState>({ side: 0 });
  const [openManageDragStage, setOpenManageDragStage] = useState(false);
  const [openAdvancedOptions, setOpenAdvancedOptions] = useState(false);
  const [deleteStageDialog, setDeleteStageDialog] = useState<{
    open: boolean;
    stage: IStage | null;
  }>({ open: false, stage: null });

  const deleteStage = useAxios<string>({ loading: "OnRequest" });

  const hasStepStage = useMemo(
    () => stages.find(e => e.type === EStageType.Step),
    [stages]
  );
  const stageIdByTaskId = useMemo(
    () => new Map(tasks.map(i => [i.id, i.workflowId])),
    [tasks]
  );

  const stagesId = useMemo(
    () =>
      stages
        .filter(stage => stage.type === EStageType.Step)
        .map(stage => stage.id),
    [stages]
  );

  // Stage ids disable dnd
  const disabledColumnIds = useMemo(
    () => stages.filter(e => e.type !== EStageType.Step).map(stage => stage.id),
    [stages]
  );
  // Stage ids cannot be over by other tasks or stages
  const disabledStageIds = useMemo(() => {
    if (activeStage) {
      return disabledColumnIds;
    }
    if (activeTask) {
      const stageIndex = stages.findIndex(e => e.id === activeTask.workflowId);
      const disabledIds = stages
        .slice(Math.min(stageIndex + 2, stages.length), stages.length)
        .map(e => e.id);

      return disabledIds;
    }
    return [];
  }, [activeStage, activeTask]);

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 3 } })
  );

  const onDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === "Stage") {
      setActiveStage(event.active.data.current?.stage);
      return;
    }
    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current?.task);

      return;
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    setActiveTask(null);
    setActiveStage(null);
    setTasksLastChanged(null);
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id;
    const overStageId = over.id;

    if (activeId === overStageId) return;

    const isActiveAStage = active.data.current?.type === "Stage";
    const isActiveATask = active.data.current?.type === "Task";

    if (isActiveAStage) {
      const activeStage = active.data.current!.stage;
      const newPosition = over.data.current!.stage;

      const activeIndex = stages.findIndex(stage => stage.id === activeId);
      const overIndex = stages.findIndex(stage => stage.id === overStageId);
      onStagesChanged(arrayMove(stages, activeIndex, overIndex));
      onUpdateStagePosition(activeStage, newPosition);
    }
    if (isActiveATask) {
      const activeTask = active.data.current!.task as ITaskCard;
      const newStage =
        over.data.current!.type === "Stage"
          ? (over.data.current!.stage as IStage)
          : stages.find(e => e.id === activeTask.workflowId)!;

      const activeIndex = tasks.findIndex(task => task.id === activeId);
      var index = 0;
      for (const task of tasks) {
        if (task.id === tasks[activeIndex].id) break;

        index =
          index + (task.workflowId === tasks[activeIndex].workflowId ? 1 : 0);
      }

      if (JSON.stringify(tasksLastChanged) == JSON.stringify(tasks)) return;

      onUpdateTaskPosition(activeTask, newStage, index);
    }
  };

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) return;
    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";

    if (!isActiveATask) return;

    const activeIndex = tasks.findIndex(task => task.id === activeId);

    if (isActiveATask && isOverATask) {
      if (
        stageIdByTaskId.get(overId) &&
        disabledStageIds.includes(stageIdByTaskId.get(overId)!)
      )
        return;
      if (!tasksLastChanged)
        setTasksLastChanged([...tasks].map(e => Object.assign({}, e)));

      const overIndex = tasks.findIndex(task => task.id === overId);
      tasks[activeIndex].workflowId = tasks[overIndex].workflowId;

      onTasksChanged(arrayMove(tasks, activeIndex, overIndex));
    }
    const isOverAStage = over.data.current?.type === "Stage";

    if (isActiveATask && isOverAStage) {
      if (disabledStageIds.includes(overId as number)) return;

      if (!tasksLastChanged)
        setTasksLastChanged([...tasks].map(e => Object.assign({}, e)));

      tasks[activeIndex].workflowId = overId;
      onTasksChanged(arrayMove(tasks, activeIndex, activeIndex));
    }
  };

  const onCancelDrop = (args: CancelDropArguments) => {
    if (args.over) {
      const overId = args.over.id as number;
      const isOverADisabledTask = args.over.data.current?.type === "Task";

      if (
        isOverADisabledTask &&
        disabledStageIds.includes(stageIdByTaskId.get(overId) as number)
      ) {
        if (tasksLastChanged) onTasksChanged(tasksLastChanged);
        setTasksLastChanged(null);
        return true;
      }
      const isOverADisabledStage = args.over.data.current?.type === "Stage";
      if (isOverADisabledStage && disabledStageIds.includes(overId)) {
        if (tasksLastChanged) onTasksChanged(tasksLastChanged);
        setTasksLastChanged(null);
        return true;
      }
    }

    return false;
  };

  const handleAdd = () => {
    setPositionCreateStage({ side: 1 });
    setOpenCreateStage(true);
  };

  const handleLeftAdd = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 1 });
    setOpenCreateStage(true);
  };

  const handleRightAdd = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 2 });
    setOpenCreateStage(true);
  };

  const handleAddSuccess = (newStage: IStage) => {
    onReload();
  };

  const handleCreateStage = (stage: IStage) => {
    setOpenCreateStage(true);
  };
  const handleManageDrag = (stage: IStage) => {
    setOpenManageDragStage(true);
  };
  const handleAdvancedOptions = (stage: IStage) => {
    setOpenAdvancedOptions(true);
  };
  const handleUpdateWorkflowSuccess = (workflow: IWorkflow) => {
    onReload();
  };
  const handleUpdateStage = (stage: IStage) => {
    setPositionCreateStage({ stageSelected: stage, side: 0, isUpdate: true });
    setOpenUpdateStage(true);
  };

  const handleDeleteStage = async (stage: IStage) => {
    handleOpenDeleteStageDialog(stage);
  };

  const handleOpenDeleteStageDialog = (stage: IStage) => {
    setDeleteStageDialog({ open: true, stage: stage }); // Open the delete confirmation dialog
  };

  const handleCloseDeleteStageDialog = () => {
    setDeleteStageDialog({ open: false, stage: null }); // Close the delete confirmation dialog
  };

  const handleConfirmDelete = () => {
    deleteStage.request(
      StageService.deleteStage(deleteStageDialog.stage!.id as string)
    );
  };

  const handleUpdateTaskcard = (newTask: ITaskCard) => {
    let taskIndex = tasks.findIndex(e => e.id === newTask.id);
    let newTasks = [...tasks];
    newTasks[taskIndex] = newTask;

    onTasksChanged(newTasks);
  };

  const handleDeletedTaskcard = (newTask: ITaskCard) => {
    let taskIndex = tasks.findIndex(e => e.id === newTask.id);
    let newTasks = [...tasks];
    newTasks.splice(taskIndex, 1);

    onTasksChanged(newTasks);
  };

  const handleRollbackTaskcard = (newTask: ITaskCard) => {
    onReload();
  };

  useEffect(() => {
    if (deleteStage.isSuccess) {
      onReload();
      handleCloseDeleteStageDialog(); // Close the dialog after deletion
    }
    if (deleteStage.error) {
      toggleMessage({
        type: "error",
        message: deleteStage.error.message ?? ""
      });
    }
  }, [deleteStage.isSuccess, deleteStage.error]);

  return (
    <>
      <BoardProjectWrapper>
        <Box
          className="EmptyStage"
          sx={{ display: "flex", flexDirection: "row", minHeight: "100%" }}
        >
          {!hasStepStage && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "280px",
                minHeight: "calc(100% - 80px - 52px)",
                cursor: "pointer"
              }}
              onClick={() => setOpenCreateStage(true)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <AddIcon sx={{ fontSize: 40, color: "#666" }} />
                <Typography variant="body1" sx={{ mt: 1, color: "#666" }}>
                  Thêm giai đoạn
                </Typography>
              </Box>
            </Box>
          )}
          <Box display={"flex"}>
            <DndContext
              sensors={sensors}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
              cancelDrop={onCancelDrop}
            >
              <SortableContext items={stagesId}>
                {stages.map(stage => {
                  return (
                    <StageColumn
                      key={stage.id}
                      stage={stage}
                      roleWorkflow={role}
                      onLeftAdd={() => handleLeftAdd(stage)}
                      onRightAdd={() => handleRightAdd(stage)}
                      onDelete={() => handleDeleteStage(stage)}
                      onCreate={() => handleCreateStage(stage)}
                      onManageDrag={() => handleManageDrag(stage)}
                      onAdvancedOptions={() => handleAdvancedOptions(stage)}
                      onUpdate={() => handleUpdateStage(stage)}
                      disabled={
                        disabledColumnIds.includes(stage.id) ||
                        (role.isCreator === false &&
                          role.isProcessManagement === false)
                      }
                      onTaskUpdateSuccess={newTaskcard =>
                        handleUpdateTaskcard(newTaskcard)
                      }
                      onTaskDeleteSuccess={newTaskcard =>
                        handleDeletedTaskcard(newTaskcard)
                      }
                      onTaskRollBackSuccess={newTaskcard =>
                        handleRollbackTaskcard(newTaskcard)
                      }
                      tasks={tasks.filter(task => task.workflowId === stage.id)}
                    />
                  );
                })}
              </SortableContext>

              {createPortal(
                <DragOverlay>
                  {activeStage && (
                    <StageColumn
                      hidden={true}
                      disabled
                      roleWorkflow={role}
                      stage={activeStage}
                      onTaskUpdateSuccess={() => {}}
                      onTaskDeleteSuccess={() => {}}
                      onTaskRollBackSuccess={() => {}}
                      tasks={tasks.filter(
                        task => task.workflowId === activeStage.id
                      )}
                    />
                  )}
                  {activeTask &&
                    (() => {
                      const stage = stages.find(
                        e => e.id === activeTask.workflowId
                      );
                      if (!stage)
                        return (
                          <TaskBox
                            task={activeTask}
                            key={activeTask.id}
                            roleWorkflow={role}
                            disabled
                          />
                        );
                      return (
                        <TaskBox
                          task={activeTask}
                          key={activeTask.id}
                          roleStage={stage.roleWorkflowByUser}
                          roleWorkflow={role}
                          canReassignTask={
                            stage.reassignedWork ===
                            EWorkflowProcess.TaskAssigneeDecides
                          }
                          disabled
                        />
                      );
                    })()}
                </DragOverlay>,
                document.body
              )}
            </DndContext>
          </Box>
        </Box>
      </BoardProjectWrapper>
      <ActionProject
        workflow={workflow}
        role={role}
        hasStepStage={!!hasStepStage}
        onCreateTaskSuccess={() => onReload()}
        onCreateStage={() => handleAdd()}
        onCloseWorkflow={() => onCloseWorkflow(workflow.id!)}
        onUpdateWorkflowSuccess={() => handleUpdateWorkflowSuccess(workflow)}
      />
      <CreateStageModal
        open={openCreateStage || openUpdateStage}
        workflow={workflow}
        isUpdate={openUpdateStage}
        {...positionSelectedStage}
        handleClose={() =>
          openCreateStage
            ? setOpenCreateStage(false)
            : setOpenUpdateStage(false)
        }
        handleSuccess={handleAddSuccess}
      />
      <ManageDragModal
        open={openManageDragStage}
        handleClose={() => setOpenManageDragStage(false)}
      />
      <AdvancedOptionsModal
        open={openAdvancedOptions}
        handleClose={() => setOpenAdvancedOptions(false)}
      />
      <Modal
        title={"Xác nhận xóa giai đoạn"}
        textSubmit={"Xác nhận"}
        textClose="Huỷ bỏ"
        open={deleteStageDialog.open}
        onSubmit={handleConfirmDelete}
        disabledSubmit={deleteStage.isLoading}
        onClose={handleCloseDeleteStageDialog}
      >
        {"Bạn có chắc chắn muốn xóa giai đoạn này?"}
      </Modal>
    </>
  );
};

export default BoardProject;
