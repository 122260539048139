import {
  AppBar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Toolbar,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Wrapper } from "./styles";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import Modal from "../../components/Modal";
import InputForm from "../../components/controls/InputForm";
import ControlForm from "../../components/ControlForm";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ICreateDepartmentModel,
  ICreateDepartmentReq,
  IDepartment
} from "../../models/department";
import { TagInput } from "../WorkflowManagement/components/TagInput/TagInput";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import DepartmentService from "../../services/api/department.service";
import { toggleMessage } from "../../components/Toast/Toast";
import Avatar from "../../components/Avatar";
import { authTokens } from "../../services/services";
import { ITag, IUser } from "../../models/user";
import { Roles, TagType } from "../../models/common/models.enum";
import { useApi } from "../../hooks/useApi.hook";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import SearchNoResult from "../Member/components/SearchNoResult";
import SearchInput from "../Member/components/SearchInput";
import { Helmet } from "react-helmet";
import { IBasePaging } from "../../models/common/models.type";

const Department = () => {
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const [data, setData] = useState<IDepartment[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [paging, setPaging] = useState<IBasePaging | null>();
  const [searchState, setSearchState] = useState<"SUCCESS" | "NONE" | null>(
    null
  );
  const navigate = useNavigate();
  const getPage = useApi<IDepartment[]>({ isFetch: true });
  const createDepartment = useApi<IDepartment[]>({});

  function fetchData(keySearch?: string, pageIndex?: number) {
    getPage.request(
      DepartmentService.getPage({
        CurrentPage: pageIndex ?? 1,
        PageSize: 10,
        KeySearch: keySearch
      })
    );
    setSearchState(null);
    if (!keySearch) {
      setInputValue("");
    }
  }

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
    fetchData();
  }, []);

  useEffect(() => {
    if (getPage.data) {
      setData(getPage?.data ?? []);
      setPaging(getPage?.pagging);
      if (getPage.data.length && inputValue.length) {
        setSearchState("SUCCESS");
      } else if (!getPage.data.length && inputValue.length) {
        setSearchState("NONE");
      }
    }
  }, [getPage.data]);

  useEffect(() => {
    if (createDepartment.success) {
      toggleMessage({
        type: "success",
        message: "Tạo phòng thành công"
      });
      handleClose();
      fetchData();
    }
    if (createDepartment.error) {
      toggleMessage({
        type: "error",
        message: getPage?.error ?? "Đã xảy ra lỗi"
      });
    }
  }, [createDepartment.success, createDepartment.error]);

  useEffect(() => {
    if (getPage.error) {
      toggleMessage({
        type: "error",
        message: getPage?.error ?? "Đã xảy ra lỗi"
      });
    }
  }, [getPage.error]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${"Vui lòng nhập tên nhóm"}`),
    userName: Yup.string().required(`${"Vui lòng nhập username"}`),
    leaderId: Yup.array()
      .required(`${"Vui lòng chọn người quản trị nhóm"}`)
      .min(1, `${"Vui lòng chọn người quản trị nhóm"}`)
  }) as any;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ICreateDepartmentModel>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (request: ICreateDepartmentModel) => {
    const req: ICreateDepartmentReq = {
      name: request.name,
      userName: request.userName,
      leaderId: request.leaderId[0].id,
      memberIds: request.memberIds?.map(member => member.id)
    };
    createDepartment.request(DepartmentService.create(req));
  };

  // useEffect(() => {
  //   if (searchDepartment.data) {
  //     var list: IDepartment[] = [];
  //     searchDepartment.data.map(data => {
  //       const object = payload.find(obj => obj.id === data.id);
  //       if (object) {
  //         list = [...list, object];
  //       }
  //     });
  //     if (list.length) {
  //       setSearchState("SUCCESS");
  //     } else {
  //       setSearchState("NONE");
  //     }
  //     setData(list);
  //   }
  // }, [searchDepartment.data]);

  function handleClose() {
    setOpenCreate(false);
    reset();
  }

  function handleSearch() {
    if (inputValue.length > 0) {
      fetchData(inputValue, 1);
    } else {
      fetchData();
      setSearchState(null);
    }
  }

  return (
    <LoadingOverlayWrapper
      active={getPage.loading}
      text={"Đang tải dữ liệu, vui lòng chờ.."}
    >
      <Wrapper>
        <Helmet>
          <title> Quản lý tài khoản | Nhóm</title>
        </Helmet>
        <AppBar position="static" color="default">
          <Toolbar>
            <Box flexGrow={1}>
              <SearchInput
                input={inputValue}
                placeholder="Tìm kiếm thành viên.."
                isSuccess={searchState === "SUCCESS"}
                search={handleSearch}
                clear={() => fetchData()}
                value={inputValue}
                countResult={data?.length ?? 0}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setInputValue(event.target.value);
                }}
              />
            </Box>
            <Button
              variant="contained"
              size="small"
              sx={{
                borderRadius: "4px",
                display: userInfo?.roles === Roles.SuperAdmin ? "flex" : "none"
              }}
              onClick={_ => setOpenCreate(true)}
            >
              Tạo nhóm người dùng
            </Button>
            <Modal
              title="Tạo nhóm người dùng"
              textSubmit="Tạo nhóm"
              textClose="Huỷ bỏ"
              open={openCreate}
              onSubmit={handleSubmit(onSubmit)}
              onClose={handleClose}
              loadingState={createDepartment.loading}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px"
                }}
              >
                <ControlForm title="Tên nhóm" isRequired classname="brand-form">
                  <InputForm
                    placeholder="Tên nhóm"
                    required
                    name="name"
                    errors={errors}
                    control={control}
                    size="small"
                    disabled={createDepartment.loading}
                  />
                </ControlForm>
                <ControlForm title="Username" isRequired classname="brand-form">
                  <InputForm
                    placeholder="@tennhom"
                    required
                    name="userName"
                    errors={errors}
                    control={control}
                    size="small"
                    disabled={createDepartment.loading}
                  />
                </ControlForm>
                <ControlForm
                  title="Quản trị nhóm"
                  isRequired
                  classname="brand-form"
                >
                  <TagInput
                    name={"leaderId"}
                    control={control}
                    errors={errors}
                    isError={!!errors.leaderId}
                    placeholder={"Gõ để tìm"}
                    limit={1}
                  />
                </ControlForm>
                <ControlForm
                  title="Thành viên"
                  infor="Thành viên có thể được thêm sau"
                  classname="brand-form"
                >
                  <TagInput
                    name={"memberIds"}
                    control={control}
                    errors={errors}
                    isError={false}
                    placeholder={"Thêm thành viên"}
                    // optionDisabled={data}
                  />
                </ControlForm>
              </form>
            </Modal>
          </Toolbar>
        </AppBar>

        {searchState === "NONE" && (
          <SearchNoResult
            keySearch={inputValue}
            title={"phòng ban"}
            clearSearch={() => fetchData()}
          />
        )}

        <Box sx={{ margin: "12px 14px" }}>
          <Grid container spacing={2} columns={16}>
            {!data.length ? (
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  alignContent: "center",
                  height: "75vh",
                  width: "100vw"
                }}
              >
                Danh sách trống
              </Typography>
            ) : (
              data.map((item, idx) => (
                <Grid key={item.id} item xs={8}>
                  <Card
                    sx={{ padding: "16px" }}
                    onClick={() =>
                      navigate(`${ROUTE_PATH.ACCOUNT_DEPARTMENT}/${item.id}`)
                    }
                  >
                    <Box display="flex">
                      <Box
                        sx={{
                          backgroundColor: "#e8efee",
                          borderRadius: "8px",
                          width: "44px",
                          height: "44px",
                          textAlign: "center",
                          alignContent: "center",
                          fontSize: "16px",
                          fontWeight: "600"
                        }}
                      >
                        {idx + 1 < 10 ? "0" : ""}
                        {idx + 1}
                      </Box>
                      <Box sx={{ marginLeft: "12px", flexGrow: 1 }}>
                        <Typography variant="subtitle1" className="card-name">
                          {item.name}
                        </Typography>
                        <Box display="flex">
                          <Typography variant="subtitle2">
                            {item.userName}
                          </Typography>
                        </Box>
                      </Box>
                      <EastRoundedIcon />
                    </Box>

                    <Box display="flex" marginTop="10px">
                      <Box flexGrow={1}>
                        <Typography variant="caption" flexGrow={1}>
                          QUẢN TRỊ VIÊN
                        </Typography>
                        <Box display="flex" alignItems="center" marginTop="2px">
                          <Avatar
                            src={item.infoLeader?.avatar}
                            width={24}
                            height={24}
                          />
                          <Typography variant="caption" marginLeft="8px">
                            {item.infoLeader?.fullName}
                          </Typography>
                        </Box>
                      </Box>

                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ margin: "0 12px" }}
                      />

                      <Box
                        flexGrow={1}
                        flexDirection="column"
                        display="flex"
                        alignItems="end"
                      >
                        <Typography variant="caption" flexGrow={1}>
                          {item.infoMembers.length} Thành viên
                        </Typography>
                        {item.infoMembers.length === 0 ? (
                          <p>Chưa có thành viên</p>
                        ) : (
                          <Box display="flex" justifyContent="space-between">
                            <AvatarGroup
                              total={item.infoMembers.length}
                              max={4}
                              sx={{
                                "& .MuiAvatar-root": {
                                  width: 24,
                                  height: 24,
                                  fontSize: 15
                                }
                              }}
                            >
                              {item.infoMembers.map(info => (
                                <Avatar
                                  key={info.id}
                                  src={info?.avatar}
                                  width={24}
                                  height={24}
                                />
                              ))}
                            </AvatarGroup>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
          {paging && paging.currentPage !== paging.totalPages && (
            <Button
              size="small"
              onClick={() => fetchData(undefined, paging.currentPage + 1)}
            >
              Xem thêm
              <ExpandMoreRoundedIcon />
            </Button>
          )}
        </Box>
      </Wrapper>
    </LoadingOverlayWrapper>
  );
};

export default Department;
