import { ROUTE_PATH } from "./common/constants/app.constant";
import { RouteObject } from "react-router";
import { Roles } from "./models/common/models.enum";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";

import { Navigate } from "react-router-dom";
import { INavItem } from "./models/app";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import UnAuthLayout from "./components/dashboard/UnAuthLayout";
import Home from "./pages/Home/Home";

import Material from "./pages/Material/Material";
import MyProjectIcon from "./assets/images/sidebar/icon_myproject_line_grey.svg";
import MyProjectIconActive from "./assets/images/sidebar/icon_myproject_active.svg";
import WorkflowManagement from "./pages/WorkflowManagement/workflow_manage";
import WorkflowBoard from "./pages/WorkflowManagement/components/WorkflowBoard/WorkflowBoard";
import ManageProject from "./pages/ManageProject/ManageProject";
import BoardProject from "./pages/ManageProject/BoardProject/board_project";
import Account from "./pages/Account/Account";
import Member from "./pages/Member/Member";
import AccountInfo from "./pages/AccountInfo/AccountInfo";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import LanRoundedIcon from "@mui/icons-material/LanRounded";
import Notification from "./pages/Notification/Notification";
import TaskDetail from "./pages/ManageProject/Task/components/JobDetail/JobDetail";
import ProjectMagagerial from "./pages/ManageProject/Managerial";
import Manage from "./pages/ManageProject/Task";
import Department from "./pages/Department/Department";
import DetailDepartment from "./pages/Department/components/DetailDepartment/DetailDepartment";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import FileReviewPage from "./pages/ManageProject/FileViewer";
import TimeOffPage from "./pages/TimeOff/TimeOff";

export const sidebarItems = (role?: Roles): INavItem[] => {
  const WORKFLOWS: INavItem = {
    title: "QUAN TRỌNG",
    code: "Important",
    children: [
      {
        title: "Quản lý quy trình",
        code: "Quản lý quy trình",
        href: ROUTE_PATH.WORKFLOWS,
        icon: { default: MyProjectIcon, active: MyProjectIconActive }
      }
      // {
      //   title: "Nghỉ phép",
      //   code: "TimeOff",
      //   href: ROUTE_PATH.TIMEOFF,
      //   icon: { default: MyProjectIcon, active: MyProjectIconActive }
      // }
    ]
  };

  const MATERIAL: INavItem = {
    title: "Material",
    children: [
      {
        title: "Material",
        code: "Material",
        href: ROUTE_PATH.MATERIAL,
        icon: { default: MyProjectIcon, active: MyProjectIconActive }
      }
    ]
  };

  let navs: INavItem[] = [WORKFLOWS];

  if (process.env.NODE_ENV === "development") {
    navs.push(MATERIAL);
    // navs.push(DEVELOPMENT);
  }

  return navs;
};

export const accountSidebarItems = (role?: Roles) => {
  const USER = {
    icon: {
      default: <AccountCircleRoundedIcon sx={{ color: "GrayText" }} />,
      active: <AccountCircleRoundedIcon />
    },
    text: "Cá nhân",
    href: ROUTE_PATH.ACCOUNT
  };

  // const NOTIFICATION = {
  //   icon: {
  //     default: <NotificationsRoundedIcon sx={{ color: "GrayText" }} />,
  //     active: <NotificationsRoundedIcon />
  //   },
  //   text: "Thông báo",
  //   href: ROUTE_PATH.ACCOUNT_NOTI
  // };

  const MEMBER = {
    icon: {
      default: <SupervisorAccountRoundedIcon sx={{ color: "GrayText" }} />,
      active: <SupervisorAccountRoundedIcon />
    },
    text: "Thành viên",
    href: ROUTE_PATH.ACCOUNT_MEMBER
  };

  const GROUP = {
    icon: {
      default: <LanRoundedIcon sx={{ color: "GrayText" }} />,
      active: <LanRoundedIcon />
    },
    text: "Nhóm",
    href: ROUTE_PATH.ACCOUNT_DEPARTMENT
  };

  const HOME = {
    icon: {
      default: <HomeRoundedIcon sx={{ color: "GrayText" }} />,
      active: <HomeRoundedIcon />
    },
    text: "Tranh chủ",
    href: ROUTE_PATH.INDEX
  };

  let navs = [USER];

  if (process.env.NODE_ENV === "development") {
  }
  if (role === Roles.SuperAdmin) {
    navs.push(MEMBER);
  }
  navs.push(GROUP);
  navs.push(HOME);

  return navs;
};

const appRoutes = (role?: Roles): RouteObject[] => {
  let routes: RouteObject[] = [
    {
      path: ROUTE_PATH.INDEX,
      element: <DashboardLayout />,
      children: [
        { path: "*", element: <Navigate to={ROUTE_PATH.NOTFOUND} /> },
        {
          path: ROUTE_PATH.INDEX,
          element: <Navigate to={ROUTE_PATH.WORKFLOWS} />
        },
        {
          path: ROUTE_PATH.MATERIAL,
          element: <Material />
        },

        {
          path: ROUTE_PATH.WORKFLOWS,
          element: <WorkflowManagement />
        },
        {
          path: ROUTE_PATH.PROJECTS,
          element: <ManageProject />
        },
        {
          path: ROUTE_PATH.TASK,
          element: <TaskDetail />
        },
        {
          path: ROUTE_PATH.TIMEOFF,
          element: <TimeOffPage />
        }
      ]
    },

    {
      path: ROUTE_PATH.INDEX,
      element: <UnAuthLayout />,
      children: [
        {
          path: ROUTE_PATH.LOGIN,
          element: <Login />
        },
        { path: "*", element: <Navigate to={ROUTE_PATH.LOGIN} /> },
        {
          path: ROUTE_PATH.RESOURCE,
          element: <FileReviewPage />
        }
      ]
    },
    {
      path: ROUTE_PATH.NOTFOUND,
      element: <NotFound />
    },
    {
      path: ROUTE_PATH.ACCOUNT,
      element: <Account />,
      children: [
        { path: "*", element: <Navigate to={ROUTE_PATH.NOTFOUND} /> },
        {
          path: ROUTE_PATH.ACCOUNT,
          element: <AccountInfo />
        },
        {
          path: ROUTE_PATH.ACCOUNT_NOTI,
          element: <Notification />
        },
        role === Roles.SuperAdmin
          ? {
              path: ROUTE_PATH.ACCOUNT_MEMBER,
              element: <Member />
            }
          : {},
        {
          path: ROUTE_PATH.ACCOUNT_DEPARTMENT,
          element: <Department />
          // children: [

          // ]
        },
        {
          path: `${ROUTE_PATH.ACCOUNT_DEPARTMENT}/:id`,
          element: <DetailDepartment />
        }
      ]
    }
  ];

  return routes;
};

export default appRoutes;
