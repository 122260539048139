import { AuditTaskStatus } from "../../models/audit";
import { IValue } from "../../models/common/models.type";
import { IBasePagingRes } from "../models/common/model.type";

const STATUS_OPTIONS: IValue[] = [
  { title: "Common.Active", value: true },
  { title: "Common.Deactive", value: false }
];

const AUDIT_STATUS_OPTIONS: IValue[] = [
  { title: "Waiting", value: AuditTaskStatus.Waiting },
  { title: "Running", value: AuditTaskStatus.Running },
  { title: "Done", value: AuditTaskStatus.Done },
  { title: "Cancelled", value: AuditTaskStatus.Cancelled },
  { title: "Error", value: AuditTaskStatus.Error }
];

export const DEFAULT_BASE_PAGING_RES: IBasePagingRes<any> = {
  items: [],
  page: 1,
  pageSize: 0,
  total: 0,
  isFull: true
};

const VALUE_LIST = {
  STATUS_OPTIONS,
  AUDIT_STATUS_OPTIONS
};

export const firebaseConfig = {
  apiKey: "AIzaSyDEPYkS5s9RV3cOcJWu1NgohE0n7Rv8k7Y",
  authDomain: "e-office-13a76.firebaseapp.com",
  projectId: "e-office-13a76",
  storageBucket: "e-office-13a76.appspot.com",
  messagingSenderId: "170009792886",
  appId: "1:170009792886:web:74789db4648898dc03aa57"
};

export default VALUE_LIST;
