import styled from "@emotion/styled";
import { Box } from "@mui/material";
import variableStyles from "../../../../../theme/variable-styles";

export const Wrapper = styled(Box)((theme) => ({
    margin: 0,
    padding: 0,
    boxSizing: "border-box",

    "& .container": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        "& .breadcrumb": {
            display: "flex",
            borderRadius: 2,
            overflow: "hidden",
            margin: "auto",
            textAlign: "center",
            top: "50%",
            width: "100%",
            height: 24,
            transform: "translateY(-50 %)",
            zIndex: "1",
            backgroundColor: "#e5e5e5",

            "& .breadcrumb-box": {
                position: "relative",
                display: "flex",
                flexGrow: 1,
                textDecoration: "none",
                margin: "auto",
                height: "100%",
                alignItems: "center",
                width: "calc(100% / 5)",
                paddingLeft: 24,
                paddingRight: 0,
                overflow: "hiden",
                "&:last-child": {
                    "&:after": {
                        content: "none",
                    }
                },
                "&:after": {
                    content: '""',
                    position: "absolute",
                    display: "inline-block",
                    width: 24,
                    height: 24,
                    top: 0,
                    right: - 12,
                    backgroundColor: "#e5e5e5",
                    borderTopRightRadius: 4,
                    transform: "scale(0.707) rotate(45deg)",
                    boxShadow: "2px -2px white",
                    zIndex: "1",
                },
                // "&:hover": {
                //     background: "darken(tomato, 20%)",
                //     color: "white",
                //     "&:after": {
                //         background: "darken(tomato, 20%)",
                //         color: "white",
                //     }
                // },
                "&.active": {
                    background: "darken(tomato, 20%)",
                    color: "white",
                    "&:after": {
                        background: "darken(tomato, 20%)",
                        color: "white",
                    }
                }
            },
            "& .breadcrumb__inner": {
                margin: "auto",
                zIndex: "2",
                width: "100%",
                color: "white",
                fontSize: 14,
                fontWeight: 600,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                marginLeft: "-6px"
            },
            "& .active": {
                backgroundColor: "#54acf6",
                "&:after": {
                    backgroundColor: "#54acf6",

                },
            },
            "& .completed": {
                backgroundColor: "#53b727",
                "&:after": {
                    backgroundColor: "#53b727",

                },
            }


        }
    }

}));