import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { sidebarItems } from "../../routes";
import { BreadcrumbsWrapper } from "./styles";

interface BreadcrumbsProps {
  handleHidden: (value: boolean) => void;
}

export default function Breadcrumbs(props: BreadcrumbsProps) {
  const { handleHidden } = props;
  const location = useLocation();
  const nav = sidebarItems();

  const paths = location.pathname.split("/").slice(1);
  const breadcrumbs: { title: string; link: string }[] = [];

  paths.forEach(p => {
    const category = nav
      .flatMap(cg => cg.children)
      .find(c => c?.href === `/${p}`);
    if (!!category) {
      breadcrumbs.push({
        title: `${category?.title}`,
        link: category?.href || ""
      });
    }
  });

  useEffect(() => {
    handleHidden(breadcrumbs.length <= 1);
  }, [breadcrumbs]);

  return (
    <BreadcrumbsWrapper
      className={`${breadcrumbs.length <= 1 ? "hidden" : ""}`}
    >
      <ul className="breadcrumb-list flex-start-align-center">
        {breadcrumbs &&
          breadcrumbs.map((b, index) =>
            index !== breadcrumbs.length - 1 ? (
              <li key={b.title} className="item">
                <Link to={b.link}>{b.title}</Link>
              </li>
            ) : (
              <li key={b.title} className="last-item">
                {b.title}
              </li>
            )
          )}
      </ul>
    </BreadcrumbsWrapper>
  );
}
