import { Avatar, Box } from "@mui/material";
import { WorkflowItemWrapper } from "./styles";
import { BorderLinearProgress } from "./border_linear_progress";
import {
  createSearchParams,
  Link,
  URLSearchParamsInit,
  useLocation,
  useNavigate
} from "react-router-dom";
import { ROUTE_PATH } from "../../../../common/constants/app.constant";
import { IWorkflow } from "../../../../models/workflow";
import React from "react";
import useNavigateSearch from "../../../../components/NavigateSearch";

interface Props {
  workflow: IWorkflow;
  tabCode: string;
}

const WorkflowItem = ({ workflow, tabCode }: Props) => {
  const navigateSearch = useNavigateSearch();

  const goProjectManager = () =>
    navigateSearch(ROUTE_PATH.PROJECTS, { id: workflow.id, tab: tabCode });
  // window.open(
  //   ROUTE_PATH.PROJECTS + `?${createSearchParams({ boardId: workflow.id })}`,
  //   "_blank"
  // );

  return (
    <WorkflowItemWrapper
      className="workflow-item"
      display={"flex"}
      flexDirection={"column"}
      p={1.5}
      sx={{
        borderRadius: 1.5,
        bgcolor: "white",
        float: "left"
      }}
      onClick={goProjectManager}
    >
      <Box display={"flex"} flexDirection={"column"} sx={{ flexGrow: 7 }}>
        <span className="workflow-item-name"> {workflow.name}</span>
        <div
          className="workflow-item-description"
          dangerouslySetInnerHTML={{
            __html: workflow.description ?? ""
          }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        sx={{ flexGrow: 5 }}
      >
        <Box sx={{ flexGrow: 3 }}>
          {/* <Avatar
            sx={{
              bgcolor: "#267CDE",
              width: 24,
              height: 24,
              fontSize: 11,
              fontWeight: 300
            }}
          ></Avatar> */}
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          sx={{ flexGrow: 1 }}
        >
          <BorderLinearProgress
            variant="determinate"
            value={0}
            height={8}
            barcolor="#308fe8"
            background="#ededed"
            border="solid .5px #cdcdcd"
          />
          <Box display={"flex"} flexDirection={"row"}>
            <Box sx={{ display: "flex", flexGrow: 1, gap: 0.5, fontSize: 13 }}>
              <span>{workflow.totalTask}</span>
              <span className="workflow-item-span">Nhiệm vụ</span>
            </Box>
            <Box sx={{ display: "flex", gap: 0.5, fontSize: 13 }}>
              <span>{workflow.totalTaskComplete} </span>
              <span className="workflow-item-span">Hoàn thành・</span>
            </Box>
            <Box sx={{ display: "flex", gap: 0.5, fontSize: 13 }}>
              <span>{workflow.totalTaskFailed} </span>
              <span className="workflow-item-span">Thất bại</span>
            </Box>
          </Box>
        </Box>
      </Box>
    </WorkflowItemWrapper>
  );
};

export default WorkflowItem;
