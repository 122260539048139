// style
import GlobalStyles from "../theme/globalStyles";
// theme
import ThemeConfig from "../theme";
// library
import { useDispatch } from "react-redux";
// routes
import appRoutes from "../routes";
import { useRoutes } from "react-router";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { USER_ROLE_KEY, USER_TOKEN_KEY } from "../services/local/auth-tokens";
// import { initializeApp } from "firebase/app";
import { Roles } from "../models/common/models.enum";
// import "firebase/messaging";

const Main = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const token = localStorage.getItem(USER_TOKEN_KEY);
  const role = localStorage.getItem(USER_ROLE_KEY);
  const content = useRoutes(appRoutes((role as Roles) || Roles.Member));

  // get data
  // useEffect(() => {
  //   if (token) {
  //     if (role === Roles.AdminSystem || role === Roles.SuperAdmin) {
  //     } else {
  //       dispatch(getUser());
  //     }
  //   }
  // }, [token]);

  useEffect(() => {}, [location]);

  return (
    <ThemeConfig>
      <GlobalStyles />
      {content}
    </ThemeConfig>
  );
};

export default Main;
