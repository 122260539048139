import {
  ICreateDepartmentReq,
  IDepartment
} from "./../../models/department/index";
import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import { IGetUsersReq } from "../../models/user";

const API = ApiEndpoints.DEPARTMENT;
const baseURL = envConfig.API_ENDPOINT;

const getPage = (data: IGetUsersReq): Promise<IBasePagingRes<any>> => {
  return api.get(baseURL, API.GET_DEPARTMENTS.url, {
    params: data
  });
};

const create = (
  data: ICreateDepartmentReq
): Promise<IBaseResponseModel<IBasePagingRes<any>>> => {
  return api.post(baseURL, API.CREATE.url, data);
};

const getSingle = (id: string): Promise<IBaseResponseModel<IDepartment>> => {
  return api.get(baseURL, `${API.GET_SIGNLE.url}/${id}`);
};

const update = (data: IDepartment): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API.UPDATE.url, data);
};

const deleteGroup = (id: string): Promise<IBaseResponseModel<IDepartment>> => {
  return api.delete(baseURL, `${API.DELETE.url}/${id}`);
};

const DepartmentService = {
  getPage,
  create,
  getSingle,
  update,
  deleteGroup
};

export default DepartmentService;
