import { IActionAdminUpdateUserSuccess } from "./../../models/redux/user/index";
import {
  ICreateDepartmentModel,
  ICreateDepartmentReq,
  IDepartment
} from "./../../models/department/index";
import { IGetUsersReq } from "../../models/user";
import {
  EDepartmentType,
  IActionCreateDepartment,
  IActionCreateDepartmentFailed,
  IActionCreateDepartmentSuccess,
  IActionDepartment,
  IActionGetDepartments,
  IActionGetSingleDepartment,
  IActionSetDepartment,
  IActionSetPage,
  IActionUpdateDepartment,
  IActionUpdateDepartmentFailed,
  IActionUpdateDepartmentSuccess,
  IReduxDepartmentState
} from "./../../models/redux/department/index";

export const getPage = (request: IGetUsersReq): IActionGetDepartments => ({
  type: EDepartmentType.GET_PAGE,
  payload: { request }
});

export const setPage = (listData: IDepartment[]): IActionSetPage => ({
  type: EDepartmentType.SET_PAGE_STATE,
  payload: { listData }
});

export const setDepartment = (data: IDepartment): IActionSetDepartment => ({
  type: EDepartmentType.SET_DEPARTMENT,
  payload: { data }
});

export const getSingleDepartment = (
  request: string
): IActionGetSingleDepartment => ({
  type: EDepartmentType.GET_SINGLE,
  payload: { request }
});

export const createDepartment = (
  request: ICreateDepartmentModel
): IActionCreateDepartment => ({
  type: EDepartmentType.CREATE_DEPARTMENT,
  payload: { request }
});

export const createDepartmentSuccess = (): IActionCreateDepartmentSuccess => ({
  type: EDepartmentType.CREATE_DEPARTMENT_SUCCESS,
  payload: {}
});

export const createDepartmentFailed = (
  message: string
): IActionCreateDepartmentFailed => ({
  type: EDepartmentType.CREATE_DEPARTMENT_FAILED,
  payload: { message }
});

export const updateDepartment = (
  request: IDepartment
): IActionUpdateDepartment => ({
  type: EDepartmentType.UPDATE_DEPARTMENT,
  payload: { request }
});

export const updateDepartmentSuccess = (): IActionUpdateDepartmentSuccess => ({
  type: EDepartmentType.UPDATE_DEPARTMENT_SUCCESS,
  payload: {}
});

export const updateDepartmentFailed = (
  message: string
): IActionUpdateDepartmentFailed => ({
  type: EDepartmentType.UPDATE_DEPARTMENT_FAILED,
  payload: { message }
});

const defaultState: IReduxDepartmentState = {
  // data: {
  //   id: "",
  //   firstName: "",
  //   lastName: "",
  //   fullName: "",
  //   avatar: "",
  //   email: "",
  //   phoneNumber: null,
  //   userName: "",
  //   roles: "",
  //   position: "",
  //   address: null,
  //   managerId: null,
  //   birthDay: null,
  //   manager: null
  // },
  listData: [],
  error: null,
  success: null
};

export default function departmentReducer(
  state: IReduxDepartmentState = defaultState,
  action: IActionDepartment
): IReduxDepartmentState {
  switch (action.type) {
    case EDepartmentType.SET_PAGE_STATE:
      return {
        ...state,
        listData: action.payload.listData
      };
    case EDepartmentType.SET_DEPARTMENT:
      return {
        ...state,
        data: action.payload.data
      };
    case EDepartmentType.CREATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        success: true
      };
    case EDepartmentType.CREATE_DEPARTMENT_FAILED:
      return {
        ...state,
        error: action.payload.message
      };
    case EDepartmentType.UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        success: true
      };
    case EDepartmentType.UPDATE_DEPARTMENT_FAILED:
      return {
        ...state,
        error: action.payload.message
      };

    default:
      return state;
  }
}
