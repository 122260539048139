import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const HeaderWrapper = styled("header")(({}) => ({
  padding: "16.5px 24px",
  "& .header-infor": {
    gap: "5px",
    "& .infor-title": {
      fontWeight: variableStyles.fwBold,
      fontSize: "14px",
      lineHeight: "19.6px",
      color: variableStyles.GreenPrimaryColor800
    }
  },
  "& .header-action": {
    "& .MuiFormControl-root": {
      marginRight: "32px"
    },
    "& .action-personal": {
      display: "flex",
      alignItems: "center",
      gap: "24px",

      "& .lang-code": {
        fontWeight: "bold"
      },
      "& .avatar-button": {
        padding: "0",
        minWidth: "unset"
      }
    }
  }
}));
