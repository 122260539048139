import "react-perfect-scrollbar/dist/css/styles.css";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useDispatch } from "react-redux";
import reduxStore, { AppDispatch } from "../src/redux/store";
import "./App.css";
import Main from "./pages/Main";
import "./i18n/i18n";
import "moment/locale/vi";
import Toast, { toggleMessage } from "./components/Toast/Toast";
import ConfirmModal from "./components/ConfirmModal/ConfirmModal";
import { useEffect, useState } from "react";
import { CountryLanguage, changeLanguage } from "./i18n/i18n";

import { messaging, requestPermission } from "./firebase";
import { onMessage } from "firebase/messaging";
import { incrementNotification } from "./redux/user/user.duck";
import Snackbar from "@mui/material/Snackbar";
import { EFireBaseType } from "./models/common/models.enum";
import { useLocation } from "react-router-dom";
import { ROUTE_PATH } from "./common/constants/app.constant";
import { now } from "moment";
import { Typography } from "@mui/material";

const App = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const reloadChannel = new BroadcastChannel("reload-board");

  useEffect(() => {
    requestPermission();
  }, []);

  useEffect(() => {
    const locale = localStorage.getItem("language");

    if (!locale) {
      localStorage.setItem("language", CountryLanguage.EN);
      changeLanguage(CountryLanguage.EN);
    } else {
      changeLanguage(locale as CountryLanguage);
    }
  }, []);

  onMessage(messaging, message => handleOnMessage(message));

  useEffect(() => {
    const channel = new BroadcastChannel("notifications");
    channel.addEventListener("message", event => handleOnMessage(event.data));
  }, []);

  const handleOnMessage = (message: any) => {
    const notificationTitle =
      message.notification?.title ?? "Bạn nhận được 1 thông báo mới";
    const hiddenNotification =
      message.data?.Type === EFireBaseType.UpdateBoard.toString();

    if (hiddenNotification && location.pathname === ROUTE_PATH.PROJECTS) {
      reloadChannel.postMessage("reload");
    }
    if (!hiddenNotification) {
      // Show the Toast notification
      setMessage(notificationTitle);
      setOpen(true);
      // Increment the notification count
      dispatch(incrementNotification());
    }
  };

  return (
    <Provider store={reduxStore.store}>
      <PersistGate loading={null} persistor={reduxStore.persistor}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          key={"toast" + now()}
          open={open}
          autoHideDuration={3000}
          onClose={() => setOpen(false)}
          message={
            <>
              <Typography>{"Thông báo"}</Typography>
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </>
          }
        />
        <Toast />
        <Main />
        <ConfirmModal />
      </PersistGate>
    </Provider>
  );
};

export default App;
