import moment from "moment";
import { ECurrencyCode } from "../../models/common/models.enum";
import { CountryLanguage } from "../../i18n/i18n";
import { DATE_FORMAT, DATE_SELECT_FORMAT } from "../../config/constants";

const formatNumber = (currency: number = 0, locales?: string) => {
  if (currency === undefined) return;
  if (typeof currency === "string") currency = parseInt(currency);

  return currency?.toLocaleString(locales ?? "it-IT");
};
export function formatNumberCurrency(
  num: number | undefined | null,
  isCurrency?: boolean
) {
  if (!num) {
    return `${0}${isCurrency ? "đ" : ""}`;
  } else {
    try {
      return `${Number(num)
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}${isCurrency ? "đ" : ""}`;
    } catch (error) {
      return `${0}${isCurrency ? "đ" : ""}`;
    }
  }
}
const formatCurrency = (currency: number = 0, currencyCode?: ECurrencyCode) => {
  if (currencyCode === ECurrencyCode.USD) {
    return currency >= 0
      ? `$ ${formatNumber(currency, "en-US")}`
      : `- $ ${formatNumber(-currency, "en-US")}`;
  } else if (currencyCode === ECurrencyCode.VND) {
    return currency >= 0
      ? `VND ${formatNumber(currency, "en-US")}`
      : `- VND ${formatNumber(-currency, "en-US")}`;
  } else if (currencyCode === ECurrencyCode.EUR) {
    return currency >= 0
      ? `€ ${formatNumber(currency, "en-US")}`
      : `- € ${formatNumber(-currency, "en-US")}`;
  }
  return formatNumber(currency);
};
const formatDateTime = (
  date?: string | Date | null,
  format?: string | undefined
) => {
  const locale = localStorage.getItem("language") as CountryLanguage | null;

  if (!date) return null;
  return moment(date)
    .locale(locale === CountryLanguage.EN ? "en" : "vi")
    .format(format ?? DATE_SELECT_FORMAT);
};
const formatDate = (
  date?: string | Date | null,
  format?: string | undefined
) => {
  const locale = localStorage.getItem("language") as CountryLanguage | null;
  if (!date) return "";
  return moment(date)
    .locale(locale === CountryLanguage.EN ? "en" : "vi")
    .format(format ?? DATE_FORMAT);
};
const formatNumbertoDate = (date: number | undefined) => {
  const locale = localStorage.getItem("language") as CountryLanguage | null;

  if (date && date > 0) {
    let dateNew = new Date(date);
    return moment(dateNew.toUTCString())
      .locale(locale === CountryLanguage.EN ? "en" : "vi")
      .format("DD MMM YYYY hh:mm:ss");
  }
  return "--";
};
const generateId = (prefix?: string): string => {
  const _prefix = prefix ? prefix : "";
  const id = `${Math.floor(Math.random() * Math.floor(9999)) + 1111}`;

  return _prefix + Math.floor(Date.now() / 1000) + id;
};
const generateGuidId = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const generateUUID = (length: number): string => {
  const stringValue = [...Array(length)].map(t => "x").join("");

  return stringValue.replace(/[x]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const generateWeek = () => {
  const arr = [0, 1, 2, 3, 4, 5, 6];
  let dayOfWeek = arr.map(item => {
    const day = moment().day(item).format("DD");
    return {
      label: day,
      value: 0,
      gift: []
    };
  });
  return dayOfWeek;
};
const hasEmail = (email: string) => {
  return !!email.match("[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+");
};
const hasImage = (mime: string): boolean => {
  return ["image/jpeg", "image/png"].indexOf(mime) === -1;
};
const capitalize = (value: string | undefined) => {
  if (!value) return "";
  return value.charAt(0).toUpperCase() + value.slice(1);
};
function startDownloadFile(file: Buffer, fileName: string) {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const safeRead = function (obj: any, strfield: string): any {
  let current, prop, props, val, _i, _len;

  //obj = arguments[0], props = 2 <= arguments.length ? [].slice.call(arguments, 1) : [];
  props = strfield.split(".");

  let read = function (obj: any, prop: any) {
    if ((obj != null ? obj[prop] : void 0) == null) {
      return;
    }
    return obj[prop];
  };

  current = obj;
  for (_i = 0, _len = props.length; _i < _len; _i++) {
    prop = props[_i];

    if ((val = read(current, prop))) {
      current = val;
    } else {
      return "";
    }
  }
  return current;
};

export function removeVietnameseAccents(str: string) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function renderValue(value: any) {
  if (value) {
    return value;
  }
  return "\u00A0";
}

export async function copyToClipboard(textToCopy: string) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator && navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard?.writeText(textToCopy);
  } else {
    // Use the 'out of viewport hidden text area' trick
    const element = document.createElement("div");
    element.innerHTML = textToCopy;

    // Move textarea out of the viewport so it's not visible
    element.style.position = "absolute";
    element.style.left = "-999999px";

    document.body.prepend(element);

    try {
      var r = document.createRange();

      r.selectNode(element);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(r);
      document.execCommand("copy");
    } catch (error) {
      console.error(error);
    } finally {
      window.getSelection()?.removeAllRanges();
      element.remove();
    }
  }
}

export function isValidDomain(domain: string) {
  // Regular expression for checking if string is a valid domain
  const domainRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/?/;

  return domainRegex.test(domain);
}

export function hasNoneHttp(domain: string) {
  // Regular expression for checking if string is a valid domain
  const domainRegex = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}/;

  return domainRegex.test(domain);
}

export const b64toBlob = (b64Data: any, filename: string, sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new File(byteArrays, filename);
  return blob;
};

const CommonHandle = {
  formatNumber,
  formatCurrency,
  generateId,
  generateWeek,
  formatDateTime,
  formatDate,
  formatNumbertoDate,
  generateGuidId,
  hasEmail,
  hasImage,
  capitalize,
  startDownloadFile,
  safeRead,
  generateUUID,
  removeVietnameseAccents,
  renderValue,
  copyToClipboard,
  formatNumberCurrency
};

export default CommonHandle;
