import { StageColumnWrapper } from "./styles";
import StageInfo, { StageInfoProps } from "../StageInfo/stage_info";
import StageArea from "../StageArea/stage_area";
import { useEffect, useRef, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Box } from "@mui/material";
import { ITaskCard } from "../../../../../models/Task";
import { IRoleBoard, IStage } from "../../../../../models/Stage";

interface StageColumnProps {
  stage: IStage;
  tasks: ITaskCard[];
  roleWorkflow: IRoleBoard;
  disabled: boolean;
  hidden?: boolean;
  onLeftAdd?: () => void;
  onRightAdd?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
  onCreate?: () => void;
  onManageDrag?: () => void;
  onAdvancedOptions?: () => void;
  onTaskUpdateSuccess: (newTaskcard: ITaskCard) => void;
  onTaskDeleteSuccess: (newTaskcard: ITaskCard) => void;
  onTaskRollBackSuccess: (newTaskcard: ITaskCard) => void;
}

const StageColumn = (props: StageColumnProps) => {
  const {
    stage,
    tasks,
    roleWorkflow,
    onTaskUpdateSuccess,
    onTaskDeleteSuccess,
    onTaskRollBackSuccess,
    disabled,
    hidden
  } = props;

  const [disabledDnd, setDisabledDnd] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: stage.id,
    disabled: disabledDnd,
    data: { type: "Stage", stage }
  });

  useEffect(() => {
    setDisabledDnd(disabled);
  }, [disabled]);

  const style = {
    transition,
    transform: CSS.Translate.toString(transform)
  };

  if (isDragging) {
    return (
      <StageColumnWrapper
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        <StageInfo {...props} isDragging={hidden} name={stage.name} />
        <StageArea
          disabled={true}
          hidden={hidden}
          tasks={tasks}
          roleWorkflow={roleWorkflow}
          stage={props.stage}
          onTaskUpdateSuccess={onTaskUpdateSuccess}
          onTaskDeleteSuccess={onTaskDeleteSuccess}
          onRollBackSuccess={onTaskRollBackSuccess}
        />
      </StageColumnWrapper>
    );
  }

  return (
    <StageColumnWrapper
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      hidden={hidden}
      className="Stage-Column"
    >
      <StageInfo
        {...props}
        isDragging={hidden}
        isShowMenu={stage.type === 0}
        name={stage.name}
      />
      <StageArea
        hidden={hidden}
        tasks={tasks}
        roleWorkflow={roleWorkflow}
        stage={props.stage}
        onTaskUpdateSuccess={onTaskUpdateSuccess}
        onTaskDeleteSuccess={onTaskDeleteSuccess}
        onRollBackSuccess={onTaskRollBackSuccess}
      />
    </StageColumnWrapper>
  );
};
export default StageColumn;
