import { useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";
import AccountSidebar from "../../components/Account/AccountSideBar";
import { Helmet } from "react-helmet";

const AccountLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",
  overflow: "hidden",
  width: "100%"
}));

const AccountLayoutWrapper = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column"
}));

export const PageWrapper = styled("div")(() => ({
  flex: "1 1 auto",
  maxHeight: "100vh",
  overflow: "auto",
  backgroundColor: variableStyles.NaturalColor100,
  "&.fullHeight": { maxHeight: "100vh" },
  "@media (max-width:767.98px)": {
    padding: "20px 8px"
  },
  "& .MuiTableHead-root": {
    backgroundColor: "rgb(243, 244, 246)"
  }
}));

const Account = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isHiddenBreadcrumb, setHiddenBreadcrumb] = useState<boolean>(false);


  return (
    <AccountLayoutRoot>
      <Helmet>
        <title> Quản lý tài khoản </title>
      </Helmet>
      <AccountSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <AccountLayoutWrapper>
        {/* <Breadcrumbs
            handleHidden={value => {
              setHiddenBreadcrumb(value);
            }}
          /> */}
        <PageWrapper
          className={`scrollbar ${isHiddenBreadcrumb ? "fullHeight" : ""}`}
        >
          <Outlet />
        </PageWrapper>
      </AccountLayoutWrapper>
    </AccountLayoutRoot>
  );
};

export default Account;
