import moment from "moment";

export function fDate(date?: Date | string | null, newFormat?: string) {
  const fm = newFormat || "DD-MM-yyyy";

  return date ? moment(new Date(date)).format(fm) : "";
}

export function fDateTime(date?: Date | string | null, newFormat?: string) {
  const fm = newFormat || "DD-MM-YYYY HH:mm";

  return date ? moment(new Date(date)).format(fm) : "";
}

export function fTimestamp(date?: Date | string | null) {
  return date ? moment(new Date(date)).toDate().toJSON() : "";
}

export function fToNow(date?: Date | string | null) {
  return date ? moment(new Date(date)).toNow() : "";
}
export function fTime(
  time: string | null
): { hours: string; minutes: string; seconds: number } | null {
  if (!time) return null;
  const [hours, minutes] = time.split(":").map(Number);

  return {
    hours: hours > 0 ? hours.toString() : "",
    minutes: minutes > 0 ? minutes.toString() : "",
    seconds: (hours ?? 0) * 3600 + (minutes ?? 0) * 60
  };
};

