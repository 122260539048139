import { Box, Chip, IconButton, TextField } from "@mui/material";
import React, { ChangeEventHandler } from "react";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import FileImage from "./FileImage";

type Props = {
  inputRef: any;
  value?: string;
  clear?: Function;
  onSubmit: Function;
  files?: File[] | null;
  onChange?:
  | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  | undefined;
  isReply?: boolean;
  onDelete: Function;
  isDisableAction?: boolean;
  handleFileChange: ChangeEventHandler<HTMLInputElement>;
};

const CommentInput = (props: Props) => {
  const {
    inputRef,
    value,
    onSubmit,
    files,
    onChange,
    handleFileChange,
    onDelete,
    isReply = false,
    isDisableAction
  } = props;
  return (
    <Box
      sx={{
        border: "1px solid #DEDEDE",
        borderRadius: "8px",
        margin: `10px 0 10px ${isReply ? 42 : 0}px`,
        padding: "1px"
      }}
    >
      <TextField
        id="comment"
        size="small"
        multiline
        InputProps={{ disableUnderline: true }}
        variant="standard"
        // minRows={!isReply ? 2 : undefined}
        sx={{
          "& .MuiInputBase-root": {
            paddingLeft: 0,
            padding: 0,
            margin: 0
          }
        }}
        fullWidth
        placeholder="Viết thảo luận của bạn"
        onChange={onChange}
        value={value}
      />

      <Box display="flex" margin="0 8px" alignItems="center">
        <Box flexGrow={1}>
          {files &&
            files.map((f, idx) => (
              <Chip
                key={idx}
                variant="outlined"
                size="small"
                sx={{
                  marginRight: "8px",
                  marginBottom: "6px",
                  borderRadius: "8px",
                  padding: "4px",
                  border: "1px solid #DEDEDE"
                }}
                label={f?.name}
                onDelete={() => onDelete(f)}
                avatar={<FileImage type={`.${f?.type.split("/")[1]}`} />}
              />
            ))}
        </Box>
        <Box textAlign="center">
          <input
            name="avatar"
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={true}
            onChange={handleFileChange}
            className="input-file-upload"
            hidden
          />
          <IconButton size="small" onClick={() => inputRef.current.click()}>
            <AttachFileRoundedIcon sx={{ rotate: "45deg" }} />
          </IconButton>
        </Box>
        <IconButton
          size="small"
          color="primary"
          disabled={isDisableAction}
          onClick={() => onSubmit()}
        >
          <SendRoundedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CommentInput;
