import { Palette, PaletteColor, alpha } from "@mui/material/styles";
import variableStyles from "./variable-styles";

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

function customAugmentColor(mainColor: string) {
  return {
    main: mainColor,
    light: alpha(mainColor, 0.2),
    dark: alpha(mainColor, 0.48),
    contrastText: variableStyles.colorWhite
  };
}

// SETUP COLORS
const GREY = {
  50: variableStyles.grey50,
  100: variableStyles.grey100,
  200: variableStyles.grey200,
  300: variableStyles.grey300,
  400: variableStyles.grey400,
  500: variableStyles.grey500,
  600: variableStyles.grey600,
  700: variableStyles.grey700,
  800: variableStyles.grey800,
  900: variableStyles.grey900,
  A100: variableStyles.greyA100,
  A200: variableStyles.greyA200,
  A400: variableStyles.greyA400,
  A700: variableStyles.WarningA700
};

const PRIMARY: PaletteColor = {
  light: variableStyles.GreenPrimaryColor300,
  main: variableStyles.GreenPrimaryColor400,
  dark: variableStyles.GreenPrimaryColor700,
  contrastText: variableStyles.colorWhite
};
const SECONDARY: PaletteColor = {
  light: variableStyles.blueSecondary600,
  main: variableStyles.blueSecondary400,
  dark: variableStyles.blueSecondary500,
  contrastText: variableStyles.colorWhite
};
const INFO: PaletteColor = {
  light: variableStyles.Info300,
  main: variableStyles.Info500,
  dark: variableStyles.Info700,
  contrastText: variableStyles.colorWhite
};
const SUCCESS: PaletteColor = {
  light: variableStyles.Success300,
  main: variableStyles.Success500,
  dark: variableStyles.Success700,
  contrastText: variableStyles.colorWhite
};
const WARNING: PaletteColor = {
  light: variableStyles.Warning400,
  main: variableStyles.WarningA700,
  dark: variableStyles.Warning700,
  contrastText: variableStyles.colorWhite
};
const ERROR: PaletteColor = {
  light: variableStyles.Error300,
  main: variableStyles.Error500,
  dark: variableStyles.Error700,
  contrastText: variableStyles.colorWhite
};

export const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  secondary: createGradient(SECONDARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

export const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
  colors: [
    "#f6685e",
    "#ed4b82",
    "#af52bf",
    "#8561c5",
    "#6573c3",
    "#4dabf5",
    "#35baf6",
    "#33c9dc",
    "#33ab9f",
    "#6fbf73",
    "#a2cf6e",
    "#d7e360",
    "#ffef62",
    "#ffcd38",
    "#ffac33",
    "#ff784e"
  ]
};

const palette: Palette = {
  common: {
    black: variableStyles.colorBlack,
    white: variableStyles.colorWhite
  },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  divider: GREY[300],
  text: {
    primary: variableStyles.colorBlack,
    secondary: GREY[900],
    disabled: GREY[400]
  },
  background: {
    paper: variableStyles.colorWhite,
    default: variableStyles.primaryBackgroundColor
  },
  action: {
    active: GREY[500],
    hover: "tranparent",
    selected: GREY[500],
    disabled: "#AFAFAF",
    disabledBackground: "#E5E5E5",
    focus: GREY[200],
    hoverOpacity: 0.08,
    disabledOpacity: 0.4,
    activatedOpacity: 0.8,
    focusOpacity: 0,
    selectedOpacity: 0.8
  },
  augmentColor: () => customAugmentColor(PRIMARY.main),
  contrastThreshold: 4.5,
  getContrastText: background => background,
  mode: "light",
  tonalOffset: {
    dark: 0.2,
    light: 0.4
  }
};

export default palette;
