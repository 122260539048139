import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import { IGetPageReq } from "../../models/common/models.request";
import { IUpdateViewedReq } from "../../models/notification";

const API = ApiEndpoints.NOTIFICATION;
const baseURL = envConfig.API_ENDPOINT;

const getPage = (data: IGetPageReq): Promise<IBasePagingRes<any>> => {
  return api.get(baseURL, API.GET_PAGE.url, {
    params: data
  });
};

const updateViewed = (
  data: IUpdateViewedReq
): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API.UPDATE.url, data);
};

//    const getChildren = (
//      data: IGetCommentChildrenReq
//    ): Promise<IBasePagingRes<any>> => {
//      return api.get(baseURL, API.GET_CHILDREN_COMMENTS.url, {
//        params: data
//      });
//    };

//    const create = (
//      data: ICreateCommentReq
//    ): Promise<IBaseResponseModel<IBasePagingRes<any>>> => {
//      return api.post(baseURL, API.CREATE.url, data);
//    };

// const getSingle = (id: string): Promise<IBaseResponseModel<IDepartment>> => {
//   return api.get(baseURL, `${API.GET_SIGNLE.url}/${id}`);
// };

// const update = (data: IDepartment): Promise<IBaseResponseModel<any>> => {
//   return api.put(baseURL, API.UPDATE.url, data);
// };

// const deleteGroup = (id: string): Promise<IBaseResponseModel<IDepartment>> => {
//   return api.delete(baseURL, `${API.DELETE.url}/${id}`);
// };

const NotificationService = {
  getPage,
  updateViewed
  //   getChildren,
  //   create
  //   getSingle,
  //   update,
  //   deleteGroup
};

export default NotificationService;
