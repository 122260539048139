import { Box, styled } from "@mui/material";

export const BoardProjectWrapper = styled(Box)(() => ({
    maxWidth: "100%",
    overflow: "auto",
    height: "calc(100% - 80px)",
    position: "relative",
    "&  .Stage-Column:nth-of-type(1)": {
        "& .StageHeader": {
            borderLeft: "1.5px solid #dbdbdb",
        },
        "& .StageArea": {
            borderLeft: "1.5px solid #dbdbdb",
        },
    },


}));