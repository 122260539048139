import React from "react";
import { ImageCustom } from "../../../../../../components";
import { EFileType } from "../../../../../../models/common/models.enum";
import pdf from "../../../../../../assets/images/file/pdf.svg";
import mp4 from "../../../../../../assets/images/file/mp4.svg";
import mov from "../../../../../../assets/images/file/mov.svg";
import avi from "../../../../../../assets/images/file/avi.svg";
import flv from "../../../../../../assets/images/file/flv.svg";
import doc from "../../../../../../assets/images/file/doc.svg";
import xsl from "../../../../../../assets/images/file/xsl.svg";
import ppt from "../../../../../../assets/images/file/ppt.svg";
import png from "../../../../../../assets/images/file/png.svg";
import jpg from "../../../../../../assets/images/file/jpg.svg";
import gif from "../../../../../../assets/images/file/gif.svg";

type Props = {
  type: string;
};

const FileImage = (props: Props) => {
  const fileImage = (type: string) => {
    switch (type) {
      case EFileType.gif:
        return <ImageCustom src={gif} width="auto" height="20px" />;
      case EFileType.png:
        return <ImageCustom src={png} width="auto" height="20px" />;
      case EFileType.jpg:
      case EFileType.jpeg:
        return <ImageCustom src={jpg} width="auto" height="20px" />;
      case EFileType.pdf:
        return <ImageCustom src={pdf} width="auto" height="20px" />;
      case EFileType.mp4:
        return <ImageCustom src={mp4} width="auto" height="20px" />;
      case EFileType.mov:
        return <ImageCustom src={mov} width="auto" height="20px" />;
      case EFileType.avi:
        return <ImageCustom src={avi} width="auto" height="20px" />;
      case EFileType.flv:
        return <ImageCustom src={flv} width="auto" height="20px" />;
      case EFileType.doc:
      case EFileType.docx:
        return <ImageCustom src={doc} width="auto" height="20px" />;
      case EFileType.ppt:
      case EFileType.pptx:
        return <ImageCustom src={ppt} width="auto" height="20px" />;
      case EFileType.xlxs:
        return <ImageCustom src={xsl} width="auto" height="20px" />;
      default:
        return <ImageCustom src="" width="auto" height="20px" />;
    }
  };
  return <>{fileImage(props.type)}</>;
};

export default FileImage;
