import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";

const MainLayoutRoot = styled("div")(({ theme }) => ({}));

const MainLayout = () => {
  // let navigate = useNavigate();

  // useEffect(() => {
  //   const token = localStorage.getItem(USER_TOKEN_KEY);

  //   if (token) navigate(ROUTE_PATH.INDEX);
  // }, [navigate]);

  return (
    <MainLayoutRoot>
      <Outlet />
    </MainLayoutRoot>
  );
};

export default MainLayout;
