import {
    getMessaging,
    getToken,
    isSupported,
    MessagePayload,
    Messaging,
    onMessage
} from "firebase/messaging";
import { envConfig } from "./config/env.config";

import "firebase/messaging";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./common/constants/value.constant";

export const app = initializeApp(firebaseConfig);

let messaging: Messaging;
if (typeof window !== "undefined") {
    messaging = getMessaging(app);
}
export { messaging };

export async function requestPermission() {
    if (Notification.permission === "granted") { return true; } else {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                return true;
            } else {
                return false;
            }
        });
    }

};

export const getMessagingToken = async () => {
    if (!messaging) return null;
    try {
        const currentToken = await getToken(messaging, {
            vapidKey: envConfig.FIREBASE_FCM_VAPID_KEY
        });
        return currentToken;
    } catch (error) {
        return null;
    }
};

export const onMessageListener = () => {
    return new Promise(resolve => {
        if (!messaging) return null;
        onMessage(messaging, payload => {
            resolve(payload);
        });
    });
};
