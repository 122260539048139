import { styled } from "@mui/material";
import variableStyles from "../../../../../theme/variable-styles";


export const Wrapper = styled("div")(() => ({
  "& .stepper-container": {
    ".MuiSvgIcon-root": {
      //  borderRadius: "50%"
      //  color: "red"
      //  border: "1px solid #1976d2"
    },
    ".MuiSvgIcon-root:not(.Mui-completed)": {
      color: variableStyles.grey400
      //  backgroundColor: "red"
    },
    ".MuiStepIcon-text": {
      //  fill: "white",
      fontWeight: 500
    },
    ".MuiSvgIcon-root.Mui-active": {
      color: "#3091F3",
      padding: "2px",
      borderRadius: "50%",
      border: "1px solid #3091F3",
      marginY: "-2px"
    },
    ".Mui-active .MuiStepIcon-text": {
      fill: "white",
      fontWeight: "bold",
      fontSize: "16px"
    },

  }
}));
