import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AvatarImage from "../../assets/images/temp/temp_avatar.png";
import { Box, Tooltip } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { accountSidebarItems } from "../../routes";
import { SidebarWrapper } from "./styles";
import ImageCustom from "../Image";
import { IUser } from "../../models/user";
import { authTokens } from "../../services/services";

type Props = {
  onMobileClose: () => void;
  openMobile: boolean | undefined;
};

const AccountSidebar = ({ onMobileClose, openMobile }: Props) => {
  const location = useLocation();
  const [notExpanded, setNotExpanded] = useState<boolean>(false);
  useState<HTMLButtonElement | null>(null);
  const [userInfo, setUserInfo] = useState<IUser | null>(null);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
  }, []);

  const sidebarData = accountSidebarItems(userInfo?.roles);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(`${"Please enter username"}`),
    password: Yup.string().required(`${"Please enter password"}`)
  }) as any;

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    defaultValues: {
      username: null,
      password: null
    },
    resolver: yupResolver(validationSchema)
  });

  return (
    <>
      <SidebarWrapper notexpanded={notExpanded}>
        <Box className="sidebar-box">
          <ul
            className={`sidebar-menu ${notExpanded ? "notExpanded" : ""} scrollbar-small`}
          >
            {sidebarData.map((sidebar, index) => (
              <li key={index}>
                <Link
                  className={`sidebar-item ${location.pathname === sidebar?.href ? "active" : ""}`}
                  to={sidebar.href || "/"}
                >
                  <div
                    className={`icon-box ${location.pathname === sidebar?.href ? "active" : ""}`}
                  >
                    {location.pathname === sidebar?.href
                      ? sidebar?.icon?.active
                      : sidebar?.icon?.default}
                  </div>
                  <p>{sidebar?.text}</p>
                </Link>
              </li>
            ))}
          </ul>
        </Box>
      </SidebarWrapper>
    </>
  );
};

export default AccountSidebar;
