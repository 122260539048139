import { Box, Button, Container, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
import NOT_FOUND from "../assets/images/common/notfound.jpg";

const NotFound = () => (
  <Box sx={{ padding: 4 }} title="404 Page Not Found | Miko Admin">
    <Container>
      <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
        <motion.div>
          <Typography variant="h3" paragraph>
            Page not found
          </Typography>
        </motion.div>
        <Typography sx={{ color: "text.blueSecondary" }}>
          Cannot find this page
        </Typography>
        <motion.div>
          <Box
            component="img"
            src={NOT_FOUND}
            sx={{ height: 260, mx: "auto", my: { xs: 2, sm: 5 } }}
          />
        </motion.div>
        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Go to home page
        </Button>
      </Box>
    </Container>
  </Box>
);

export default NotFound;
