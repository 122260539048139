import { Badge, Box, IconButton } from "@mui/material";
import Avatar from "../../../components/Avatar";

import CameraAltIcon from '@mui/icons-material/CameraAltRounded';
import { ChangeEventHandler } from "react";
import { envConfig } from "../../../config/env.config";

type Props = {
     file: File | null;
     avatar?: string | null;
     onButtonClick: Function;
     handleFileChange: ChangeEventHandler<HTMLInputElement>;
     inputRef: any
};

const EditAvatar = (props: Props) => {
     const { file, onButtonClick, handleFileChange, inputRef, avatar } = props;

     const getAvatar = () => {
          const imgUrl = (!file && avatar) ? avatar : undefined;
          return file ? URL.createObjectURL(file) : imgUrl;
     }


     return (<Box textAlign="center">
          <input
               name="avatar"
               ref={inputRef}
               type="file"
               id="input-file-upload"
               multiple={false}
               onChange={handleFileChange}
               className="input-file-upload"
               hidden
          />
          <IconButton onClick={() => onButtonClick()}>
               <Badge
                    overlap="circular"
                    anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'right',
                    }}
                    badgeContent={<CameraAltIcon style={{ fontSize: 18 }} />}
               >
                    <Avatar alt="User" src={getAvatar()} isUrl={!file} width="52px" height="52px" />
               </Badge>
          </IconButton>
     </Box>);
};

export default EditAvatar;
