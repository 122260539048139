import styled from "@emotion/styled";


export const Wrapper = styled("form")((theme) => ({

    overflow: "auto",
    msOverflowStyle: "none",
    "::-webkit-scrollbar": {
        display: "none",
    },
    maxHeight: "calc(100vh - 264px)",

}));