import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

interface SidebarWrapperProps {
  notexpanded: boolean;
}

export const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden"
});

export const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingLeft: "300px",
  transition: "all 0.5s ease-in-out",
  paddingBottom: theme.spacing(10),
  backgroundColor: theme.palette.background.default
}));

export const SidebarWrapper = styled("div")<SidebarWrapperProps>(
  ({ theme, notexpanded }) => ({
    flex: "0 0 auto",
    position: "relative",
    borderRight: `1px solid ${variableStyles.NaturalColor200}`,
    "@keyframes hidden": {
      "0%": {
        opacity: "1"
      },
      "50%": {
        opacity: "1"
      },
      "100%": {
        display: "none",
        opacity: "0"
      }
    },
    "& .sidebar-box": {
      backgroundColor: "#22272D",
      transition: "all 0.25s linear",
      height: "100vh",
      overflow: "hidden",
      width: "100px"
    },

    "& .sidebar-menu": {
      overflow: "auto",
      "&.notExpanded": {
        transition: "all 0s linear 0.2s",
        height: "calc(100vh - 222px)"
      },
      "& .sidebar-item": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "16px",
        position: "relative",
        fontSize: "11px",
        color: "#65686C",
        "&:hover": {
          color: "white",
          fontWeight: "bold"
        },
        "&.active": {
          color: "white",
          fontWeight: "bold"
        },
        "& .icon-box": {
          "&:hover, &.Mui-focusVisible": { color: "white" },
          "&.active": { color: "white" }
        }
      }
    }
  })
);

export const NavbarWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  backgroundColor: variableStyles.GreenPrimaryColor950,
  padding: "6px 12px",
  "& .header-navigate": {
    display: "flex",
    gap: "36px",
    alignItems: "center",
    "& .header-menu": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "32px",
      "& .menu-item": {
        paddingBottom: "3px",
        "& a": {
          color: variableStyles.NaturalColor300,
          fontWeight: variableStyles.fwSemiBold,
          fontSize: "12px",
          lineHeight: "15px",
          transition: "all 0.2s ease-out",
          "&:hover": {
            color: variableStyles.NaturalColor50
          }
        }
      }
    }
  },
  "& .header-action": {
    display: "flex",
    alignItems: "center",
    "& .action-search": {
      marginRight: "12px",
      flex: "0 0 auto",
      width: "200px"
    },
    "& .actice-group": {
      display: "flex",
      alignItems: "center",
      marginRight: "8px",
      gap: "8px",
      flex: "0 0 auto",
      "& button": {
        padding: "6px",
        opacity: ".5",
        transition: "all 0.2s ease-out",
        "&:hover": {
          opacity: "1"
        }
      }
    },
    "& .action-profile": {
      flex: "0 0 auto",
      padding: "4px"
    }
  }
}));

export const PlanPopoverWrapper = styled(Popover)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    marginLeft: "14px"
  }
}));

export const ActionPopoverWrapper = styled(Popover)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    marginTop: "19px",
    padding: "12px 8px",
    border: `1px solid ${variableStyles.NaturalColor100}`,
    boxShadow: "0px 21px 37px -15px #1956580F",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    width: "238px",
    "& .MuiDivider-root": {
      borderColor: variableStyles.NaturalColor200
    },
    "& .profile-infor": {
      display: "flex",
      gap: "12px",
      "& .detail-name": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "14px",
        lineHeight: "14px",
        marginBottom: "4px"
      },
      "& .detail-email": {
        color: variableStyles.NaturalColor900,
        fontWeight: variableStyles.fwRegular,
        fontSize: "12px",
        lineHeight: "12px"
      }
    },
    "& .profile-actions": {
      "& li": {
        "& a": {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          color: variableStyles.NaturalColor900,
          fontWeight: variableStyles.fwRegular,
          fontSize: "14px",
          lineHeight: "14px",
          padding: "10px 6px",
          borderRadius: "6px",
          transition: "all .2s linear",
          "&:hover": {
            backgroundColor: variableStyles.NaturalColor100
          }
        }
      }
    },
    "& .profile-plan": {
      display: "flex",
      justifyContent: "space-between",
      padding: "2px 6px",
      alignItems: "center",
      "& .infor-title": {
        color: variableStyles.NaturalColor950,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "14px",
        lineHeight: "14px",
        marginBottom: "6px"
      },
      "& .infor-description": {
        color: variableStyles.NaturalColor600,
        fontWeight: variableStyles.fwMedium,
        fontSize: "12px",
        lineHeight: "12px"
      },
      "& button": {
        padding: "9px 18px"
      }
    },
    "& .profile-logout": {
      gap: "8px",
      color: variableStyles.Error500,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "14px",
      lineHeight: "14px",
      padding: "6px",
      width: "100%",
      justifyContent: "flex-start",
      borderRadius: "6px",
      "&:hover": {
        color: `${variableStyles.Error500} !important`,
        backgroundColor: variableStyles.Error50
      }
    }
  }
}));

export const ProjectPopoverWrapper = styled(Popover)(() => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    marginLeft: "28px",
    border: `1px solid ${variableStyles.NaturalColor100}`,
    padding: "12px 8px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    width: "238px",
    "& .project-list": {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      "& li": {
        "& a": {
          borderRadius: "12px",
          padding: "6px",
          display: "flex",
          alignItems: "center",
          transition: "all 0.2s linear",
          "&:hover": {
            backgroundColor: variableStyles.NaturalColor100
          },
          "& .infor-box": {
            display: "flex",
            gap: "12px",
            alignItems: "center",
            width: "222px",
            "& .project-avatar": {
              flex: "0 0 auto"
            },
            "& .project-name": {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "4px",
              flex: "1 1 auto",
              "& .name-project": {
                color: variableStyles.NaturalColor950,
                fontWeight: variableStyles.fwSemiBold,
                fontSize: "14px",
                lineHeight: "14px"
              },
              "& .name-domain": {
                color: variableStyles.NaturalColor900,
                fontWeight: variableStyles.fwRegular,
                fontSize: "12px",
                lineHeight: "12px"
              }
            }
          }
        }
      }
    },
    "& .MuiDivider-root": {
      borderColor: variableStyles.NaturalColor200
    },
    "& .create-button": {
      gap: "8px"
    }
  }
}));
