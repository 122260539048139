import { Box, Typography } from "@mui/material";
import React from "react";
import Avatar from "../../../components/Avatar";
import CircleIcon from "@mui/icons-material/Circle";
import { envConfig } from "../../../config/env.config";
import AvatarImage from "../../../assets/images/common/avatar.png";

type Props = {
  name: string | null;
  tag: string;
  position: string;
  avatar?: string | null;
};

const AccountLayout = (props: Props) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Avatar
        src={props?.avatar}
        alt="Avatar-Image"
        width={"34px"}
        height={"34px"}
      />
      <div style={{ marginLeft: "12px" }}>
        <Typography variant="subtitle2">{props.name}</Typography>
        <Box sx={{ display: "flex" }}>
          <Typography variant="caption">{props.tag}</Typography>
          <CircleIcon sx={{ width: "2px", margin: "0 8px" }} />
          <Typography variant="caption">{props.position}</Typography>
        </Box>
      </div>
    </Box>
  );
};

export default AccountLayout;
