import React, { forwardRef, useEffect, useState } from "react";
import { Button, Slide } from "@mui/material";
import { BehaviorSubject } from "rxjs";
import { Wrapper } from "./ConfirmModalStyles";
import { useTranslation } from "react-i18next";
import variableStyles from "../../theme/variable-styles";
import IconWarningYellow from "../../assets/images/common/icon_warning_fill_yellow.png";
import IconInfor from "../../assets/images/common/icon_infor_fill_blue.png";
import IconWarningRed from "../../assets/images/common/icon_warning_fill_red.png";
import IconConfirm from "../../assets/images/common/icon_confirm_fill_green.png";
import { ImageCustom } from "..";
import { TransitionProps } from "@mui/material/transitions";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ConfirmModalProps {
  open?: boolean;
  title: string;
  content: React.ReactNode;
  submitText?: string;
  onSubmit: () => Promise<void>;
  cancelText?: string;
  onCancel?: () => Promise<void>;
  type?: "warning" | "confirm" | "infor" | "warning-red";
}

const confirmModalSubject = new BehaviorSubject<ConfirmModalProps>({
  open: false,
  title: "Content Warning Text",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  eiusmo",
  submitText: "",
  onSubmit: async () => {},
  type: "confirm"
});

export const toggleConfirmModal = (value: ConfirmModalProps) => {
  confirmModalSubject.next(value);
};

export default function ConfirmModal() {
  const { t } = useTranslation();

  const [data, setData] = useState<ConfirmModalProps>({
    open: false,
    title: "",
    content: "",
    submitText: "",
    onSubmit: async () => {},
    type: "confirm"
  });

  useEffect(() => {
    const subscribe = confirmModalSubject.subscribe(value => {
      let params = { ...value };

      if (value.open === undefined) {
        params.open = true;
      }

      setData(params);
    });
    return () => {
      subscribe.unsubscribe();
    };
  }, []);

  const onCancel = () => {
    data.onCancel && data.onCancel();
    setData({ ...data, open: false });
  };

  const onSubmit = () => {
    data.onSubmit && data.onSubmit();
    setData({ ...data, open: false });
  };

  const cancelText = data.cancelText ? data.cancelText : t("Common.Cancel");
  const submitText = data.submitText ? data.submitText : t("Common.Confirm");

  return (
    <Wrapper
      open={data.open ?? false}
      width={400}
      BackdropProps={{
        style: {
          background: variableStyles.NaturalColor1000,
          opacity: ".3"
        }
      }}
      TransitionComponent={Transition}
    >
      <figure className="modal-icon">
        <ImageCustom
          src={
            data.type === "confirm"
              ? IconConfirm
              : data.type === "infor"
                ? IconInfor
                : data.type === "warning-red"
                  ? IconWarningRed
                  : IconWarningYellow
          }
          alt="Modal-Icon"
          width={"44px"}
          height={"44px"}
        />
      </figure>
      <p className="modal-title">{data.title}</p>
      <p className="modal-cotent">{data.content}</p>
      <div className="modal-action">
        <Button
          onClick={onCancel}
          size="xsmall"
          variant="outlined"
          className="action-cancel"
        >
          {cancelText ? cancelText : "Cancel"}
        </Button>
        <Button
          size="xsmall"
          variant="contained"
          onClick={onSubmit}
          className={`action-confirm ${data.type === "warning-red" ? "warning-red" : ""}`}
        >
          {submitText ? submitText : "Confirm"}
        </Button>
      </div>
    </Wrapper>
  );
}
