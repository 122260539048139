import { styled } from "@mui/material";
import variableStyles from "../../theme/variable-styles";

export const Wrapper = styled("div")(() => ({
  height: "100vh",
  "& .grid-layout-container": {
    display: "flex",
    flexWrap: "wrap",
    "& .grid-layout-card": {
      backgroundColor: "white",
      margin: " 16px auto",
      borderRadius: "4px",
      "& .card-member": {
        marginTop: "8px",
        display: "flex",
        justifyContent: "space-between"
      },
      "& .MuiAvatar-root": {
        width: 24,
        height: 24,
        fontSize: "8px",
        fontWeight: "bold",
        color: "black"
        // backgroundColor: "blu"
      },

      "& .MuiAutocomplete-option": {
        color: "red"
      }
      // "& .card-name": {
      //   fontSize: "16px",
      //   fontWeight: "600"
      // },
      // "& .card-tag": {
      //   fontWeight: "600"
      // },
      // "& .card-description": {
      //   marginTop: "8px",
      //   padding: "2px 0"
      // },
      // "& .card-text": { padding: "2px 0" }
    }
  },
  "& .MuiFilledInput-root": {
    backgroundColor: variableStyles.Error300,
    borderRadius: "8px",
    border: "none",
    width: "20%"
    // marginRight: "12px"
  },
  "& .MuiFilledInput-root:hover": {
    backgroundColor: variableStyles.NaturalColor50,
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: variableStyles.NaturalColor50
      // Reset on touch devices, it doesn't add specificity
    }
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: "rgb(250, 241, 232)",
    border: "none"
  },
  // "& .MuiInputBase-root": {
  //   // backgroundColor: "green",
  //   borderRadius: "8px",
  //   padding: "6px 12px",
  //   width: "30%",
  //   border: "1px solid GrayText"
  // },
  "& .MuiTextField-root": {
    // marginLeft: "12px",
    // fontSize: "14px",
    // width: "230px"
  }
}));
