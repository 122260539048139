import { IChangePasswordByAdminReq, ITag } from "./../../user/index";
import {
  ICreateUserRequest,
  IGetUsersReq,
  ITagReq,
  IUpdateByAdminRequest,
  IUser
} from "../../user";

export interface IReduxUserState {
  data?: IUser | null;
  listData?: IUser[] | null;
  itags?: ITag[] | null;
  error?: string | null;
  success?: boolean | null;
  notification?: number;
}

export enum EUserType {
  //*Get
  SET_USER_STATE = "SET_USER_STATE",
  SET_USERS_STATE = "SET_USERS_STATE",
  GET_USER = "GET_USER",
  GET_USERS = "GET_USERS",
  GET_USER_OR_DEPARTMENT = "GET_USER_OR_DEPARTMENT",
  SET_ITAG = "SET_ITAG",
  //*Create
  CREATE_USER = "CREATE_USER",
  CREATE_USER_FAILED = "CREATE_USER_FAILED",
  CREATE_SUCCESS = "CREATE_SUCCESS",
  //*Update
  ADMIN_UPDATE_USER = "UPDATE_USER",
  ADMIN_UPDATE_SUCCESS = "UPDATE_SUCCESS",
  ADMIN_UPDATE_FAILED = "UPDATE_FAILED",
  ADMIN_CHANGE_PASSWORD = "ADMIN_CHANGE_PASSWORD",
  //*Delete
  CLEAR_USER = "CLEAR_USER",
  INCREMENT_NOTIFICATION = "INCREMENT_NOTIFICATION",
  SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT"
}
//?Get
export interface IActionSetUserState {
  type: EUserType.SET_USER_STATE;
  payload: { data: IUser };
}

export interface IActionSetUsersState {
  type: EUserType.SET_USERS_STATE;
  payload: { listData: IUser[] };
}

export interface IActionSetItag {
  type: EUserType.SET_ITAG;
  payload: { itags: ITag[] };
}

export interface IActionGetUser {
  type: EUserType.GET_USER;
  payload: {};
}

export interface IActionIncrementNotification {
  type: EUserType.INCREMENT_NOTIFICATION;
  payload: {};
}

export interface IActionSetNotificationCount {
  type: EUserType.SET_NOTIFICATION_COUNT;
  payload: {};
}

export interface IActionGetUsers {
  type: EUserType.GET_USERS;
  payload: { request: IGetUsersReq };
}

export interface IActionGetUserOrDepartment {
  type: EUserType.GET_USER_OR_DEPARTMENT;
  payload: { request: ITagReq };
}

//?Create
export interface IActionCreateUserFailed {
  type: EUserType.CREATE_USER_FAILED;
  payload: { message: string };
}

export interface IActionClearUser {
  type: EUserType.CLEAR_USER;
  payload: {};
}

export interface IActionCreateUser {
  type: EUserType.CREATE_USER;
  payload: { request: ICreateUserRequest };
}

export interface IActionUpdateUserByAdmin {
  type: EUserType.ADMIN_UPDATE_USER;
  payload: { request: IUpdateByAdminRequest };
}

export interface IActionChangePasswordByAdmin {
  type: EUserType.ADMIN_CHANGE_PASSWORD;
  payload: { request: any };
}

export interface IActionCreateUserSuccess {
  type: EUserType.CREATE_SUCCESS;
  payload: {};
}

export interface IActionUpdateUserByAdminSuccess {
  type: EUserType.ADMIN_UPDATE_SUCCESS;
  payload: {};
}

export interface IActionUpdateUserByAdminFailed {
  type: EUserType.ADMIN_UPDATE_FAILED;
  payload: { message: string };
}

//?Update

export interface IActionAdminUpdateUserSuccess {
  type: EUserType.ADMIN_UPDATE_SUCCESS;
  payload: {};
}

export interface IActionAdminUpdateUserFailed {
  type: EUserType.ADMIN_UPDATE_FAILED;
  payload: { message: string };
}

export type IActionUser =
  | IActionSetUserState
  | IActionSetUsersState
  | IActionGetUser
  | IActionGetUsers
  | IActionCreateUserFailed
  | IActionCreateUser
  | IActionCreateUserSuccess
  | IActionAdminUpdateUserFailed
  | IActionUpdateUserByAdmin
  | IActionAdminUpdateUserSuccess
  | IActionChangePasswordByAdmin
  | IActionSetItag
  | IActionIncrementNotification
  | IActionSetNotificationCount
  | IActionClearUser;
