import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Theme } from "@mui/material";
import { MenuBasicWrapper } from "./styles";
import variableStyles from "../../theme/variable-styles";
import palette from "../../theme/palette";

export interface IMenuItem {
  value: any;
  label: React.ReactNode;
}

interface IProps {
  onChange: (value: any) => void;
  label: React.ReactNode;
  options: IMenuItem[];
  padding?: string | number;
}

export default function BasicMenu({
  onChange,
  options,
  label,
  padding
}: IProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeValue = (value: any) => {
    onChange(value);
    handleClose();
  };

  return (
    <MenuBasicWrapper>
      <Box
        sx={{
          cursor: "pointer"
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className={`menu-button ${open ? "active" : ""}`}>{label}</div>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "12px",
              boxShadow: "0px 5px 16px 0px #0000001F",
              minWidth: "150px",
              "& .MuiList-root": {
                "& li": {
                  padding: padding ?? "11px 12px",
                  fontFamily: variableStyles.Inter,
                  fontWeight: variableStyles.fwMedium,
                  fontSize: "14px",
                  lineHeight: "19.6px",
                  color: variableStyles.colorBlack,
                  transition: "color .25s linear",
                  "&:hover": {
                    color: palette.secondary.dark
                  }
                }
              }
            }
          }
        }}
      >
        {options.map((item, i) => (
          <MenuItem key={i} onClick={() => onChangeValue(item.value)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </MenuBasicWrapper>
  );
}
