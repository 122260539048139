import React, { useEffect, useState } from "react";
import { ControlForm, Modal } from "../../../components";
import { TagInput } from "../../WorkflowManagement/components/TagInput/TagInput";
import { ITaskCard, ITaskCardUpdateRequest } from "../../../models/Task";
import { TagType } from "../../../models/common/models.enum";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ITag } from "../../../models/user";
import useAxios from "../../../components/UseAxios/useAxios";
import TaskService from "../../../services/api/task.service";
import { toggleMessage } from "../../../components/Toast/Toast";

interface EditTrackerProps {
  open: boolean;
  onClose: () => void;
  onUpdateSuccess: () => void;
  task: ITaskCard;
}

interface FormData {
  trackers: ITag[];
}

const EditTrackerModal: React.FC<EditTrackerProps> = ({
  open,
  onClose,
  onUpdateSuccess,
  task
}) => {
  const updateTrackersAxios = useAxios<ITaskCard>({ loading: "OnRequest" });

  const validationSchema = Yup.object().shape({}) as any;

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data: FormData) => {
    const request: ITaskCardUpdateRequest = {
      id: task.id,
      workflowId: task.workflowId,
      boardId: task.boardId,
      title: task.title,
      taskTrackerIds: data.trackers?.length
        ? data.trackers.map(fl => fl.id).join(",")
        : null
    };

    updateTrackersAxios.request(TaskService.updateTask(request));
  };

  useEffect(() => {
    if (open) {
      reset({
        trackers:
          task.taskTrackers?.map(tracker => ({
            id: tracker.id,
            username: tracker.userName,
            fullName: tracker.fullName
          })) || []
      });
      updateTrackersAxios.reset();
    }
  }, [open, task, reset]);

  useEffect(() => {
    if (updateTrackersAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: updateTrackersAxios.message ?? "Cập nhật thành công"
      });
      onUpdateSuccess();
      onClose();
    }
    if (updateTrackersAxios.error) {
      toggleMessage({
        type: "error",
        message: updateTrackersAxios.error?.message ?? ""
      });
    }
  }, [updateTrackersAxios.isSuccess, updateTrackersAxios.error]);

  return (
    <Modal
      title="Chỉnh sửa người theo dõi"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={updateTrackersAxios.isLoading}
      disabledSubmit={updateTrackersAxios.isLoading}
      textSubmit="Cập nhật"
      textClose="Huỷ"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ControlForm title="Người theo dõi" classname="brand-form">
          <TagInput
            name={"trackers"}
            control={control}
            store={"stage"}
            storeId={task.workflowId}
            defaultValues={getValues("trackers")}
            errors={errors}
            type={TagType.User}
            isError={!!errors.trackers}
            placeholder={"Sử dụng @ để tag người theo dõi nhiệm vụ"}
          />
        </ControlForm>
      </form>
    </Modal>
  );
};

export default EditTrackerModal;
