import {
  AvatarGroup,
  Box,
  Button,
  Card,
  Divider,
  List,
  ListItem,
  SxProps,
  Theme,
  Typography
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import AssistantPhotoRoundedIcon from "@mui/icons-material/AssistantPhotoRounded";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import { Wrapper } from "./styles";

import { Avatar } from "../../../../../components";
import { BorderLinearProgress } from "../../../../WorkflowManagement/components/WorkflowItem/border_linear_progress";
import variableStyles from "../../../../../theme/variable-styles";
import { IStageHistory, ITask } from "../../../../../models/Task";
import moment from "moment";
import dayjs from "dayjs";
import { EStageType } from "../../../../../models/common/models.enum";
import EditTrackerModal from "../../../EditTaskTrackerModal/EditTrackerModal";
import { fTime } from "../../../../../utils/formatTime";

type JobInformationProps = {
  rows: {
    icon: React.ReactNode;
    title: string;
    content?: string | undefined;
    createAt?: string | undefined;
  }[];
};

interface Props {
  canUpdateTrackers: boolean;
  task: ITask;
  stagesHistorys: IStageHistory[];
  onUpdateTrackersSuccess: () => void;
}
interface ProcessProps {
  task: ITask;
  stagesHistorys: IStageHistory[];
}

const calculatePercentage = ({
  expectedTime,
  completionTime
}: {
  expectedTime: string | null;
  completionTime: string | null;
}): { percentage: number; isOver: boolean } => {
  if (!completionTime) return { percentage: 0, isOver: false };

  if (expectedTime) {
    if (fTime(expectedTime)!.seconds === 0)
      return { percentage: 0, isOver: false };
    const percentage =
      fTime(completionTime)!.seconds / fTime(expectedTime)!.seconds;

    if (percentage > 1 && percentage % 1 === 0) {
      return { percentage: 1, isOver: true };
    } else if (percentage > 1 && percentage % 1 < 1) {
      return { percentage: percentage % 1, isOver: true };
    } else {
      return { percentage: percentage, isOver: false };
    }
  }

  return { percentage: 0, isOver: false };
};

const CurrentStage = ({
  index,
  task,
  stages
}: {
  index: number;
  task: ITask;
  stages: IStageHistory[];
}) => {
  let allStage = stages.length;
  const currentStage = stages[index];
  return (
    <>
      <Card
        sx={{
          padding: "8px 16px 8px 16px",
          backgroundColor:
            currentStage.type === EStageType.Cancel
              ? "#b72727"
              : currentStage.type === EStageType.Complete
                ? "#53b727"
                : "#3091F3",
          color: "white",
          borderRadius: "3px 3px 0 0"
        }}
      >
        {currentStage.type !== EStageType.Step && (
          <>
            <Box display={"flex"} flexDirection={"row"} gap={1} padding={0.7}>
              <CheckCircleRoundedIcon />
              <Box>
                <Typography variant="subtitle1" fontSize={15}>
                  <Box
                    component="span"
                    fontWeight="fontWeightMedium"
                    color={variableStyles.NaturalColor300}
                  >
                    Giai đoạn:{" "}
                  </Box>
                  {currentStage.name ?? ""}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={13}
                  paddingTop={0.5}
                  color={variableStyles.NaturalColor300}
                >
                  {currentStage.type === EStageType.Complete
                    ? "Đã hoàn thành"
                    : currentStage.type === EStageType.Cancel
                      ? "Đã bị hủy"
                      : "Đã thất bại"}
                </Typography>
              </Box>
            </Box>
          </>
        )}
        {currentStage.type === EStageType.Step && (
          <>
            <Typography
              variant="overline"
              fontSize={12}
              color={variableStyles.NaturalColor200}
            >
              giai đoạn hiện tại
            </Typography>
            <Typography variant="subtitle1" fontSize={15} paddingTop={1}>
              [{index + 1}/{allStage}] {task.info.currentStage}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: "8px 0"
              }}
            >
              <Typography variant="caption" fontSize={11} fontWeight={500}>
                THỜI HẠN:{" "}
                {task.taskCard.deadLine
                  ? dayjs(task.taskCard.deadLine).format("HH:mm DD/MM/YYYY")
                  : "Không thời hạn"}
              </Typography>
              {/* <Typography variant="caption" fontSize={11} fontWeight={500}>
            Đã bắt đầu 9 ngày trước
          </Typography> */}
            </Box>
            <BorderLinearProgress
              variant="determinate"
              value={0}
              height={8}
              radius={2}
              barcolor="#308fe8"
              background="#2674c2"
            />
            {stages[index].histories && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "8px 0"
                }}
              >
                <Typography variant="caption" fontSize={11} fontWeight={500}>
                  KỲ VỌNG:{" "}
                  {(() => {
                    const time = fTime(stages[index].histories!.expectedTime);
                    if (!time) return "0m";
                    return (
                      `${time.hours ? time.hours + "h" : ""}${time.minutes ? time.minutes + "m" : ""}`.trim() ||
                      "0m"
                    );
                  })()}
                </Typography>
                <Typography variant="caption" fontSize={11} fontWeight={500}>
                  ĐÃ SỬ DỤNG:{" "}
                  {(() => {
                    const time = fTime(stages[index].histories!.completionTime);
                    if (!time) return "0m";
                    return (
                      `${time.hours ? time.hours + "h" : ""}${time.minutes ? time.minutes + "m" : ""}`.trim() ||
                      "0m"
                    );
                  })()}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Card>

      {index < stages.length - 1 && (
        <Card
          sx={{
            borderRadius: "0 0 3px 3px",
            backgroundColor: "#2E8AE7",
            display: "flex",
            padding: "6px 12px",
            alignItems: "center"
          }}
        >
          <KeyboardDoubleArrowRightRoundedIcon
            sx={{ color: "white", height: 14, width: 14 }}
          />
          <Typography
            variant="overline"
            fontSize={12}
            color={variableStyles.NaturalColor200}
          >
            {"GIAI ĐOẠN KẾ TIẾP: "}
          </Typography>
          <Typography
            variant="caption"
            fontSize={13}
            color="white"
            fontWeight={700}
            paddingX={0.5}
            lineHeight={1}
          >
            {stages[index + 1].name}
          </Typography>
          <Typography
            variant="subtitle2"
            fontSize={12}
            fontWeight={600}
            lineHeight={1.5}
            color={variableStyles.NaturalColor200}
          >
            {" (0m)"}
          </Typography>
        </Card>
      )}
    </>
  );
};

const JobInformation = ({ rows }: JobInformationProps) => {
  return (
    <Card sx={{ margin: "10px 0", padding: "12px", borderRadius: "3px" }}>
      <Typography variant="overline" color={"#858585"} fontWeight={600}>
        Thông tin nhiệm vụ
      </Typography>
      <List>
        {rows.map(row => (
          <ListItem key={row.title} sx={{ padding: "2px 0" }}>
            {row.icon}
            <Typography variant="body2" fontSize={13} margin="0 4px">
              {row.title}
            </Typography>
            {row.content && (
              <Typography
                variant="body2"
                fontSize={13}
                fontWeight={600}
                marginRight="4px"
              >
                {row.content}
              </Typography>
            )}
            {row.createAt && (
              <Typography variant="body2" fontSize={13} marginRight="4px">
                {row.createAt}
              </Typography>
            )}
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

const Progress = ({ task, stagesHistorys }: ProcessProps) => {
  const hourUnit = "h";
  const minuteUnit = "m";

  const currentStage = stagesHistorys.find(
    e => e.id === task.taskCard.workflowId
  );

  const currentStageIndex = stagesHistorys.findIndex(
    e => e.id === task.taskCard.workflowId
  );

  const calculateTotalTime = useCallback(
    (timeType: "expected" | "actual"): string => {
      const totalMinutes = task.stages.reduce((total, stage) => {
        const time =
          timeType === "expected"
            ? stage.histories?.expectedTime
            : stage.histories?.completionTime;
        if (time) {
          const [hours, minutes] = time.includes(":")
            ? time.split(":").map(Number)
            : [Number(time), 0];
          return total + hours * 60 + minutes;
        }
        return total;
      }, 0);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours}:${minutes < 10 ? minutes.toString() : minutes.toString().padStart(2, "0")}`;
    },
    [task.stages]
  );

  const getTotalExpectedTime = useMemo(
    () => calculateTotalTime("expected"),
    [calculateTotalTime]
  );
  const getTotalActualTime = useMemo(
    () => calculateTotalTime("actual"),
    [calculateTotalTime]
  );

  const { percentage, isOver } = calculatePercentage({
    expectedTime: getTotalExpectedTime,
    completionTime: getTotalActualTime
  });

  const renderTime = (time: string | null) => {
    const defaultComponent = (
      <>
        <Box
          component="span"
          fontWeight="fontWeightMedium"
          color={variableStyles.colorBlack}
        >
          {"0"}
        </Box>
        m
      </>
    );
    if (!time) {
      return defaultComponent;
    }
    const timeFormated = fTime(time);
    if (!timeFormated) return <></>;
    if (!timeFormated.hours && !timeFormated.minutes) {
      return defaultComponent;
    }
    return (
      <>
        {timeFormated.hours && (
          <>
            <Box
              component="span"
              fontWeight="fontWeightMedium"
              color={variableStyles.colorBlack}
            >
              {timeFormated.hours}
            </Box>
            {hourUnit}
          </>
        )}
        {timeFormated.minutes && (
          <>
            {" "}
            <Box
              component="span"
              fontWeight="fontWeightMedium"
              color={variableStyles.colorBlack}
            >
              {timeFormated.minutes}
            </Box>
            {minuteUnit}
          </>
        )}
      </>
    );
  };

  const StageIndex = ({
    stage,
    isComplete: isCompleted,
    isCancel,
    index
  }: {
    index: number;
    stage: IStageHistory;
    isComplete: boolean;
    isCancel: boolean;
  }) => {
    const isActive = stage.histories;

    const colors = (): { bg: string; border: string } => {
      if (isActive && isCompleted) {
        return {
          bg: "#6ad63a",
          border: "#53b727"
        };
      }
      if (isActive && isCancel) {
        return {
          bg: "#b72727",
          border: "#891818"
        };
      }
      if (isActive) {
        return {
          bg: "#3091F3",
          border: "#70aef8"
        };
      }
      return {
        bg: "#c2c2c2",
        border: "white"
      };
    };

    return (
      <Box
        width={20}
        height={20}
        borderRadius={"50%"}
        bgcolor={colors().border}
        border={"1px solid #c2c2c2"}
        color={"white"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          width={14}
          height={14}
          borderRadius={"50%"}
          bgcolor={colors().bg}
          textAlign={"center"}
          fontSize={10}
          lineHeight={1.5}
        >
          {index}
        </Box>
      </Box>
    );
  };

  const ProgressItem = ({
    index,
    stage,
    noHistoryValue,
    isComplete,
    isCancel
  }: {
    index: number;
    stage: IStageHistory;
    noHistoryValue: string;
    isComplete: boolean;
    isCancel: boolean;
  }) => {
    const { percentage, isOver } = calculatePercentage({
      expectedTime: stage.histories?.expectedTime ?? null,
      completionTime: stage.histories?.completionTime ?? null
    });

    const isShowTime = stage.histories && stage.type === EStageType.Step;

    return (
      <>
        <Divider sx={{ margin: "10px 0", borderColor: "#eeeeee" }} />
        <Box display={"flex"} flexDirection={"row"}>
          <StageIndex
            index={index}
            stage={stage}
            isComplete={isComplete}
            isCancel={isCancel}
          />
          <Box
            display={"flex"}
            flexDirection={"column"}
            flexGrow={1}
            marginLeft={1.5}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Typography
                variant="caption"
                fontWeight={500}
                fontSize={12}
                lineHeight={1}
              >
                {stage.name}
              </Typography>
              {stage.histories?.completionDateTime && (
                <Typography
                  variant="caption"
                  fontSize={12}
                  fontWeight={500}
                  color={"#a9a9a9"}
                >
                  {dayjs(stage.histories.completionDateTime).format(
                    "HH:mm DD/MM/YYYY"
                  )}
                </Typography>
              )}
            </Box>
            {stage.histories && (
              <>
                <BorderLinearProgress
                  variant="determinate"
                  value={percentage * 100}
                  height={8}
                  radius={2}
                  barcolor={isOver ? "#be495d" : "#53b727"}
                  background="#eeeeee"
                  sx={{ margin: "4px 0" }}
                />
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                >
                  {isShowTime && (
                    <Typography
                      variant="caption"
                      fontSize={12}
                      fontWeight={500}
                      color={"#a9a9a9"}
                    >
                      Kỳ vọng: {renderTime(stage.histories.expectedTime)}
                    </Typography>
                  )}
                  {isShowTime && (
                    <Typography
                      variant="caption"
                      fontSize={12}
                      fontWeight={500}
                      color={"#a9a9a9"}
                    >
                      Thực tế: {renderTime(stage.histories.completionTime)}
                    </Typography>
                  )}
                </Box>
                {stage.histories.user && (
                  <Box
                    display="flex"
                    flexDirection={"row"}
                    alignItems={"center"}
                    margin={"4px 0"}
                  >
                    <Avatar
                      src={stage.histories.user.avatar}
                      alt="Avatar-Image"
                      width={20}
                      height={20}
                    />

                    <Typography variant="caption" paddingLeft={1}>
                      {stage.histories.user.fullName}
                    </Typography>
                  </Box>
                )}
              </>
            )}
            {!stage.histories && (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                paddingTop={0.6}
              >
                <Typography
                  variant="caption"
                  fontSize={12}
                  fontWeight={500}
                  color={"#a9a9a9"}
                >
                  Thời lượng:
                  <Box
                    component="span"
                    fontWeight="fontWeightMedium"
                    color={variableStyles.colorBlack}
                  >
                    {" 0"}
                  </Box>
                  {minuteUnit}
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={12}
                  fontWeight={500}
                  color={"#a9a9a9"}
                >
                  {noHistoryValue}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Card sx={{ marginTop: "10px", padding: "16px", borderRadius: "3px" }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="overline" color={"#858585"} fontWeight={600}>
          TỔNG THỜI GIAN
        </Typography>
        <Typography variant="caption" color={"#858585"}>
          {"Đã sử dụng "}
          {renderTime(getTotalActualTime)}
          {" của "}
          {renderTime(getTotalExpectedTime)}
        </Typography>
      </Box>

      <BorderLinearProgress
        variant="determinate"
        value={percentage}
        height={8}
        radius={2}
        barcolor={isOver ? "#be495d" : "#53b727"}
        background="#eeeeee"
        sx={{ margin: "8px 0" }}
      />

      <Typography variant="overline" fontWeight={600}>
        TIẾN TRÌNH CÁC GIAI ĐOẠN
      </Typography>

      {stagesHistorys.map((e, i) => (
        <ProgressItem
          index={i}
          stage={e}
          key={e.id}
          noHistoryValue={
            currentStageIndex < i
              ? "Giai đoạn chờ đến lượt"
              : currentStageIndex > i
                ? "Giai đoạn bị bỏ qua"
                : ""
          }
          isComplete={currentStage?.type === EStageType.Complete}
          isCancel={currentStage?.type === EStageType.Cancel}
        />
      ))}
      <Divider sx={{ margin: "8px 0", borderColor: "#eeeeee" }} />
    </Card>
  );
};

const JobDetailProgress = ({
  canUpdateTrackers,
  task,
  stagesHistorys,
  onUpdateTrackersSuccess
}: Props) => {
  let currentStageIndex = stagesHistorys.findIndex(
    e => e.id === task.taskCard.workflowId
  );

  const [openUpdateTrackers, setOpenUpdateTrackers] = useState(false);

  const getDate = useCallback(
    (date: string | null, type?: "short") => {
      if (!date) return "";
      return moment(date).format(
        type === "short" ? "DD/MM/YYYY" : "HH:mm DD/MM/YYYY"
      );
    },

    []
  );

  const sx: SxProps<Theme> = {
    width: 16,
    color: "grey",
    marginRight: "4px"
  };

  const rows = [
    // {
    //   icon: <TagRoundedIcon sx={sx} />,
    //   title: "Mã nhiệm vụ:",
    //   content: "1725289"
    // },
    {
      icon: <AccountCircleRoundedIcon sx={sx} />,
      title: "Tạo bởi",
      content: task.info.creator ?? "",
      createAt: "lúc " + getDate(task.info.createdDate)
    },
    {
      icon: <AccessTimeRoundedIcon sx={sx} />,
      title: "Cập nhật gần nhất",
      createAt:
        "lúc " +
        getDate(task.info.modifiedDate ?? task.info.createdDate, "short")
    },
    {
      icon: <AssistantPhotoRoundedIcon sx={sx} />,
      title: "Giai đoạn hiện tại:",
      content: stagesHistorys[currentStageIndex].name
    },
    {
      icon: <AccessTimeRoundedIcon sx={sx} />,
      title: "Chuyển giai đoạn",
      createAt: "lúc " + getDate(task.info.stageTransitionAt)
    },
    {
      icon: <BookmarkRoundedIcon sx={sx} />,
      title: "Người quản trị giai đoạn: ",
      content: task.info.managerStage ?? "Chưa thiết lập"
    }
  ];

  return (
    <Wrapper>
      <CurrentStage
        stages={stagesHistorys}
        task={task}
        index={currentStageIndex}
      />
      <JobInformation rows={rows} />
      <Card
        sx={{
          margin: "10px 0",
          padding: "12px",
          display: "flex",
          flexDirection: "column",
          justifyItems: "flex-start",
          borderRadius: "3px"
        }}
      >
        <Box display={"flex"} flexDirection={"row"}>
          <Typography
            variant="overline"
            flexGrow={1}
            color={"#858585"}
            fontWeight={600}
          >
            Người theo dõi
          </Typography>

          {canUpdateTrackers && (
            <Button
              sx={{ color: "#2E9CC9", marginLeft: 1 }}
              onClick={() => setOpenUpdateTrackers(true)}
            >
              <Typography flexGrow={1} variant="caption">
                Chỉnh sửa người theo dõi
              </Typography>
            </Button>
          )}
        </Box>

        <AvatarGroup
          sx={{
            alignSelf: "start",
            marginTop: 1,
            ".MuiAvatar-root": { width: 26, height: 26 }
          }}
          max={4}
          renderSurplus={surplus => (
            <Typography fontSize={16}>+{surplus}</Typography>
          )}
          total={task.taskCard.taskTrackers?.length || 0}
        >
          {task.taskCard.taskTrackers
            ?.slice(0, 4)
            .map(tracker => (
              <Avatar
                key={tracker.id}
                height={26}
                width={26}
                alt={tracker.fullName ?? "User avatar"}
                src={tracker.avatar}
              />
            ))}
        </AvatarGroup>
      </Card>
      <Progress task={task} stagesHistorys={stagesHistorys} />
      <EditTrackerModal
        open={openUpdateTrackers}
        onClose={() => setOpenUpdateTrackers(false)}
        onUpdateSuccess={onUpdateTrackersSuccess}
        task={task.taskCard}
      />
    </Wrapper>
  );
};

export default JobDetailProgress;
