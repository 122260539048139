import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const CreateWorkflowFormItemWrapper = styled(Box)(() => ({

    paddingTop: 16,
    "& .FormItemName": {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.3,
    },
    "& .FormItemDesc": {
        fontSize: 11.5,
        color: "#979797",
        lineHeight: .5

    },
    "& .MuiInputBase-root": {
        "& .MuiInputBase-input": {
            padding: "8px !important",

        },
        "& fieldset": {
            borderRadius: 4
        }
    }


}));