import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const Wrapper = styled("div")(() => ({
  margin: "24px",
  "& .header": {
    display: "flex",
    "& .account-roles": {
      backgroundColor: "#E2EFFD",
      color: "#3091F3",
      fontSize: "12px",
      padding: "0 4px",
      fontWeight: "600"
    }
  },
  "& .account-info": {
    width: "70%",
    margin: "32px auto",
    "& .account-identity": {
      display: "flex"
    },
    "& .account-infomation": {
      marginTop: "4%",
      "& .deparment-card": {
        margin: "12px 0",
        padding: "12px"
      }
    }
  }
}));
