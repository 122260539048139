import { useCallback, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/vi";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { toggleMessage } from "../../../../components/Toast/Toast";
import useAxios from "../../../../components/UseAxios/useAxios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ControlForm, Modal } from "../../../../components";
import ReactQuill from "react-quill";
import { ITimeOff } from "../../../../models/timeoff";
import InputForm from "../../../../components/controls/InputForm";
import BackspaceRoundedIcon from "@mui/icons-material/BackspaceRounded";
import { Wrapper } from "./styles";

dayjs.extend(utc);
dayjs.extend(timezone);
const today = dayjs();

interface TimeOffDate {
  date: dayjs.Dayjs | null;
  shift: number;
}

interface IProps {
  open: boolean;
  onCreateSuccess: (newTimeOff?: ITimeOff) => void;
  handleClose: () => void;
}

interface DataForm {
  title: string;
  type: string;
  description: string;
  startDate: TimeOffDate[];
}

const shiftOptions = [
  {
    name: "8:30 - 12:30",
    value: 1
  },
  {
    name: "13:30 - 17:30",
    value: 2
  },
  {
    name: "Cả ngày",
    value: 0
  }
];

const typeOptions = [
  {
    name: "Nghỉ phép năm dưới 3 ngày",
    value: 1
  },
  {
    name: "Nghỉ phép năm trên 3 ngày",
    value: 2
  },
  {
    name: "Nghỉ không lương",
    value: 0
  }
];

const CreateTimeOffModal = ({
  open,

  handleClose,
  onCreateSuccess
}: IProps) => {
  const createTimeOffAxios = useAxios<ITimeOff>({ loading: "OnRequest" });

  useEffect(() => {
    if (open === true) {
      reset({
        title: "",
        type: "",
        description: "",
        startDate: [{ date: null, shift: 0 }]
      });
      createTimeOffAxios.reset();
    }
  }, [open]);

  useEffect(() => {
    if (createTimeOffAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message:
          createTimeOffAxios.message ?? "Tạo yêu cầu nghỉ phép thành công"
      });
      onCreateSuccess();
      handleClose();
    } else if (createTimeOffAxios.error) {
      toggleMessage({
        type: "error",
        message: createTimeOffAxios.error?.message ?? ""
      });
    }
  }, [createTimeOffAxios.error, createTimeOffAxios.isSuccess]);

  const onSubmit = useCallback((data: DataForm) => {
    const request = {
      title: data.title,
      type: data.type,
      description: data.description,
      startDate: []
    };

    //   createTimeOffAxios.request(TimeOffService.createTimeOff(request));
  }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Vui lòng nhập tiêu đề"),
    type: Yup.string().required("Vui lòng chọn loại đề xuất"),

    startDate: Yup.mixed<dayjs.Dayjs[]>()
      .nullable()
      .required("Vui lòng chọn ngày bắt đầu")
      .test("startDate-test", "Thời gian không hợp lệ", (value, _) => {
        return true;
      })
  }) as any;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<DataForm>({ resolver: yupResolver(validationSchema) });

  return (
    <Modal
      title={"Tạo yêu cầu nghỉ phép"}
      textSubmit="Lưu lại"
      textClose="Bỏ qua"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={createTimeOffAxios.isLoading}
      disabledSubmit={createTimeOffAxios.isLoading}
      onClose={
        !createTimeOffAxios.isLoading
          ? () => {
              handleClose();
            }
          : undefined
      }
      width="580px"
    >
      <Wrapper
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px"
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ backgroundColor: "#ffeee2", padding: 1, borderRadius: 1 }}>
          <Typography variant="body1" color="black" fontWeight={400}>
            {"[Bắt buộc] Bạn không thể đề xuất những ngày trong quá khứ"}
          </Typography>
        </Box>
        <ControlForm title="Tiêu đề" classname="brand-form" isRequired>
          <InputForm
            placeholder="Tiêu đề"
            required
            name="title"
            errors={errors}
            control={control}
            inputProps={{
              style: {
                fontSize: 15,
                fontWeight: 500,
                color: "#596970",
                textTransform: "uppercase"
              }
            }}
            size="small"
          />
        </ControlForm>
        <ControlForm title="Loại đề xuất" classname="brand-form" isRequired>
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <Select
                  onChange={newValue => {
                    onChange(newValue);
                  }}
                  size="small"
                  displayEmpty
                  fullWidth
                  value={value}
                  IconComponent={ExpandLessIcon}
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "white",
                    borderRadius: "12px",
                    fontSize: 15,
                    fontWeight: 500,
                    color: "#596970"
                  }}
                >
                  {typeOptions.map((option, index) => (
                    <MenuItem
                      sx={{ fontSize: 13 }}
                      value={option.value}
                      key={index}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.type && (
                  <FormHelperText sx={{ color: "#ED3E47" }}>
                    {errors.type.message ?? ""}
                  </FormHelperText>
                )}
              </>
            )}
          ></Controller>
        </ControlForm>
        <ControlForm title="Mô tả đề xuất" classname="brand-form">
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Mô tả đề xuất"
                size="small"
                multiline
                fullWidth
                rows={4}
                inputProps={{
                  style: { fontSize: 15, fontWeight: 500, color: "#596970" }
                }}
                error={!!errors?.description}
                helperText={errors?.description?.message}
              />
            )}
          />
        </ControlForm>

        <ControlForm title="Ca đề xuất" classname="brand-form" isRequired>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="vi">
            <Controller
              name="startDate"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error }
              }) => (
                <Box display={"flex"} flexDirection={"column"}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.5,
                      padding: 1,
                      backgroundColor: "#e9e9e9",
                      borderRadius: "4px"
                    }}
                  >
                    {value.map((timeOffDate, index) => (
                      <div key={"timeOffDate" + index}>
                        <div style={{ display: "flex" }}>
                          <DatePicker
                            disablePast
                            minDate={
                              value[index - 1]?.date?.add(1, "day") ?? today
                            }
                            value={timeOffDate.date}
                            dayOfWeekFormatter={date => date.format("dd")}
                            onChange={newDate => {
                              const newValue = [...value];
                              newValue[index].date = newDate;
                              onChange(newValue);
                            }}
                            slotProps={{
                              textField: {
                                placeholder: "Nhấn để chọn ngày",
                                InputProps: {
                                  readOnly: true,
                                  sx: {
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                    borderRadius: "12px"
                                  }
                                }
                              }
                            }}
                          />
                          {index > 0 && (
                            <BackspaceRoundedIcon
                              color="action"
                              fontSize="small"
                              sx={{
                                width: "22px",
                                height: "22px",
                                cursor: "pointer",
                                color: "#b22020",
                                alignSelf: "center",
                                marginLeft: "6px"
                              }}
                              onClick={() =>
                                onChange(value.filter((_, i) => i !== index))
                              }
                            />
                          )}
                          {index === 0 && <Box width={"30px"}></Box>}
                          {timeOffDate.date && (
                            <Select
                              onChange={newShift => {
                                const newValue = [...value];
                                newValue[index].shift = newShift.target
                                  .value as number;
                                onChange(newValue);
                              }}
                              size="small"
                              displayEmpty
                              value={timeOffDate.shift}
                              IconComponent={ExpandLessIcon}
                              sx={{
                                fontSize: 15,
                                flexGrow: 1,
                                backgroundColor: "white",
                                borderRadius: "12px",
                                fontWeight: 500,
                                color: "#596970",
                                marginLeft: "6px"
                              }}
                            >
                              {shiftOptions.map((option, index) => (
                                <MenuItem
                                  sx={{ fontSize: 13 }}
                                  value={option.value}
                                  key={index}
                                >
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </div>
                        {error && (
                          <FormHelperText sx={{ color: "#ED3E47" }}>
                            {error.message}
                          </FormHelperText>
                        )}
                      </div>
                    ))}
                  </Box>
                  <Button
                    sx={{
                      color: "#A0A0A0",
                      alignSelf: "center",
                      marginTop: "12px"
                    }}
                    onClick={() =>
                      onChange([...value, { date: null, shift: 0 }])
                    }
                  >
                    +THÊM NGÀY NỐI TIẾP
                  </Button>
                </Box>
              )}
            />
          </LocalizationProvider>
        </ControlForm>
      </Wrapper>
    </Modal>
  );
};

export default CreateTimeOffModal;
