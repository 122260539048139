import {
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  styled,
  Box,
  Typography,
  Card,
} from "@mui/material";
import { Avatar } from "../../components";
import { Wrapper } from "./styles";
import variableStyles from "../../theme/variable-styles";
import { useEffect, useState } from "react";
import { IUser, IUserInfo } from "../../models/user";
import CircleIcon from "@mui/icons-material/Circle";
import { useApi } from "../../hooks/useApi.hook";
import UserService from "../../services/api/user.service";
import { authTokens } from "../../services/services";
import RolesTag from "../Member/components/RolesTag";
import { Roles, stringToRoles } from "../../models/common/models.enum";
import AccountLayout from "../Member/components/AccountLayout";
import { Helmet } from "react-helmet";

type Props = {};

const Tittle = styled("div")(({ theme }) => ({
  fontWeight: "bold",
  padding: "8px 0",
  color: "GrayText"
}));

const InformationContainer = styled("div")(({ theme }) => ({
  paddingTop: "64px"
}));

const AccountInfo = (props: Props) => {
  const [item, setItem] = useState<IUserInfo | null>(null);
  const [userInfo, setUserInfo] = useState<IUser | null>(null);

  const getInfo = useApi<IUserInfo>({});

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) getInfo.request(UserService.getSingle(userInfo.id));
  }, [userInfo]);

  useEffect(() => {
    if (getInfo.data) {
      setItem(getInfo.data);
    }
  }, [getInfo.data]);



  const EmptyBox = () => {
    return <Box
      sx={{
        margin: "12px 0",
        backgroundColor: variableStyles.grey200,
        width: "auto",
        height: "48px",
        textAlign: "center",
        alignContent: "center",
        borderRadius: "5px"
      }}
    >
      Không có thông tin
    </Box>;
  }

  return (
    <Wrapper>
      <Helmet>
        <title> Quản lý tài khoản | Cá nhân</title>
      </Helmet>
      <div className="header">
        <Box flexGrow="1">
          <Typography variant="overline" color="GrayText">
            TÀI KHOẢN
          </Typography>
          <Box marginTop="4px" marginBottom="12px">
            <RolesTag roles={stringToRoles(item?.roles!)} />
          </Box>
          {/* <p className="account-name">
            {state.fullName} . {state.position}
          </p> */}
        </Box>
        {/* <Button variant="contained" size="small">
          Chỉnh sửa tài khoản
        </Button> */}
      </div>

      <Divider />

      <div className="account-info">
        <div className="account-identity">
          <Avatar
            src={item?.avatar}
            alt="Avatar-Image"
            width={"80px"}
            height={"80px"}
          />
          <div style={{ marginLeft: "18px" }}>
            <p style={{ fontSize: "20px" }}>{item?.fullName}</p>
            <Box display="flex" color="GrayText" marginTop="4px">
              <Typography variant="body1">{item?.userName}</Typography>
              <CircleIcon sx={{ width: "4px", margin: "0 8px" }} />
              <Typography variant="body1">{item?.position}</Typography>
            </Box>

            <Table size="small" sx={{ marginTop: "12px" }}>
              <TableBody>
                <TableRow sx={{
                  "&:last-child th, &:last-child td": {
                    borderBottom: 0,
                  },
                }}>
                  <TableCell padding="none" sx={{ color: "GrayText", minWidth: "60px" }}>
                    Email
                  </TableCell>
                  <TableCell padding="none">{item?.email}</TableCell>
                </TableRow>

                {userInfo?.roles !== Roles.SuperAdmin && <TableRow>
                  <TableCell padding="none" sx={{ color: "GrayText" }}>
                    Quản lý trực tiếp &nbsp;&nbsp;&nbsp;
                  </TableCell>
                  <TableCell padding="none">
                    {item?.manager?.fullName}

                  </TableCell>
                </TableRow>}
              </TableBody>
            </Table>
          </div>
        </div>

        <div className="account-infomation">
          <Tittle>THÔNG TIN LIÊN HỆ</Tittle>
          <Divider />
          <Box display="flex" padding="14px 0">
            <Typography variant="body2" color="GrayText" width="20%">
              Số điện thoại
            </Typography>
            <Typography variant="body2" textAlign="center" flexGrow="1">
              {item?.phoneNumber?.length
                ? item?.phoneNumber
                : "Không có thông tin"}
            </Typography>
          </Box>
          <Divider />
          <Box display="flex" padding="14px 0">
            <Typography variant="body2" color="GrayText" width="20%">
              Địa chỉ
            </Typography>
            <Typography variant="body2" textAlign="center" flexGrow="1">
              {item?.address ?? "Không có thông tin"}
            </Typography>
          </Box>
          <Divider />
          <InformationContainer>
            <Tittle>NHÓM ({item?.departments.length})</Tittle>
            <Divider />
            {item?.departments.length ? <Box>
              {item?.departments.map((department) =>
                <Card key={department.id} className="deparment-card">
                  <Typography variant="subtitle2">{department.name}</Typography>
                  <Typography variant="caption">{department.userName}</Typography>
                </Card>
              )}
            </Box> : EmptyBox()}
          </InformationContainer>

          <InformationContainer>
            <Tittle>NHÂN VIÊN TRỰC TIẾP ({item?.staff.length})</Tittle>
            <Divider />
            {item?.staff.length ? <Box>
              {item?.staff.map((user) => <Box key={user.id}>
                <Card className="deparment-card">
                  <AccountLayout name={user.fullName} tag={user.userName} position={user?.position} avatar={user?.avatar} />
                </Card>
              </Box>)}
            </Box> : EmptyBox()}
          </InformationContainer>
          {/* <InformationContainer>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Tittle>HỌC VẤN</Tittle>
              <Button>
                <AddCircleOutlineIcon
                  style={{ color: variableStyles.grey400 }}
                />
              </Button>
            </div>
            <Divider />
          </InformationContainer> */}

        </div>
      </div>
    </Wrapper>
  );
};

export default AccountInfo;
