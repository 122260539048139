import { styled } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  // margin: "12px 20px",
  "& .header-page": {
    color: "black",
    flexGrow: 1,
    display: "flex",
    button: {
      marginRight: "24px",
      color: "GrayText"
    }
  },
  "& .admin-action": {
    button: { marginLeft: "12px", borderRadius: "4px" }
  },

  "& .admin-container": {
    margin: "14px 20px",
    marginTop: "12px",
    display: "flex",
    justifyContent: "space-between",
    button: {
      marginLeft: "28px",
      borderRadius: "4px",
      padding: "8px 0"
    }
  },
  "& .main-container": {
    marginTop: "12px",
    margin: "12px 20px"
  }
}));
