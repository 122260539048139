import Typography from "@mui/material/Typography";
import { Avatar } from "../../../../../components";
import { MemberCardWrapper } from "./styles";
import theme from "../../../../../theme";
import Box from "@mui/material/Box";
import { IManagement } from "../../../../../models/workflow";

const MemberCard = (item: IManagement) => {
  return (
    <MemberCardWrapper className="MemberCard">
      <Avatar src={item?.avatar} width={56} height={56} />
      <Box sx={{ fontSize: 15, fontWeight: 300, paddingTop: 1 }}>
        {item.fullName}
      </Box>
      <Box sx={{ fontSize: 12, fontWeight: 600 }}>{item.userName}</Box>
      <Box sx={{ fontSize: 13, color: "#8e8e8e" }}>{item.position}</Box>
      <Box sx={{ fontSize: 13, color: "#8e8e8e" }}>{item.nameWorkflow}</Box>
    </MemberCardWrapper>
  );
};
export default MemberCard;
