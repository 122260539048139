import { useEffect, useState } from "react";
import { Box } from "@mui/material";

interface ImageProps {
  height?: number | string;
  width?: number | string;
  borderRadius?: string;
  objectFit?: any;
  objectPosition?: string;
  className?: string;
  src?: string | null;
  isAvatar?: boolean;
  alt?: string;
  onClick?: () => void;
  notlazy?: boolean;
}
function ImageCustom(props: ImageProps) {
  const {
    height = "",
    width = "",
    borderRadius = "",
    objectFit,
    objectPosition = "",
    className = "",
    src,
    isAvatar,
    alt = "",
    onClick,
    notlazy = false
  } = props;

  const [isConnected, setIsConnected] = useState<boolean>(true);

  const imageEmpty = "";

  useEffect(() => {
    const handleOnline = () => {
      setIsConnected(true);
    };
    const handleOffline = () => {
      setIsConnected(false);
    };

    if (typeof window !== "undefined") {
      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);
    }

    return () => {
      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <Box
      component="img"
      className={className}
      src={src ? src : imageEmpty}
      loading={notlazy ? "eager" : "lazy"}
      onError={(e: any) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.src = isConnected ? imageEmpty : "";
      }}
      alt={alt}
      onClick={onClick}
      sx={{
        borderRadius: borderRadius,
        height: height,
        width: width,
        display: "block",
        objectFit: objectFit ? objectFit : "cover",
        objectPosition: objectPosition ? objectPosition : "center"
      }}
    />
  );
}

export default ImageCustom;
