export enum Roles {
  Member = "Member",
  Admin = "Admin",
  SuperAdmin = "SuperAdmin"
}

export const rolesColor = (role: Roles): { color: string; bgcolor: string } => {
  switch (role) {
    case Roles.Admin:
      return { color: "#4AB58A", bgcolor: "#EDF8F4" };
    case Roles.Member:
      return { color: "#3091F3", bgcolor: "#E4EFFC" };
    case Roles.SuperAdmin:
      return { color: "#ED3E47", bgcolor: "#FDECED" };
    default:
      return { color: "#000000", bgcolor: "000000" };
  }
};

export const rolesTitle = (role: Roles): string => {
  switch (role) {
    case Roles.Admin:
      return "Quản lý";
    case Roles.Member:
      return "Nhân viên";
    case Roles.SuperAdmin:
      return "Quản trị cấp cao";
    default:
      return "Không xác định";
  }
};

export const stringToRoles = (roles: string): Roles => {
  switch (roles) {
    case "Admin":
      return Roles.Admin;
    case "SuperAdmin":
      return Roles.SuperAdmin;
    case "Member":
      return Roles.Member;
    default:
      return Roles.Member;
  }
};

export enum ObjectStatus {
  Active = "active",
  DeActive = "deactive"
}
export enum OrderDirection {
  DESC = "DESC",
  ASC = "ASC"
}
export enum EImageType {
  png = ".png",
  jpeg = ".jpeg",
  jpg = ".jpg"
}

export enum ECurrencyCode {
  VND = "VND",
  USD = "USD",
  EUR = "EUR"
}

export enum ELanguage {
  Vie = "vi-VN",
  Eng = "en-US"
}

export enum EFlow {
  Inflow = 0,
  Outflow = 1
}

export enum EModeModal {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  NOTHING = "NOTHING"
}

export enum ExchangeRateType {
  USDtoVND = "USD/VND",
  EURtoVND = "EUR/VND",
  EURtoUSD = "EUR/USD"
}

export enum TypeLink {
  internal = "internal",
  external = "external"
}

export enum UserAction {
  EditInformation = "Chỉnh sửa thông tin cá nhân",
  ChangePassword = "Thay đổi mật khẩu",
  ChangeManage = "Chỉnh sửa người quản lý trực tiếp",
  ChangeAdministration = "Chỉnh sửa quyền quản trị"
}

export enum TagType {
  User = 0,
  Department = 1
}

export enum EStageType {
  Step = 0,
  Cancel = 1,
  Complete = 2
}

export enum ETaskStatus {
  Inprogress = 0,
  Cancel = 1,
  Complete = 2,
  Failed = 3
}

export enum EWorkflowProcess {
  Continue = 0,
  First = 1,
  Other = 2,
  TaskAssigneeDecides = 3,
  SelectStep = 4
}

export enum EFireBaseType {
  AssignTask = 0,
  UpdateTask = 1,
  UpdateBoard = 2,
}

export enum ETimeOffStatus {
  Approved = 0,
  PendingApproval = 1,
  Rejected = 2,
  Canceled = 3,
}


export const taskCardStatusColor = (
  status: ETaskStatus
): { color: string; bgcolor: string } => {
  switch (status) {
    case ETaskStatus.Inprogress:
      return { color: "#3091F3", bgcolor: "#EAF4FE" };
    case ETaskStatus.Cancel:
      return { color: "#F2A533", bgcolor: "#FEF6EB" };
    case ETaskStatus.Complete:
      return { color: "#54BD95", bgcolor: "#EEF8F4" };
    case ETaskStatus.Failed:
      return { color: "#ED3E47", bgcolor: "#FDECED" };
    default:
      return { color: "#000000", bgcolor: "000000" };
  }
};

export const taskStatusTitle = (status: ETaskStatus): string => {
  switch (status) {
    case ETaskStatus.Inprogress:
      return "Đang thực hiện";
    case ETaskStatus.Cancel:
      return "Huỷ";
    case ETaskStatus.Complete:
      return "Hoàn thành";
    case ETaskStatus.Failed:
      return "Lỗi";
    default:
      return "Không xác định";
  }
};

export enum EFileType {
  jpg = ".jpg",
  jpeg = ".jpeg",
  png = ".png",
  gif = ".gif",
  mp4 = ".mp4",
  mov = ".mov",
  avi = ".avi",
  flv = ".flv",
  docx = ".docx",
  doc = ".doc",
  xlxs = ".xlxs",
  cvs = ".cvs",
  pdf = ".pdf",
  pptx = ".pptx",
  ppt = ".ppt"
}

export enum EFireBaseTypeEnum {
  AssignTask = 0,
  UpdateTask = 1,
  UpdateBoard = 2
}
