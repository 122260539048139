import { isNull } from "lodash";
import { envConfig } from "../../config/env.config";
import ImageCustom from "../ImageCustom";
import { AvatarWrapper } from "./styles";
import AvatarImage from "../../assets/images/common/avatar.png";

export interface AvatarProps {
  src?: string | null;
  alt?: string;
  width?: string | number;
  height?: string | number;
  className?: string;
  borderColor?: string;
  borderWidth?: number;
  isUrl?: boolean;
}

export default function Avatar(props: AvatarProps) {
  const {
    src,
    alt,
    width = 36,
    height = 36,
    className,
    borderColor,
    borderWidth,
    isUrl = true
  } = props;

  const imgUrl = `${(isUrl && src) ? envConfig.MEDIA_ENDPOINT : ""}${src ?? AvatarImage}`;

  return (
    <AvatarWrapper
      borderWidth={borderWidth}
      borderColor={borderColor}
      className={className}
      width={width}
      height={height}
    >
      <ImageCustom isAvatar src={imgUrl} alt={alt} width={width} height={height} />
    </AvatarWrapper>
  );
}
