import { Box, Typography } from "@mui/material";
import DvrIcon from "@mui/icons-material/Dvr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Roles } from "../../models/common/models.enum";
import { ITabItem } from "../WorkflowManagement/workflow_manage";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { useEffect, useMemo, useRef, useState } from "react";
import WorkflowService from "../../services/api/workflow.service";
import { toggleMessage } from "../../components/Toast/Toast";
import BoardProject from "./BoardProject/board_project";
import Manage from "./Task";
import ProjectMagagerial from "./Managerial";
import StageService from "../../services/api/stage.service";
import {
  IRoleBoard,
  IStage,
  IStageByWorkflowResponse,
  IUpdateStagePositionRequest
} from "../../models/Stage";
import {
  ITaskCard,
  ITaskCardUpdatePositionRequest,
  ITaskUpdatePositionResponse
} from "../../models/Task";
import TaskService from "../../services/api/task.service";
import useAxios from "../../components/UseAxios/useAxios";
import { Helmet } from "react-helmet";
import { ManageProjectWrapper } from "./styles";
import useNavigateSearch from "../../components/NavigateSearch";
import { Modal } from "../../components";

export const tabItems = (role?: Roles): ITabItem[] => {
  const BOARD: ITabItem = {
    href: ROUTE_PATH.PROJECTS,
    title: "Dạng bảng",
    code: "Dạng bảng"
  };
  const SNARE: ITabItem = {
    href: ROUTE_PATH.MANAGER,
    title: "Dạng lưới",
    code: "Dạng lưới"
  };
  const MORE: ITabItem = {
    href: ROUTE_PATH.PROJECTS_MANAGERIAL,
    title: "Xem thêm",
    code: "Xem thêm"
  };

  return [BOARD, SNARE, MORE];
};

const ManageProject = () => {
  const tabItemsData = tabItems();
  const nodeRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [openShutWorkflowDialog, setOpenShutWorkflowDialog] = useState(false);
  const [searchParams] = useSearchParams();
  const reloadChannel = new BroadcastChannel("reload-board");

  const navigateSearch = useNavigateSearch();

  const workflowAxios = useAxios<IStageByWorkflowResponse>({
    loading: "OnRequest"
  });
  const shutWorkflowAxios = useAxios<string>({ loading: "OnRequest" });
  const updateStagePositionAxios = useAxios<IStageByWorkflowResponse>({
    loading: "onCreate"
  });
  const updateTaskPositionAxios = useAxios<ITaskUpdatePositionResponse>({
    loading: "onCreate"
  });
  const [workflow, setWorkflow] = useState<IStageByWorkflowResponse | null>(
    null
  );
  const [stages, setStages] = useState<IStage[]>([]);
  const [tasks, setTasks] = useState<ITaskCard[]>([]);

  const [stagesUpdate, setStagesUpdate] = useState(true);

  const roleBoardByUser: IRoleBoard | undefined = useMemo(() => {
    if (workflow) {
      return workflow.roleBoardByUser;
    }
  }, [workflow]);

  useEffect(() => {
    reloadChannel.addEventListener("message", event => setStagesUpdate(true));
    return () => {
      reloadChannel.close();
    };
  }, []);

  useEffect(() => {
    if (workflowAxios.error)
      toggleMessage({
        type: "error",
        message: workflowAxios.error.message ?? ""
      });
  }, [workflowAxios.error]);

  useEffect(() => {
    if (workflowAxios.data) {
      setWorkflow(workflowAxios.data);
      setStages(workflowAxios.data.workflows);

      const newTasks = workflowAxios.data.workflows
        .map(e => e.taskCards)
        .reduce((prev, next) => prev.concat(next))
        .sort((a, b) => a.position - b.position);

      setTasks(newTasks);
    }
  }, [workflowAxios.data]);

  useEffect(() => {
    if (updateTaskPositionAxios.isSuccess && updateTaskPositionAxios.data) {
      let newStages = [...stages];
      let oldIndex = stages.findIndex(
        e => e.id === updateTaskPositionAxios.data!.oldWorkflow.id
      );
      newStages[oldIndex].taskCards =
        updateTaskPositionAxios.data.oldWorkflow.taskCards;

      let newIndex = stages.findIndex(
        e => e.id === updateTaskPositionAxios.data!.newWorkflow.id
      );
      newStages[newIndex].taskCards =
        updateTaskPositionAxios.data.newWorkflow.taskCards;

      setStages(newStages);

      const newTasks = newStages
        .map(e => e.taskCards)
        .reduce((prev, next) => prev.concat(next))
        .sort((a, b) => a.position - b.position);

      setTasks(newTasks);
      toggleMessage({
        type: "success",
        message: updateTaskPositionAxios.message ?? "Cập nhật thành công"
      });
    }
    if (updateTaskPositionAxios.error) {
      setStagesUpdate(true);
      toggleMessage({
        type: "error",
        message: updateTaskPositionAxios.error.message ?? ""
      });
    }
  }, [updateTaskPositionAxios.isSuccess, updateTaskPositionAxios.error]);

  useEffect(() => {
    if (updateStagePositionAxios.isSuccess) {
      setStagesUpdate(true);
      toggleMessage({
        type: "error",
        message: updateStagePositionAxios.message ?? "Cập nhật thành công"
      });
    }
    if (updateStagePositionAxios.error) {
      setStagesUpdate(true);
      toggleMessage({
        type: "error",
        message: updateStagePositionAxios.error.message ?? ""
      });
    }
  }, [updateStagePositionAxios.error]);

  useEffect(() => {
    if (shutWorkflowAxios.isSuccess)
      navigateSearch(ROUTE_PATH.WORKFLOWS, {
        tab: searchParams.get("tab") ?? "availability"
      });
    if (shutWorkflowAxios.error)
      toggleMessage({
        type: "error",
        message: shutWorkflowAxios.error.message ?? ""
      });
  }, [shutWorkflowAxios.isSuccess, shutWorkflowAxios.error]);

  const getWorkflow = async () => {
    if (searchParams.get("id")) {
      workflowAxios.request(
        StageService.getStage(searchParams.get("id") as string)
      );
    }
    setStagesUpdate(false);
  };

  const handleConfirmShutWorkflow = () => {
    shutWorkflowAxios.request(
      WorkflowService.closeWorkflow(workflow!.board.id)
    );
  };
  const handleCloseShutWorkflowDialog = () => {
    setOpenShutWorkflowDialog(false);
  };

  const handleUpdateStagePosition = async (
    current: IStage,
    newPosition: IStage
  ) => {
    const request: IUpdateStagePositionRequest = {
      BoardId: workflow!.board.id,
      WorkflowMoveId: current.id,
      NewPosition: newPosition.position
    };
    updateStagePositionAxios.request(StageService.updateStagePosition(request));
  };

  const handleUpdateTaskPosition = async (
    task: ITaskCard,
    stage: IStage,
    index: number
  ) => {
    const request: ITaskCardUpdatePositionRequest = {
      WorkflowId: stage.id,
      TaskCardMoveId: task.id,
      NewPosition: index
    };

    updateTaskPositionAxios.request(TaskService.updateTaskPosition(request));
  };

  useEffect(() => {
    if (stagesUpdate) getWorkflow();
  }, [stagesUpdate]);

  return (
    <ManageProjectWrapper
      active={workflowAxios.isLoading}
      spinner
      ref={nodeRef}
    >
      {workflow && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{workflow.board.name}</title>
        </Helmet>
      )}
      {workflow && (
        <div className="board-project-header flex">
          <div className="board-project-icon">
            <DvrIcon sx={{ color: "#AAAAAA", width: 18, height: 18 }} />
          </div>
          <Box className="board-project-header-info" sx={{ flexGrow: 1 }}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              className="board-project-title"
              sx={{ flexGrow: 1 }}
            >
              {workflow && (
                <Typography
                  variant="overline"
                  fontSize={"22px"}
                  fontWeight={600}
                  lineHeight={1}
                >
                  {workflow.board.name}
                </Typography>
              )}
              <Box
                display={"flex"}
                flexDirection={"row"}
                sx={{ height: 40 }}
              ></Box>
            </Box>
            <Box className="board-project-tabs" sx={{ flexGrow: 1 }}>
              <ul className="tab-list">
                {workflow &&
                  tabItemsData.map((tabItem, i) => (
                    <li
                      key={tabItem.code}
                      onClick={() => setTabIndex(i)}
                      className={tabIndex === i ? "active" : ""}
                    >
                      {tabItem.title}
                    </li>
                  ))}
              </ul>
            </Box>
          </Box>
        </div>
      )}
      {workflow && roleBoardByUser && tabIndex === 0 && (
        <BoardProject
          workflow={workflow.board}
          role={roleBoardByUser}
          stages={stages}
          tasks={tasks}
          onCloseWorkflow={id => setOpenShutWorkflowDialog(true)}
          onReload={() => setStagesUpdate(true)}
          onUpdateStagePosition={handleUpdateStagePosition}
          onUpdateTaskPosition={handleUpdateTaskPosition}
          onStagesChanged={newStages => setStages(newStages)}
          onTasksChanged={newTasks => setTasks(newTasks)}
        />
      )}
      {workflow && roleBoardByUser && tabIndex === 1 && (
        <Manage workflow={workflow} role={roleBoardByUser} />
      )}
      {tabIndex === 2 && <ProjectMagagerial id={workflow?.board.id!} />}
      {workflow && (
        <Modal
          title={"Xác nhận đóng luồng công việc"}
          textSubmit={"Xác nhận"}
          textClose="Huỷ bỏ"
          open={openShutWorkflowDialog}
          onSubmit={handleConfirmShutWorkflow}
          disabledSubmit={shutWorkflowAxios.isLoading}
          onClose={handleCloseShutWorkflowDialog}
        >
          {"Bạn có chắc chắn muốn đóng luồng công việc này?"}
        </Modal>
      )}
    </ManageProjectWrapper>
  );
};

export default ManageProject;
