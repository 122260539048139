import { Box, Button, Divider, Typography } from "@mui/material";
import ShortcutRoundedIcon from "@mui/icons-material/ShortcutRounded";
import { IAttachmentFile } from "../../../../../models/Task";
import { useApi } from "../../../../../hooks/useApi.hook";
import CommentService from "../../../../../services/api/comment.service";
import { useEffect, useRef, useState } from "react";

import { IUser, IUserInfo } from "../../../../../models/user";
import { Avatar } from "../../../../../components";
import UploadFileService from "../../../../../services/api/uploadFile.service";
import { toggleMessage } from "../../../../../components/Toast/Toast";
import CommentInput from "./components/CommentInput";
import CommentBox from "./components/CommentBox";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { authTokens } from "../../../../../services/services";
import UserService from "../../../../../services/api/user.service";
import { IComment } from "../../../../../models/Comment";

type CommentContainerProps = {
  comment: IComment;
  taskId: string;
  refetch: Function;
};

const CommentContainer = (props: CommentContainerProps) => {
  const { comment, taskId, refetch } = props;
  const [files, setfiles] = useState<File[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [isReply, setIsReply] = useState(false);
  const [page, setPage] = useState<number | null>(null);
  const [data, setData] = useState<IComment[]>([]);
  const inputRef = useRef<any>(null);
  const createReply = useApi<IComment>({});
  const uploadFileReply = useApi<IAttachmentFile[]>({});
  const getCommentChild = useApi<IComment[]>({ isFetch: true, isPaging: true });
  useEffect(() => {
    setData([...data, ...(getCommentChild.data ?? [])]);
  }, [getCommentChild.data]);

  function handleGetReply() {
    getCommentChild.request(
      CommentService.getChildren({
        CommentParentId: comment.id,
        CurrentPage: page ?? 1,
        PageSize: 5
      })
    );
  }

  useEffect(() => {
    if (page) handleGetReply();
  }, [page]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setfiles([...files, e.target.files[0]]);
    }
  };

  const handleDeleteFile = (file: File) => {
    const newFiles = files.filter(f => f !== file);
    setfiles(newFiles);
  };

  function createComment(attachmentFiles?: IAttachmentFile[]) {
    createReply.request(
      CommentService.create({
        Description: inputValue,
        TaskId: taskId,
        ParentCommentId: comment.id,
        AttachmentFiles: attachmentFiles
      }).then(res => {
        if (res.success) {
          toggleMessage({
            type: "success",
            message: "Cập nhật thành công"
          });
          setInputValue("");
          refetch();
        } else {
          if (attachmentFiles) {
            uploadFileReply.request(
              UploadFileService.deleteFiles(attachmentFiles)
            );
          }
        }
      })
    );
  }

  const handleSubmit = () => {
    const filesData = new FormData();
    filesData.append("FolderName", "Comment");
    files
      .filter(e => e.size > 0)
      .forEach(file => filesData.append("Files", file));
    if (files.length) {
      uploadFileReply.request(
        UploadFileService.uploadFile(filesData).then(response => {
          if (response.success && response.data) {
            createComment(response.data);
          } else {
            toggleMessage({
              type: "error",
              message: "Upload file xảy ra lỗi, vui lòng thử lại"
            });
          }
        })
      );
    } else {
      createComment();
    }
  };

  return (
    <Box marginBottom="8px" sx={{ position: "relative" }}>
      <Button
        sx={{ position: "absolute", top: "8px", right: 0 }}
        onClick={() => setIsReply(!isReply)}
      >
        <ShortcutRoundedIcon sx={{ fontSize: 16, color: "dodgerblue" }} />
        <Typography variant="caption" color="dodgerblue" marginLeft="4px">
          Trả lời
        </Typography>
      </Button>
      <CommentBox
        name={comment.users.fullName}
        time={comment.createdDate}
        content={comment.description}
        avatar={comment.users.avatar}
        attachmentFiles={comment.attachmentFiles}
      />
      {comment?.totalCountCommentChildren && !getCommentChild.data && (
        <Button sx={{ marginLeft: "42px" }} onClick={handleGetReply}>
          <Typography variant="caption" color="GrayText">
            Xem thêm {comment?.totalCountCommentChildren} trả lời
          </Typography>
        </Button>
      )}
      {data && (
        <Box>
          <Box display="flex" margin="12px 0">
            <Divider
              orientation="vertical"
              flexItem
              sx={{ margin: "0 16px 0 50px ", borderStyle: "dashed" }}
            />
            <Box>
              {data.map((cmt, index) => (
                <CommentBox
                  key={cmt.id}
                  margin={`0 0 ${index === comment?.totalCountCommentChildren! - 1 ? 0 : 18}px 0`}
                  name={cmt.users.fullName}
                  time={cmt.createdDate}
                  content={cmt.description}
                  avatar={cmt.users.avatar}
                  attachmentFiles={cmt.attachmentFiles}
                />
              ))}
            </Box>
          </Box>
          {getCommentChild.pagging &&
            data.length !== getCommentChild.pagging?.totalCount && (
              <Box marginLeft="48px">
                <Button onClick={() => setPage(page ?? 1 + 1)}>
                  <Typography variant="subtitle2">
                    Xem thêm bình luận{" "}
                    <ExpandMoreRoundedIcon sx={{ fontSize: 16 }} />
                  </Typography>
                </Button>
              </Box>
            )}
        </Box>
      )}
      {isReply && (
        <CommentInput
          isReply
          files={files}
          value={inputValue}
          inputRef={inputRef}
          onSubmit={handleSubmit}
          onDelete={handleDeleteFile}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.value) setInputValue(event.target.value);
          }}
          isDisableAction={inputValue.length === 0}
          handleFileChange={handleFileChange}
        />
      )}
    </Box>
  );
};

type Props = {
  taskId: string;
};

const CommentTask = (props: Props) => {
  const [files, setfiles] = useState<File[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<IComment[]>([]);
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const inputRef = useRef<any>(null);

  const getPage = useApi<IComment[]>({ isFetch: true, isPaging: true });
  const create = useApi<IComment>({});
  const uploadFile = useApi<IAttachmentFile[]>({});
  const getInfo = useApi<IUserInfo>({});

  useEffect(() => {
    setData([...data, ...(getPage.data ?? [])]);
  }, [getPage.data]);

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) getInfo.request(UserService.getSingle(userInfo.id));
  }, [userInfo]);

  function fetchData() {
    getPage.request(
      CommentService.getPage({
        TaskCardId: props.taskId,
        CurrentPage: currentPage,
        PageSize: 5
      })
    );
  }

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const list = Array.from(e.target?.files);
      setfiles(files.concat(list));
    }
  };

  const handleDeleteFile = (file: File) => {
    const newFiles = files.filter(f => f !== file);
    setfiles(newFiles);
  };

  function createComment(attachmentFiles?: IAttachmentFile[]) {
    create.request(
      CommentService.create({
        Description: inputValue,
        TaskId: props.taskId,
        ParentCommentId: null,
        AttachmentFiles: attachmentFiles
      }).then(res => {
        if (res.success) {
          toggleMessage({
            type: "success",
            message: "Cập nhật thành công"
          });
          handleReset();
        } else {
          if (attachmentFiles) {
            uploadFile.request(UploadFileService.deleteFiles(attachmentFiles));
          }
        }
      })
    );
  }
  function handleReset() {
    setInputValue("");
    setData([]);
    fetchData();
    setfiles([]);
    inputRef.current.value = null;
  }

  const handleSubmit = () => {
    const filesData = new FormData();
    filesData.append("FolderName", "Comment");
    files
      .filter(e => e.size > 0)
      .forEach(file => filesData.append("Files", file));
    if (files.length) {
      uploadFile.request(
        UploadFileService.uploadFile(filesData).then(response => {
          if (response.success && response.data) {
            createComment(response.data);
          } else {
            toggleMessage({
              type: "error",
              message: "Upload file xảy ra lỗi, vui lòng thử lại"
            });
          }
        })
      );
    } else {
      createComment();
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Avatar
          src={userInfo?.avatar}
          alt="Avatar-Image"
          width={30}
          height={30}
        />

        <Box
          sx={{
            marginLeft: "12px",
            height: "30px",
            justifyContent: "flex-start",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight={500}
            lineHeight={1.3}
            fontSize={12}
          >
            {userInfo?.fullName}
          </Typography>

          <Typography variant="caption" fontSize={11} color={"#858585"}>
            {getInfo.data?.userName} - {getInfo.data?.position}
          </Typography>
        </Box>
      </Box>
      <CommentInput
        isDisableAction={inputValue.length === 0}
        files={files}
        inputRef={inputRef}
        onSubmit={handleSubmit}
        onDelete={handleDeleteFile}
        value={inputValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.value) setInputValue(event.target.value);
        }}
        handleFileChange={handleFileChange}
      />

      {data &&
        data.map(comment => (
          <CommentContainer
            key={comment.id}
            comment={comment}
            taskId={props.taskId}
            refetch={handleReset}
          />
        ))}
      {data.length !== getPage.pagging?.totalCount && (
        <>
          <Button onClick={() => setCurrentPage(currentPage + 1)}>
            <Typography variant="subtitle2">
              Xem thêm bình luận <ExpandMoreRoundedIcon sx={{ fontSize: 16 }} />
            </Typography>
          </Button>
        </>
      )}
    </>
  );
};

export default CommentTask;
