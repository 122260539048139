import { combineReducers } from "redux";
import departmentReducer from "./department/department.duck";
import userReducer from "./user/user.duck";
import workflowReducer from "./workflow/workflow.duck";

const reducers = combineReducers({
  users: userReducer,
  typeBoards: workflowReducer,
  department: departmentReducer
});

export default reducers;
