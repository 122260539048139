import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  Typography
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import UndoIcon from "@mui/icons-material/Undo";
import { ROUTE_PATH } from "../../../../../common/constants/app.constant";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { ITaskCard } from "../../../../../models/Task";
import { Avatar, Modal } from "../../../../../components";
import useNavigateSearch from "../../../../../components/NavigateSearch";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import BasicMenu from "../../../../../components/Menu/BasicMenu";
import useAxios from "../../../../../components/UseAxios/useAxios";
import TaskService from "../../../../../services/api/task.service";
import { toggleMessage } from "../../../../../components/Toast/Toast";
import CreateTaskModal from "../../../CreateTaskModal/create_task_modal";
import dayjs from "dayjs";
import { envConfig } from "../../../../../config/env.config";
import { ETaskStatus } from "../../../../../models/common/models.enum";
import { t } from "i18next";
import { IRoleWorkflow } from "../../../../../models/workflow";
import { IRoleBoard } from "../../../../../models/Stage";
import { TaskBoxWrapper } from "./styles";
import AssignTaskModal from "../../../assignTaskModal/assign_task_modal";

interface TaskBoxProps {
  disabled: boolean;
  canReassignTask?: boolean;

  onUpdateSuccess?: (newTask: ITaskCard) => void;
  onDeleteSuccess?: (newTask: ITaskCard) => void;
  onRollBackSuccess?: (newTask: ITaskCard) => void;
  task: ITaskCard;
  roleWorkflow?: IRoleBoard | null;
  roleStage?: IRoleWorkflow | null;
}

const MenuLabel = ({
  isGravity,
  onClick,
  name,
  icon
}: {
  isGravity?: boolean;
  icon: ReactNode;
  name: string;
  onClick: () => void;
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "11px 12px",
        display: "flex",
        color: "#585656c9"
      }}
      onClick={onClick}
    >
      {icon}
      <Typography
        variant="caption"
        fontWeight={500}
        fontSize={13}
        color={isGravity ? "#aa0000" : "585656c9"}
      >
        {name}
      </Typography>
    </Box>
  );
};

const TaskBox = (props: TaskBoxProps) => {
  const {
    task,
    canReassignTask,
    onUpdateSuccess,
    onDeleteSuccess,
    onRollBackSuccess,
    disabled,
    roleStage,
    roleWorkflow
  } = props;
  const navigateSearch = useNavigateSearch();
  const [disabledDnd, setDisabledDnd] = useState(false);

  const deleteTask = useAxios<string>({ loading: "OnRequest" });

  const rollbackTask = useAxios<string>({ loading: "OnRequest" });

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    active
  } = useSortable({
    id: task.id,
    disabled: disabledDnd,
    data: { type: "Task", task }
  });

  const [openUpdateAssign, setOpenUpdateAssign] = useState(false);
  const [openUpdateTask, setOpenUpdateTask] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const canAssignTask = useMemo(() => {
    return (
      roleStage?.isStageManagement ||
      roleWorkflow?.isProcessManagement ||
      roleWorkflow?.isCreator ||
      roleWorkflow?.isAssignTask ||
      canReassignTask
    );
  }, [roleStage, roleWorkflow]);

  const canManageTask = useMemo(() => {
    return (
      roleStage?.isStageManagement ||
      roleWorkflow?.isProcessManagement ||
      roleWorkflow?.isCreator ||
      roleWorkflow?.isAssignTask
    );
  }, [roleStage, roleWorkflow]);

  useEffect(() => {
    setDisabledDnd(disabled);
  }, [disabled]);

  const handleShowDetail = useCallback(() => {
    navigateSearch(ROUTE_PATH.TASK, { id: task.id as string });
  }, []);

  useEffect(() => {
    if (deleteTask.isSuccess === true) {
      onDeleteSuccess?.(task);
    }
    if (deleteTask.error) {
      toggleMessage({
        type: "error",
        message: deleteTask.error.message ?? ""
      });
    }
  }, [deleteTask.error, deleteTask.isSuccess]);

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true); // Open the delete confirmation dialog
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false); // Close the delete confirmation dialog
  };

  const handleConfirmDelete = () => {
    handleDelete(); // Call the delete function
    handleCloseDeleteDialog(); // Close the dialog after deletion
  };

  const handleDelete = () => {
    deleteTask.request(TaskService.deleteTask(task.id));
  };

  const handleRollBack = () => {
    rollbackTask.request(TaskService.rollBack(task.id));
  };

  useEffect(() => {
    if (rollbackTask.isSuccess && rollbackTask.data) {
      onRollBackSuccess?.(task);
      toggleMessage({
        type: "success",
        message: rollbackTask.message ?? "Cập nhật thành công"
      });
    }
    if (rollbackTask.error) {
      toggleMessage({
        type: "error",
        message: rollbackTask.error.message ?? "Failed to rollback task"
      });
    }
  }, [rollbackTask.isSuccess, rollbackTask.error]);

  const handleNewTab = () => {
    window.open(ROUTE_PATH.TASK + "?id=" + task.id, "_blank");
  };

  const style = {
    transition,
    transform: CSS.Translate.toString(transform)
  };
  if (isDragging) {
    return (
      <Box
        height={active?.rect.current.initial?.height ?? 116}
        sx={{
          position: "relative",
          backgroundColor: "transparent",
          margin: "10px",
          "&:before, &:after": {
            content: "''",
            position: "absolute",
            top: "-10px",
            left: "-10px",
            right: "-10px",
            bottom: "-10px",
            border: "2px dashed #f9be4b",
            transition: "all .5s",
            animation: "clippath 3s infinite linear"
          },
          " &:after": {
            animation: "clippath 3s infinite -1.5s linear"
          },
          "@keyframes clippath": {
            "0%, 100%": {
              clipPath: "inset(0 0 98% 0)"
            },
            "25%": {
              clipPath: "inset(0 98% 0 0)"
            },
            "50%": {
              clipPath: "inset(98% 0 0 0)"
            },
            "75%": {
              clipPath: "inset(0 0 0 98%)"
            }
          }
        }}
      ></Box>
    );
  }

  return (
    <>
      <TaskBoxWrapper
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        type={`${task.status}`}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignSelf={"stretch"}
          justifyContent={"space-between"}
        >
          <Typography
            className="TaskCardName Overflow2Line"
            sx={{ textDecoration: "underline" }}
            onClick={() => handleShowDetail()}
          >
            {task.title}
          </Typography>
          <BasicMenu
            onChange={() => {}}
            label={<MoreHorizIcon />}
            padding={0}
            options={[
              {
                value: "blank",
                label: MenuLabel({
                  onClick: handleNewTab,
                  icon: (
                    <OpenInBrowserIcon
                      sx={{ width: "20px", height: "20px", marginRight: 1 }}
                    />
                  ),
                  name: "Xem ở trong tab mới"
                })
              },
              ...(task.status === ETaskStatus.Failed
                ? [
                    {
                      value: "assign",
                      label: MenuLabel({
                        onClick: handleRollBack,
                        icon: (
                          <UndoIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Quay lại giai đoạn trước đó"
                      })
                    }
                  ]
                : []),
              ...(canManageTask && task.status === ETaskStatus.Inprogress
                ? [
                    {
                      value: "edit",
                      label: MenuLabel({
                        onClick: () => setOpenUpdateTask(true),
                        icon: (
                          <ModeEditOutlineOutlinedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Chỉnh sửa nhiệm vụ"
                      })
                    }
                  ]
                : []),

              ...(canAssignTask && task.status === ETaskStatus.Inprogress
                ? [
                    {
                      value: "assign",
                      label: MenuLabel({
                        onClick: () => setOpenUpdateAssign(true),
                        icon: (
                          <AddBoxRoundedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Giao lại cho người khác"
                      })
                    }
                  ]
                : []),
              ...(canManageTask
                ? [
                    {
                      value: "divider",
                      label: (
                        <Divider
                          sx={{
                            height: "1px",
                            width: "90%",
                            marginInline: 1
                          }}
                        />
                      )
                    },
                    {
                      value: "delete",
                      label: MenuLabel({
                        isGravity: true,
                        onClick: handleOpenDeleteDialog,
                        icon: (
                          <DeleteOutlinedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginRight: 1
                            }}
                          />
                        ),
                        name: "Xoá Nhiệm vụ"
                      })
                    }
                  ]
                : [])
            ]}
          />
        </Box>
        <div
          className="TaskShortDesc Overflow2Line"
          dangerouslySetInnerHTML={{
            __html: task.description
          }}
        />
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={0.5}
          marginTop={"auto"}
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            webkitLineClamp: "1",
            webkitBoxOrient: "vertical"
          }}
        >
          {/* <Tag name="backend" /> */}
        </Box>
        {!task.personAssigned && canAssignTask && (
          <Box
            display={"flex"}
            sx={{ width: "100%" }}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Box className="TaskStatus" display={"flex"} flexDirection={"row"}>
              <ErrorIcon />
              <div> Chưa được giao</div>
            </Box>

            <Button
              sx={{
                minWidth: "32px",
                width: "52px",
                height: "28px",
                borderRadius: 1
              }}
              onClick={() => setOpenUpdateAssign(true)}
              variant="contained"
            >
              Giao
            </Button>
          </Box>
        )}
        {task.personAssigned && (
          <Box
            display={"flex"}
            sx={{ width: "100%", height: "28px" }}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box display={"flex"} flexDirection={"row"} gap={0.5}>
              <Avatar
                src={task.personAssigned.avatar}
                alt="Avatar-Image"
                width={20}
                height={20}
              />
              <Typography
                variant="caption"
                fontSize={12}
                lineHeight={"unset"}
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  webkitLineClamp: "1",
                  webkitBoxOrient: "vertical"
                }}
              >
                {task.personAssigned.fullName}
              </Typography>
            </Box>
            <Typography
              variant="caption"
              fontSize={12}
              className="TaskDeadline"
              lineHeight={"unset"}
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                webkitLineClamp: "1",
                webkitBoxOrient: "vertical"
              }}
            >
              {task.deadLine
                ? dayjs(task.deadLine).format("DD/MM/YYYY")
                : "No deadline"}
            </Typography>
          </Box>
        )}
      </TaskBoxWrapper>
      <AssignTaskModal
        open={openUpdateAssign}
        task={task}
        onUpdateSuccess={onUpdateSuccess ?? (() => {})}
        handleClose={() => setOpenUpdateAssign(false)}
      />
      <CreateTaskModal
        open={openUpdateTask}
        taskId={task.id}
        isUpdate
        workflowId={task.boardId}
        onCreateSuccess={newTaskcard => onUpdateSuccess?.(newTaskcard!)}
        handleClose={() => setOpenUpdateTask(false)}
      />
      <Modal
        title={"Xác nhận xóa nhiệm vụ"}
        textSubmit={"Xác nhận"}
        textClose="Huỷ bỏ"
        open={openDeleteDialog}
        onSubmit={handleConfirmDelete}
        disabledSubmit={deleteTask.isLoading}
        onClose={handleCloseDeleteDialog}
      >
        {"Bạn có chắc chắn muốn xóa nhiệm vụ này?"}
      </Modal>
    </>
  );
};

export default TaskBox;
