import React from "react";
import { GlobalStyles } from "@mui/material";
import variableStyles from "./variable-styles";

function GlobalStyle() {
  return {
    "*": {
      boxSizing: "border-box"
    },

    body: {
      fontFamily: variableStyles.Inter,
      color: variableStyles.colorBlack,
      fontStyle: variableStyles.fsNormal,
      fontWeight: variableStyles.fwRegular,
      fontSize: "14px",
      lineHeight: "21px",
      scrollbarGutter: "stable",
      scrollBehavior: "smooth !important",
      overflowX: "clip",
      overflowY: "auto",
      margin: 0,
      height: "100vh",
      "&::-webkit-scrollbar": {
        width: "6px"
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "7px",
        backgroundColor: variableStyles.GreenPrimaryColor700
      },
      "& li.selected-image": {
        position: "relative",
        listStyleType: "none",

        "& button": {
          display: "none"
        }
      },
      "@media (min-width:768px)": {
        "& li.selected-image": {
          width: "124px",
          height: "124px",
          "& figure": {
            "& img": { borderRadius: "12px" }
          }
        }
      },
      "@media (max-width:767.98px)": {
        "& li.selected-image": {
          width: "1.05rem",
          height: "1.05rem",
          "& figure": {
            "& img": { borderRadius: ".12rem" }
          }
        }
      },
      "& .required-icon": {
        color: variableStyles.Warning900
      }
    },
    main: {
      margin: "0 auto",
      overflow: "clip"
    },
    html: {
      "@media only screen and (max-width: 767.98px)": {
        fontSize: `calc(100vw / 3.75)`
      },
      "&::-webkit-scrollbar": {
        width: "6px"
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "7px",
        backgroundColor: variableStyles.GreenPrimaryColor700
      }
    },
    a: {
      textDecoration: "none",
      display: "inline-block"
    },
    h1: {
      margin: 0,
      padding: 0,
      fontFamily: variableStyles.Inter
    },
    h2: {
      margin: 0,
      padding: 0,
      fontFamily: variableStyles.Inter
    },
    h3: {
      margin: 0,
      padding: 0,
      fontFamily: variableStyles.Inter
    },
    h4: {
      margin: 0,
      padding: 0,
      fontFamily: variableStyles.Inter
    },
    textarea: {
      resize: "none"
    },
    ul: {
      margin: "0",
      padding: "0",
      "& li": {
        listStyleType: "none"
      }
    },
    p: {
      margin: "0"
    },
    button: {
      textTransform: "none !important",
      padding: "0",
      minWidth: "0",
      "&.MuiLoadingButton-loading": {
        color: "transparent !important"
      },
      "& .MuiLoadingButton-loadingIndicator": {
        color: `${variableStyles.GreenPrimaryColor700} !important`
      }
    },
    figure: {
      padding: "0",
      margin: "0"
    },
    input: {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0
      },
      "&[type=number]": { MozAppearance: "textfield" }
    }
  };
}

const otherGlobalStyles = {
  "&.flex": {
    display: "flex"
  },
  "&.hidden": { display: "none" },
  "&.flex-center": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "&.flex-start-align-center": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  "&.flex-end-align-center": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  "&.flex-end-align-start": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end"
  },
  "&.flex-start-align-top": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  "&.flex-center-align-top": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  "&.flex-center-align-bottom": {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  "&.flex-between-align-top": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  "&.flex-start-align-bottom": {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start"
  },
  "&.flex-between-align-bottom": {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between"
  },
  "&.flex-between-align-center": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "&.flex-between": {
    display: "flex",
    justifyContent: "space-between"
  },
  "&.flex-column-center": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  "&.flex-column-start": {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column"
  },
  "&.flex-column-justify-start": {
    display: "flex",
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important",
    flexDirection: "column"
  },
  "&.flex-column-end": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    flexDirection: "column"
  },
  "&.scrollbar": {
    "&::-webkit-scrollbar": {
      width: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "7px",
      backgroundColor: variableStyles.blueSecondary400
    }
  },
  "&.scrollbar-small": {
    "&::-webkit-scrollbar": {
      width: "3px",
      height: "3px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "7px",
      backgroundColor: variableStyles.GreenPrimaryColor700
    }
  }
};

function AppGlobalStyles() {
  const globalStyle = GlobalStyle();
  const globalStyles = { ...globalStyle, ...otherGlobalStyles };

  const baseStyles = <GlobalStyles styles={globalStyles} />;

  return baseStyles;
}

export default AppGlobalStyles;
