
import styled from "@emotion/styled";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

interface BorderLinearProgressProps {
    height: number | undefined,
    barcolor: string | undefined,
    background: string,
    border?: string;
    radius?: number;

};

export const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(({ theme, height, barcolor, background, border, radius }) => ({
    height: height ?? 8,
    borderRadius: radius ?? 3,
    border: border,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: background ?? '#ededed',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 3,
        backgroundColor: barcolor ?? '#308fe8',
    },
}));