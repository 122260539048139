import {
  createSearchParams,
  URLSearchParamsInit,
  useNavigate
} from "react-router-dom";

const useNavigateSearch = () => {
  const navigate = useNavigate();
  return (pathname: string, params: URLSearchParamsInit) =>
    navigate(
      { pathname, search: `?${createSearchParams(params)}` },
      { unstable_flushSync: true }
    );
};

export default useNavigateSearch;
