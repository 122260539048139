import {
  IChangePasswordByAdminReq,
  IUpdateByAdminRequest,
  ITag,
  ITagReq
} from "./../../models/user/index";
import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import {
  IChangeStatusResquest,
  ICreateUserRequest,
  IGetUserReq,
  IGetUsersReq,
  IUpdateByOwnerRequest,
  IUpdatePasswordResquest,
  IUser
} from "../../models/user";
import { UniqueIdentifier } from "@dnd-kit/core";

const API_USER = ApiEndpoints.USER;
const baseURL = envConfig.API_ENDPOINT;

const createUser = (
  data: ICreateUserRequest
): Promise<IBaseResponseModel<any>> => {
  return api.post(baseURL, API_USER.CREATE.url, data);
};

const getUsers = (
  data: IGetUsersReq
): Promise<IBaseResponseModel<IBasePagingRes<any>>> => {
  return api.get(baseURL, API_USER.GET_USERS.url, {
    params: data
  });
};

const getSingle = (id: string): Promise<IBaseResponseModel<IUser>> => {
  return api.get(baseURL, `${API_USER.GET_SIGNLE.url}/${id}`);
};

const getUserOrDepartment = (
  request: ITagReq
): Promise<IBasePagingRes<ITag>> => {
  return api.get(baseURL, API_USER.GET_USER_OR_DEPARTMENT.url, {
    params: request
  });
};

const getUserOrDepartmentByWorkflowId = (
  request: ITagReq,
  workflowId: UniqueIdentifier
): Promise<IBasePagingRes<ITag>> => {
  return api.get(baseURL, API_USER.GET_USER_OR_DEPARTMENT_BY_WORKFLOW_ID.url, {
    params: { ...request, BoardId: workflowId }
  });
};
const getUserOrDepartmentByStageId = (
  request: ITagReq,
  stageId: UniqueIdentifier
): Promise<IBasePagingRes<ITag>> => {
  return api.get(baseURL, API_USER.GET_USER_OR_DEPARTMENT_BY_STAGE_ID.url, {
    params: { ...request, WorkflowId: stageId }
  });
};

const updateUserByAdmin = (
  data: IUpdateByAdminRequest
): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API_USER.UPDATE_BY_ADMIN.url, data);
};

const updatePasswordByAdmin = (
  data: IChangePasswordByAdminReq
): Promise<IBaseResponseModel<any>> => {
  return api.put(baseURL, API_USER.UPDATE_PASSWORD_BY_ADMIN.url, data);
};

const deleteUser = (id: string): Promise<IBaseResponseModel<IUser>> => {
  return api.delete(baseURL, `${API_USER.DELETE.url}/${id}`);
};

// const updateByOwner = (
//   data: IUpdateByOwnerRequest
// ): Promise<IBaseResponseModel<any>> => {
//   return api.post(baseURL, API_USER.UPDATE_BY_OWNER.url, data);
// };

// const updateStatusUser = (
//   data: IChangeStatusResquest
// ): Promise<IBaseResponseModel<any>> => {
//   return api.post(baseURL, API_USER.UPDATE_STATUS.url, data);
// };

const UserService = {
  createUser,
  getSingle,
  getUsers,
  updateUserByAdmin,
  updatePasswordByAdmin,
  getUserOrDepartment,
  getUserOrDepartmentByWorkflowId,
  getUserOrDepartmentByStageId,
  deleteUser
  // updatePassword,
  // updateByOwner,
  // updateStatusUser
};

export default UserService;
