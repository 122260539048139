import { envConfig } from "../../config/env.config";
import { IDownloadFile, IDownloadFileReq } from "../../models/Comment";
import { IBaseResponseModel } from "../../models/common/models.type";
import { IAttachmentFile } from "../../models/Task";

import ApiEndpoints from "../remote/apiEndpoints";
import api from "../remote/baseApi";

const API_UPLOAD_FILE = ApiEndpoints.UPLOAD_FILE;
const baseURL = envConfig.MEDIA_ENDPOINT;

const uploadFile = (
  params: FormData
): Promise<IBaseResponseModel<IAttachmentFile[]>> => {
  return api.post(baseURL, API_UPLOAD_FILE.CREATE.url, params, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const downloadFile = (
  params: IDownloadFileReq
): Promise<IBaseResponseModel<IDownloadFile>> => {
  return api.get(baseURL, API_UPLOAD_FILE.DOWNLOAD.url, {
    params
  });
};

const deleteFile = (
  file: IAttachmentFile
): Promise<IBaseResponseModel<string>> => {
  return api.delete(baseURL, API_UPLOAD_FILE.DELETE.url, {
    params: { path: file.path }
  });
};

const deleteFiles = (
  files: IAttachmentFile[]
): Promise<IBaseResponseModel<string>> => {
  const temp = files.map(e => e.path);
  return api.put(baseURL, API_UPLOAD_FILE.DELETE_MUlTIPLE.url, temp);
};

const UploadFileService = {
  uploadFile,
  deleteFile,
  deleteFiles,
  downloadFile
};

export default UploadFileService;
