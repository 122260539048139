import { Tooltip } from "@mui/material";
import React from "react";
import ImageCustom from "../Image";
import { ControlFormWrapper } from "./styles";
import IconQuestion from "../../assets/images/common/icon_question_line_blue.svg";

interface ControllFormProps {
  title: string;
  infor?: string | undefined;
  children: React.ReactNode;
  classname?: string;
  isRequired?: boolean;
}

export default function ControlForm(props: ControllFormProps) {
  const { title, infor, children, classname = "", isRequired = false } = props;
  return (
    <ControlFormWrapper className={`${classname}`}>
      <div className="flex-start-align-center form-title-wrapper">
        <p className="form-title">{title}</p>
        {isRequired ? (
          <p className="require-icon">*</p>
        ) : (
          infor && (
            <Tooltip title={infor} placement="top-start">
              <div>
                <ImageCustom
                  src={IconQuestion}
                  alt="Icon-Question"
                  width={"16px"}
                  height={"16px"}
                />
              </div>
            </Tooltip>
          )
        )}
      </div>
      {children}
    </ControlFormWrapper>
  );
}
