// ----------------------------------------------------------------------

import variableStyles from "../variable-styles";

export default function Input(theme: any) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            "& svg": { color: theme.palette.text.disabled }
          },
          "&.MuiInputBase-sizeLarge": {
            input: {
              padding: "16.5px 12px"
            },
            "& .MuiSelect-select": {
              padding: "16.5px 12px"
            }
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500]
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[100],
          "&:hover": {
            backgroundColor: theme.palette.background[100]
          },
          "&.Mui-focused": {
            backgroundColor: theme.palette.action.focus
          },
          "&.Mui-disabled": {
            backgroundColor: theme.palette.action.disabledBackground
          }
        },
        underline: {
          "&:before": {
            borderBottomColor: theme.palette.grey[500]
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          paddingLeft: "8px",
          borderRadius: "4px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: variableStyles.NaturalColor300
          },
          "&.Mui-disabled": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.action.disabledBackground
            }
          },
          "& fieldset": {
            borderRadius: "12px",
            padding: "0"
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        // styleOverrides cho root của sizeMedium vì TextField không có class size-specific
        root: {
          "& .MuiInputLabel-sizeMedium.MuiInputLabel-outlined:not(.Mui-focused)":
            {
              // Điều chỉnh vị trí nhãn khi thu nhỏ
              transform: "translate(14px, 12px) scale(1)"
            },
          "& .MuiInputBase-input": {
            padding: "16px 12px",
            height: "unset",
            color: variableStyles.NaturalColor800,
            fontWeight: variableStyles.fwMedium,
            fontSize: "15px",
            lineHeight: "18.15px"
          },
          "& .MuiInputBase-sizeSmall": {
            "& .MuiInputBase-input": {
              padding: "11px 8px",
              color: variableStyles.NaturalColor950,
              fontWeight: variableStyles.fwMedium,
              fontSize: "13px",
              lineHeight: "18.2px"
            },
            "& input::placeholder": {
              color: variableStyles.NaturalColor800,
              fontWeight: variableStyles.fwMedium,
              fontSize: "13px",
              lineHeight: "18.2px",
              opacity: "1"
            }
          },
          "& input::placeholder": {
            color: variableStyles.NaturalColor800,
            fontWeight: variableStyles.fwMedium,
            fontSize: "13px",
            lineHeight: "18.2px",
            opacity: "1"
          },
          "&:hover": {
            "& fieldset": {
              border: `1px solid ${variableStyles.NaturalColor600} !important`
            }
          },
          "& .Mui-focused": {
            "& fieldset": {
              boxShadow: "0px 0px 0px 4px #23B4FF33",
              border: "1px solid #0C97FF !important"
            }
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.MuiInputLabel-root.MuiInputLabel-filled.Mui-focused": {
            // Điều chỉnh vị trí nhãn khi thu nhỏ
            transform: "translate(12px, -9px) scale(0.75)"
          }
        },
        sizeLarge: {
          top: "-8px"
        }
      }
    }
  };
}
