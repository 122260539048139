import { EWorkflowType, IActionCreateWorkflow, IActionGetWorkflows, IActionSetTypeBoardState, IActionWorkflow, IReduxTypeBoardState } from "../../models/redux/workflow";
import { ICreateWorkflowRequest, IGetWorkflowsRequest } from "../../models/workflow";

export const setTypeBoardsState = (data: string[]): IActionSetTypeBoardState => ({
    type: EWorkflowType.SET_TYPEBOARDS,
    payload: { data }
});

export const getWorkflows = (
    workflowRequest: IGetWorkflowsRequest,
): IActionGetWorkflows => {
    return {
        type: EWorkflowType.GET_WORKFLOWS,
        payload: { workflowRequest }
    };
};

export const createWorkflow = (
    workflowRequest: ICreateWorkflowRequest,
): IActionCreateWorkflow => {
    return {
        type: EWorkflowType.CREATE_WORKFLOW,
        payload: { workflowRequest }
    };
};

const defaultState: IReduxTypeBoardState = {
    data: []
};

export default function workflowReducer(
    state: IReduxTypeBoardState = defaultState,
    action: IActionWorkflow
): IReduxTypeBoardState {
    switch (action.type) {
        case EWorkflowType.SET_TYPEBOARDS:
            return {
                ...state,
                data: action.payload.data
            };

        default:
            return state;
    }
}