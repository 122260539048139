export interface ENV {
  API_KEY_ENDPOINT: string;
  API_ENDPOINT: string;
  MEDIA_ENDPOINT: string;
  FIREBASE_FCM_VAPID_KEY: string;

}

export const envConfig: ENV = {
  API_KEY_ENDPOINT: process.env.REACT_APP_APIKEY_ENDPOINT ?? "",
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT ?? "",
  MEDIA_ENDPOINT: process.env.REACT_APP_MEDIA_API_ENDPOINT ?? "",
  FIREBASE_FCM_VAPID_KEY: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY ?? ""
};
