import styled from "@emotion/styled";
import { Box } from "@mui/material";
import variableStyles from "../../../../../theme/variable-styles";

export const MemberCardWrapper = styled(Box)((theme) => ({
    backgroundColor: variableStyles.colorWhite,
    height: 200,
    width: 162,
    display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 16, borderRadius: 2, boxShadow: "1px 1px 3px  #e3e3e3",

}));