import { Box, TextField } from "@mui/material";
import { CreateWorkflowFormItemWrapper } from "./styles";
import { ReactNode } from "react";

interface CreateWorkflowFormItemProps {
  name: string | null;
  desciption: string | null;
  input: ReactNode;
}

const CreateWorkflowFormItem = (props: CreateWorkflowFormItemProps) => {
  return (
    <CreateWorkflowFormItemWrapper
      className="FormItem"
      display={"flex"}
      flexDirection={"row"}
    >
      <Box width={"30%"}>
        <p className="FormItemName">{props.name}</p>
        <span className="FormItemDesc">{props.desciption}</span>
      </Box>
      <Box width={"70%"} flexGrow={1} paddingLeft={1}>
        {props.input}
      </Box>
    </CreateWorkflowFormItemWrapper>
  );
};

export default CreateWorkflowFormItem;
