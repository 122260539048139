import ActionProject from "../ActionProject";
import JobList from "./components/Jobs/JobList";
import { IRoleBoard, IStageByWorkflowResponse } from "../../../models/Stage";
import { EStageType } from "../../../models/common/models.enum";

interface Props {
  workflow: IStageByWorkflowResponse;
  role: IRoleBoard;
}

const Manage = ({ workflow, role }: Props) => {
  return (
    <>
      <JobList boardId={workflow.board.id} />
      {/* <ActionProject
        workflow={workflow.board}
        role={role}
        hasStepStage={
          !!workflow.workflows.find(e => e.type === EStageType.Step)
        }
        onCreateTaskSuccess={() => {}}
        onCreateStage={() => {}}
        onCloseWorkflow={id => {}}
        onUpdateWorkflowSuccess={() => {}}
      /> */}
    </>
  );
};

export default Manage;
