import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Button, Pagination } from "@mui/material";
import { IUser } from "../../../../models/user";
import { use } from "i18next";
import { useEffect, useMemo } from "react";
import { ETimeOffStatus } from "../../../../models/common/models.enum";
import variableStyles from "../../../../theme/variable-styles";

interface IProps {
  tab: string;
}
interface Data {
  id: number;
  title: string;
  creator: IUser | null;
  proposeAt: string;
  status: ETimeOffStatus;
}

function createData(
  id: number,
  title: string,
  creator: IUser | null,
  proposeAt: string,
  status: ETimeOffStatus
): Data {
  return {
    id,
    title,
    creator,
    proposeAt,
    status
  };
}

const rows = [
  createData(1, "Lí do cá nhân", null, "2023-01-01", ETimeOffStatus.Approved),
  createData(
    2,
    "Lí do cá nhân",
    null,
    "2023-01-01",
    ETimeOffStatus.PendingApproval
  ),
  createData(
    3,
    "Lí do cá nhân",
    null,
    "2023-01-01",
    ETimeOffStatus.PendingApproval
  ),
  createData(4, "Lí do cá nhân", null, "2023-01-01", ETimeOffStatus.Canceled),
  createData(5, "Lí do cá nhân", null, "2023-01-01", ETimeOffStatus.Rejected)
];

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  colSpan: number;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    colSpan: 1,
    disablePadding: true,
    label: "#"
  },
  {
    id: "title",
    colSpan: 6,
    disablePadding: false,
    label: "Tiêu đề"
  },
  {
    id: "creator",
    colSpan: 1,
    disablePadding: false,
    label: "Người tạo"
  },
  {
    id: "proposeAt",
    colSpan: 1,
    disablePadding: false,
    label: "Ngày đề xuất"
  },
  {
    id: "status",
    colSpan: 1,
    disablePadding: false,
    label: "Trạng thái"
  }
];

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead sx={{ backgroundColor: "white !important" }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts"
            }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            colSpan={headCell.colSpan}
            align={"left"}
            sx={{ color: "#03c847 !important" }}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
interface EnhancedTableToolbarProps {
  numSelected: number;
}
function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;
  if (numSelected <= 0) return null;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 }
        },
        numSelected > 0 && {
          bgcolor: theme =>
            alpha(
              theme.palette.secondary.light,
              theme.palette.action.activatedOpacity
            )
        }
      ]}
    >
      <Typography sx={{ flex: "1 1 80%" }} color="white" variant="subtitle1">
        {numSelected} Đề xuất
      </Typography>
      <Button
        sx={{
          color: "white",
          width: "124px !important",
          "&.MuiButton-root": {
            "&.MuiButton-text": {
              "&:hover": {
                boxShadow: "none",
                color: "white !important",
                backgroundColor: "transparent"
              }
            }
          }
        }}
      >
        {"Phê duyệt tất cả"}
      </Button>
    </Toolbar>
  );
}

const TimeOffStatus = (status: ETimeOffStatus) => {
  const statusData = () => {
    switch (status) {
      case ETimeOffStatus.Approved:
        return {
          label: "Đã phê duyệt",
          bgcolor: "#03c847"
        };
      case ETimeOffStatus.PendingApproval:
        return { label: "Đang chờ duyệt", bgcolor: "#267CDE" };
      case ETimeOffStatus.Rejected:
        return { label: "Đã từ chối", bgcolor: "#8c8c8c" };
      case ETimeOffStatus.Canceled:
      default:
        return { label: "Đã huỷ bỏ", bgcolor: "#fa6464" };
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: statusData().bgcolor,
        width: 128,
        height: 32,
        borderRadius: 1,
        alignContent: "center",
        color: "white",
        fontSize: 14,
        fontWeight: 600
      }}
    >
      <center>{statusData().label}</center>
    </Box>
  );
};

const TimeOffTable = ({ tab }: IProps) => {
  const [selected, setSelected] = React.useState<readonly number[]>([]);

  const selectableRows = useMemo(
    () => rows.filter(n => n.status === ETimeOffStatus.PendingApproval),
    [rows]
  );

  useEffect(() => {
    setSelected([]);
  }, [tab]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = selectableRows.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: Data) => {
    const selectedIndex = selected.indexOf(row.id);
    let newSelected: readonly number[] = [];
    if (row.status === ETimeOffStatus.PendingApproval) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    }
  };

  const visibleRows = React.useMemo(() => [...rows], []);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%"
        }}
      >
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer sx={{ flexGrow: 1 }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={selectableRows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = selected.includes(row.id);
                const labelId = `timeoff-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row)}
                    role="checkbox"
                    // aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    // selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        disabled={row.status !== ETimeOffStatus.PendingApproval}
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="left"
                    >
                      {row.id}
                    </TableCell>

                    <TableCell align={"left"} colSpan={6}>
                      {row.title}
                    </TableCell>
                    <TableCell align={"left"} style={{ width: 200 }}>
                      {"Nguyễn Quốc An"}
                    </TableCell>
                    <TableCell align={"left"} style={{ width: 280 }}>
                      {row.proposeAt}
                    </TableCell>
                    <TableCell align={"left"} style={{ width: 200 }}>
                      {TimeOffStatus(row.status)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Pagination count={3} sx={{ padding: 1 }} /> */}
      </Paper>
    </Box>
  );
};
export default TimeOffTable;
