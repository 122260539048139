import { styled } from "@mui/material/styles";
import { FormControlProps } from "@mui/material";
import variableStyles from "../../theme/variable-styles";

export const MenuBasicWrapper = styled("div")<FormControlProps>(
  ({ theme }) => ({
    "& .menu-button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // padding: "8px",
      gap: "4px",
      "& p": {
        fontFamily: variableStyles.Inter,
        fontWeight: variableStyles.fwSemiBold,
        fontSize: "15px",
        lineHeight: "19.5px",
        color: variableStyles.blueSecondary600
      },
      "& .button-arrow": {
        transition: "all .3s linear",
        transform: "rotate(180deg)"
      },
      "&.active": {
        "& .button-arrow": {
          transform: "rotate(0)"
        }
      }
    }
  })
);
