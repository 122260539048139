import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const LoadingIndicatorWrapper = styled("div")(({ theme }) => ({
  "& .lds-roller": {
    display: "inline-block",
    position: "relative",
    width: "80px",
    height: "80px",
    transform: "scale(0.8)",
    "& div": {
      animation: "lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
      transformOrigin: "40px 40px",
      "&::after": {
        content: '" "',
        display: "block",
        position: "absolute",
        width: "7.2px",
        height: "7.2px",
        borderRadius: "50%",
        background: variableStyles.blueSecondary500,
        margin: "-3.6px 0 0 -3.6px"
      },
      "&:nth-child(1)": {
        animationDelay: "-0.036s",
        "&::after": {
          top: "62.62742px",
          left: "62.62742px"
        }
      },
      "&:nth-child(2)": {
        animationDelay: "-0.072s",
        "&::after": {
          top: "67.71281px",
          left: "56px"
        }
      },
      "&:nth-child(3)": {
        animationDelay: "-0.108s",
        "&::after": {
          top: "70.90963px",
          left: "48.28221px"
        }
      },
      "&:nth-child(4)": {
        animationDelay: "-0.144s",
        "&::after": {
          top: "72px",
          left: "40px"
        }
      },
      "&:nth-child(5)": {
        animationDelay: "-0.18s",
        "&::after": {
          top: "70.90963px",
          left: "31.71779px"
        }
      },
      "&:nth-child(6)": {
        animationDelay: "-0.216s",
        "&::after": {
          top: "67.71281px",
          left: "24px"
        }
      },
      "&:nth-child(7)": {
        animationDelay: "-0.252s",
        "&::after": {
          top: "62.62742px",
          left: "17.37258px"
        }
      },
      "&:nth-child(8)": {
        animationDelay: "-0.288s",
        "&::after": {
          top: "56px",
          left: "12.28719px"
        }
      }
    }
  },
  "@keyframes lds-roller": {
    "0%": {
      transform: "rotate(0deg)"
    },
    "100%": {
      transform: "rotate(360deg)"
    }
  }
}));
