import { Box, Button, Typography } from "@mui/material";
import EmptyImage from "../../../assets/images/common/featured_icon.svg";

type Props = {
     keySearch: string,
     title: string,
     clearSearch?: Function,
};

const SearchNoResult = (props: Props) => {
     const { clearSearch } = props;

     function handleClear() {
          if (clearSearch) clearSearch();
     }

     return <Box sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          height: "80vh",
     }}>
          <img src={EmptyImage} width="100px" height="100px" />
          <Typography variant="subtitle1" sx={{ marginBottom: "8px", marginTop: "24px" }} >Không tìm thấy kết quả</Typography>
          <Typography variant="body2">Từ khoá "{props.keySearch}" không thể tìm thấy bất kỳ kết quả nào</Typography>
          <Typography variant="body2">Vui lòng thử lại từ khoá khác hoặc tạo {props.title} mới</Typography>
          <Button variant="outlined" sx={{ marginTop: "14px", borderRadius: "4px" }} size="small" color="info" onClick={handleClear}>Xoá tìm kiếm</Button>
     </Box>;
};

export default SearchNoResult;
