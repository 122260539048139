import { StageAreaWrapper } from "./styles";
import TaskBox from "../TaskBox/task_box";
import { FC, useEffect, useMemo, useState } from "react";
import { SortableContext } from "@dnd-kit/sortable";
import { TaskModel } from "../../../../../common/models/common/model.type";

import {
  EStageType,
  EWorkflowProcess
} from "../../../../../models/common/models.enum";
import { IRoleBoard, IStage } from "../../../../../models/Stage";
import { ITaskCard } from "../../../../../models/Task";
import { IRoleWorkflow } from "../../../../../models/workflow";
import { authTokens } from "../../../../../services/services";
import { IUser } from "../../../../../models/user";

interface StageAreaProps {
  stage: IStage;
  tasks: ITaskCard[];
  roleWorkflow: IRoleBoard;
  onTaskUpdateSuccess: (newTask: ITaskCard) => void;
  onTaskDeleteSuccess: (newTask: ITaskCard) => void;
  onRollBackSuccess: (newTask: ITaskCard) => void;
  hidden?: boolean;
  disabled?: boolean;
}
const StageArea: FC<StageAreaProps> = ({
  stage,
  tasks,
  roleWorkflow,
  onTaskUpdateSuccess,
  onTaskDeleteSuccess,
  onRollBackSuccess,
  hidden,
  disabled
}) => {
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const tasksId = useMemo(() => tasks.map(task => task.id), [tasks]);

  useEffect(() => {
    authTokens.getUser().then(user => setUserInfo(user));
  }, [stage]);

  return (
    <StageAreaWrapper
      className={
        "StageArea " +
        (hidden === true ? "hidden" : "") +
        (stage.type === EStageType.Cancel ? " StageArea-Cancel" : "") +
        (stage.type === EStageType.Complete ? " StageArea-Complete" : "")
        // + (stage.type === 3 ? " FailedStage" : "")
      }
    >
      <SortableContext items={tasksId}>
        {tasks.map(task => (
          <TaskBox
            key={task.id}
            roleStage={stage.roleWorkflowByUser}
            roleWorkflow={roleWorkflow}
            onUpdateSuccess={onTaskUpdateSuccess}
            onDeleteSuccess={onTaskDeleteSuccess}
            onRollBackSuccess={onRollBackSuccess}
            task={task}
            canReassignTask={
              stage.reassignedWork === EWorkflowProcess.TaskAssigneeDecides &&
              userInfo?.id === task.personAssignedId
            }
            disabled={
              !roleWorkflow.isCreator &&
              !roleWorkflow.isProcessManagement &&
              userInfo?.id !== task.personAssignedId
            }
          />
        ))}
      </SortableContext>
    </StageAreaWrapper>
  );
};

export default StageArea;
