import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { Avatar } from "../../../../../../components";
import { envConfig } from "../../../../../../config/env.config";
import {
  EFileType,
  EImageType
} from "../../../../../../models/common/models.enum";
import { IAttachmentFile } from "../../../../../../models/Task";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ImageCustom from "../../../../../../components/ImageCustom";
import { useApi } from "../../../../../../hooks/useApi.hook";
import UploadFileService from "../../../../../../services/api/uploadFile.service";
import { IDownloadFile } from "../../../../../../models/Comment";
import { b64toBlob } from "../../../../../../common/handles/common.handles";
import FileImage from "./FileImage";

type Props = {
  name: string;
  time: Date | null;
  content: string;
  avatar: string;
  attachmentFiles?: IAttachmentFile[];
  margin?: string;
};

const CommentBox = (props: Props) => {
  const { name, time, content, avatar, attachmentFiles, margin } = props;

  const downloadFile = useApi<IDownloadFile>({});

  function handleDownloadFile(file: IAttachmentFile) {
    downloadFile.request(
      UploadFileService.downloadFile({
        path: file.path,
        fileName: file.fileName
      }).then(response => {
        if (response.success) {
          const blob = b64toBlob(
            response.data.fileContent,
            response.data.fileName
          );
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", response.data.fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode!.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      })
    );
  }

  return (
    <Box margin={margin} display="flex">
      <Avatar src={avatar} width="30px" height="30px" />
      <Box marginLeft="12px" flexGrow={1}>
        <Box flexGrow={1}>
          <Typography variant="subtitle2" fontSize="12px">
            {name}
          </Typography>
          <Typography variant="caption" fontSize="10px">
            {moment(time).fromNow()}
          </Typography>
        </Box>
        <Box marginTop="4px">
          <Typography variant="caption">{content}</Typography>
          <Box
            marginTop="8px"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {attachmentFiles &&
              attachmentFiles.map((file, idx) => (
                <Button
                  key={idx}
                  color="info"
                  onClick={() => handleDownloadFile(file)}
                >
                  <Box display="flex" alignItems="center" marginBottom="12px">
                    <FileImage type={file.extension ?? EFileType.doc} />
                    <Typography variant="caption" margin="0 8px">
                      {file.fileName}
                    </Typography>
                    <CloudDownloadOutlinedIcon
                      sx={{ fontSize: 16, color: "GrayText" }}
                    />
                  </Box>
                </Button>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CommentBox;
