import { UniqueIdentifier } from "@dnd-kit/core";
import { envConfig } from "../../config/env.config";
import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import {
  IStage,
  IStageByWorkflowResponse,
  IStageRequest,
  IUpdateStagePositionRequest
} from "../../models/Stage";
import { IWorkflowByTypeResponse } from "../../models/workflow";
import ApiEndpoints from "../remote/apiEndpoints";
import api from "../remote/baseApi";

const API_STAGE = ApiEndpoints.STAGE;
const baseURL = envConfig.API_ENDPOINT;

const getStages = (): Promise<IBasePagingRes<IStageByWorkflowResponse>> => {
  const stages = api.get(baseURL, API_STAGE.GET_ALL.url);

  return stages;
};

const getStageByWorkflowId = (
  id: string
): Promise<IBaseResponseModel<IStageByWorkflowResponse>> => {
  const stage = api.get(baseURL, API_STAGE.GET_BY_WORKFLOWID.url, {
    params: { boardId: id }
  });
  return stage;
};
const getStageById = (
  id: UniqueIdentifier
): Promise<IBaseResponseModel<IStage>> => {
  const stage = api.get(baseURL, API_STAGE.GET_SINGLE.url + `/${id}`);
  return stage;
};

const createStage = (
  params: IStageRequest
): Promise<IBaseResponseModel<IStage>> => {
  return api.post(baseURL, API_STAGE.CREATE_NEW_STAGE.url, params);
};

const updateStage = (
  id: UniqueIdentifier,
  params: IStageRequest
): Promise<IBaseResponseModel<IStage>> => {
  return api.put(baseURL, API_STAGE.UPDATE.url, { ...params, Id: id });
};
const getListWorkflowByBoardId = (
  boardId: UniqueIdentifier
): Promise<IBaseResponseModel<IStage[]>> => {
  return api.get(baseURL, API_STAGE.GET_LIST_WORKFLOW_BY_BOARD_ID.url, {
    params: { boardId: boardId }
  });
};

const deleteStage = (id: string): Promise<IBaseResponseModel<string>> => {
  return api.delete(baseURL, API_STAGE.DELETE.url + `/${id}`);
};

const updateStagePosition = (
  params: IUpdateStagePositionRequest
): Promise<IBaseResponseModel<IStageByWorkflowResponse>> => {
  return api.put(baseURL, API_STAGE.UPDATE_POSITION.url, params);
};

const StageService = {
  getStages,
  getStage: getStageByWorkflowId,
  createStage,
  deleteStage,
  updateStagePosition,
  updateStage,
  getStageById,
  getListWorkflowByBoardId
};

export default StageService;
