import { useEffect, useMemo, useRef, useState } from "react";
import Avatar from "../../../../components/Avatar";
import AvatarImage from "../../../../assets/images/common/avatar.png";
import { Wrapper } from "./styles";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import IconAdmin from "@mui/icons-material/AdminPanelSettingsOutlined";
import IconManage from "@mui/icons-material/ManageAccountsOutlined";
import IconEmail from "@mui/icons-material/MailOutlineRounded";
import IconCake from "@mui/icons-material/CakeOutlined";
import IconPlace from "@mui/icons-material/PlaceOutlined";
import IconTag from "@mui/icons-material/BookmarkBorderRounded";
import IconPhone from "@mui/icons-material/PhoneOutlined";
import {
  IChangePasswordByAdminReq,
  IUpdateByAdminRequest,
  IUser
} from "../../../../models/user";
import Modal from "../../../../components/Modal";
import ControlForm from "../../../../components/ControlForm";
import InputForm from "../../../../components/controls/InputForm";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Roles, UserAction } from "../../../../models/common/models.enum";
import { TagInput } from "../../../WorkflowManagement/components/TagInput/TagInput";
import { useApi } from "../../../../hooks/useApi.hook";
import UserService from "../../../../services/api/user.service";
import { toggleMessage } from "../../../../components/Toast/Toast";
import { toggleConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import CommonHandle from "../../../../common/handles/common.handles";
import { DATE_FORMAT2, DATE_REQ_FORMAT } from "../../../../config/constants";
import moment from "moment";
import EditAvatar from "../EditAvatar";
import { IAttachmentFile } from "../../../../models/Task";
import UploadFileService from "../../../../services/api/uploadFile.service";

type Props = {
  item: IUser;
  reFetch?: Function;
};

function InfoRow(props: { title: string; content: string; icon: any }) {
  return (
    <Box className="info-row">
      <Box className="info-title">
        {props.icon}
        <p>{props.title}</p>
      </Box>
      <Typography variant="caption" className="info-content">
        {props.content}
      </Typography>
    </Box>
  );
}
type Action = {
  title: string;
  controls: ControlsForm[];
};

type ControlsForm = {
  title: string;
  child: React.ReactNode;
  isRequired?: boolean;
};

const UserInfoContainer = (props: Props) => {
  const { item, reFetch } = props;
  const [actionIndex, setActionIndex] = useState<number>(0);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<any>(null);
  const updateUser = useApi<IUser>({});
  const changePassUser = useApi<IUser>({});
  const deleteUser = useApi<IUser>({});
  const uploadFile = useApi<IAttachmentFile[]>({});

  const now = CommonHandle.formatDate(new Date(), DATE_FORMAT2);

  function handleClose() {
    setOpenCreate(false);
    reset();
    if (reFetch) reFetch();
  }

  useEffect(() => {
    reset(item);
  }, [item]);

  useEffect(() => {
    if (updateUser.success) {
      toggleMessage({
        type: "success",
        message: "Cập nhật thành công"
      });
      // if (updateUser.data) {
      //   authTokens.storeUser(updateUser.data);
      //   authTokens.storeRole(updateUser.data.roles);
      // }
      handleClose();
    }
    if (updateUser.error) {
      toggleMessage({
        type: "error",
        message: updateUser?.error ?? "Đã xảy ra lỗi"
      });
    }
  }, [updateUser.success, updateUser.error]);

  useEffect(() => {
    if (changePassUser.success) {
      toggleMessage({
        type: "success",
        message: "Cập nhật thành công"
      });
      handleClose();
    }
    if (changePassUser.error) {
      toggleMessage({
        type: "error",
        message: changePassUser?.error ?? "Đã xảy ra lỗi"
      });
    }
  }, [changePassUser.success, changePassUser.error]);

  useEffect(() => {
    if (deleteUser.success) {
      toggleMessage({
        type: "success",
        message: "Tài khoản này đã bị vô hiệu hoá"
      });
      handleClose();
    }
    if (deleteUser.error) {
      toggleMessage({
        type: "error",
        message: updateUser?.error ?? "Đã xảy ra lỗi"
      });
    }
  }, [deleteUser.success, deleteUser.error]);

  const validate = (): any => {
    switch (actionIndex) {
      case 0:
        return {
          firstName: Yup.string().required(`${"Vui lòng nhập họ và tên đệm"}`),
          lastName: Yup.string().required(`${"Vui lòng nhập tên"}`),
          userName: Yup.string().required(`${"Vui lòng nhập username"}`)
        };
      case 1:
        return {
          newPassword: Yup.string().required(`${"Vui lòng nhập mật khẩu mới"}`)
        };
      case 2:
        return {
          leaderId: Yup.array()
            .required(`${"Vui lòng chọn người quản lý"}`)
            .min(1, `${"Vui lòng chọn người quản lý"}`)
        };
      default:
        break;
    }
  };

  const validationSchema = Yup.object().shape(validate()) as any;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<any>({
    defaultValues: useMemo(() => item, [item]),
    resolver: yupResolver(validationSchema)
  });

  function handleActionClick(index: number) {
    setActionIndex(index);
    setOpenCreate(true);
  }

  function handleDeleteUser() {
    toggleConfirmModal({
      title: `Bạn muốn vô hiệu hoá?`,
      content: `Bạn có chắc chắn muốn vô hiệu hoá tài khoản này?`,
      onSubmit: async () => {
        if (item) {
          deleteUser.request(UserService.deleteUser(item.id));
        }
      },
      submitText: "Xác nhận",
      type: "warning-red"
    });
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const onSubmit = (request: any) => {
    switch (Object.values(UserAction)[actionIndex]) {
      case UserAction.ChangePassword:
        const req: IChangePasswordByAdminReq = {
          userId: request.id,
          newPassword: request.newPassword
        };
        changePassUser.request(UserService.updatePasswordByAdmin(req));
        break;

      case UserAction.ChangeManage:
        const reqUpdate: IUpdateByAdminRequest = {
          id: request.id,
          firstName: request.firstName,
          lastName: request.lastName,
          fullName: request.fullName,
          avatar: request.avatar,
          email: request.email,
          phoneNumber: request.phoneNumber,
          userName: request.userName,
          roles: request.roles,
          position: request.position,
          address: request.address,
          managerId: request.leaderId[0].id,
          birthDay: request.birthDay,
          manager: request.manager,
          status: 0
        };
        updateUser.request(UserService.updateUserByAdmin(reqUpdate));
        break;

      default:
        const reqInfo: IUpdateByAdminRequest = {
          id: request.id,
          firstName: request.firstName,
          lastName: request.lastName,
          fullName: request.fullName,
          avatar: item?.avatar,
          email: request.email,
          phoneNumber: request.phoneNumber,
          userName: request.userName,
          roles: request.roles,
          position: request.position,
          address: request.address,
          managerId: request.leaderId,
          birthDay: moment(
            CommonHandle.formatDate(request?.birthDay, DATE_REQ_FORMAT)
          ).toDate(),
          manager: request.manager,
          status: 0
        };
        if (file) {
          const formData = new FormData();
          formData.append("FolderName", "User");
          formData.append("Files", file);
          uploadFile.request(
            UploadFileService.uploadFile(formData).then(response => {
              if (response.success) {
                let newReq = { ...reqInfo };
                newReq.avatar = response.data[0].path;
                updateUser.request(
                  UserService.updateUserByAdmin(newReq).then(updateRes => {
                    if (!updateRes.success) {
                      uploadFile.request(
                        UploadFileService.deleteFile(updateRes.data[0])
                      );
                    } else {
                      handleClose();
                    }
                  })
                );
              }
            })
          );
        } else {
          updateUser.request(UserService.updateUserByAdmin(reqInfo));
        }
        break;
    }
  };

  const listControls: ControlsForm[][] = [
    [
      {
        title: "",
        child: (
          <EditAvatar
            file={file}
            avatar={item.avatar}
            onButtonClick={onButtonClick}
            handleFileChange={handleFileChange}
            inputRef={inputRef}
          />
        )
      },
      {
        title: "Họ và tên đệm",
        isRequired: true,
        child: (
          <InputForm
            placeholder={"Nhập họ và tên"}
            required
            name={"lastName"}
            errors={errors}
            control={control}
            size="small"
          />
        )
      },
      {
        title: "Tên",
        isRequired: true,
        child: (
          <InputForm
            placeholder={"Tên"}
            required
            name={"firstName"}
            errors={errors}
            control={control}
            size="small"
          />
        )
      },
      {
        title: "Username",
        isRequired: true,
        child: (
          <InputForm
            placeholder={"Nhập userName"}
            required
            name={"userName"}
            errors={errors}
            control={control}
            size="small"
          />
        )
      },
      {
        title: "Chức danh",
        child: (
          <InputForm
            placeholder={"Nhập chức danh"}
            name={"position"}
            errors={errors}
            control={control}
            size="small"
          />
        )
      },
      {
        title: "Số điện thoại",
        child: (
          <InputForm
            placeholder={"Nhập só điện thoại"}
            name={"phoneNumber"}
            errors={errors}
            control={control}
            size="small"
            type="tel"
          />
        )
      },
      {
        title: "Ngày sinh",
        child: (
          <InputForm
            name={"birthDay"}
            errors={errors}
            control={control}
            size="small"
            type="date"
            isFormatDate
            inputProps={{ max: now }}
          />
        )
      },
      {
        title: "Địa chỉ",
        child: (
          <InputForm
            placeholder={"Nhập địa chỉ"}
            name={"address"}
            errors={errors}
            control={control}
            size="small"
          />
        )
      }
    ],
    [
      {
        title: "Mật khẩu mới",
        isRequired: true,
        child: (
          <InputForm
            placeholder={"Nhập mật khẩu mới"}
            required
            name={"newPassword"}
            errors={errors}
            control={control}
            size="small"
            type="password"
          />
        )
      }
    ],
    [
      {
        title: "Chọn người quản lý trực tiếp",
        isRequired: true,
        child: (
          <TagInput
            name={"leaderId"}
            control={control}
            errors={errors}
            isError={!!errors.leaderId}
            placeholder={"Gõ để tìm"}
            limit={1}
          />
        )
      }
    ],
    [
      {
        title: "Phân quyền sử dụng",
        isRequired: true,
        child: (
          <Controller
            name="roles"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                fullWidth
                size="small"
                placeholder="Vui lòng chọn"
                sx={{ fontSize: "small" }}
              >
                {Object.values(Roles).map((role, i) => (
                  <MenuItem key={i} value={role} sx={{ fontSize: "small" }}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        )
      }
    ]
  ];

  const actions = Object.values(UserAction).map((action, i) => ({
    title: action,
    controls: listControls[i]
  }));

  return (
    <Wrapper>
      <Box>
        <Typography variant="body2" fontWeight="600">
          THÔNG TIN NHÂN VIÊN
        </Typography>
        <Divider sx={{ margin: "8px 0" }} />
      </Box>
      <Box className="information">
        <Avatar
          src={item?.avatar}
          alt="Avatar-Image"
          width={"48px"}
          height={"48px"}
        />
        <Box className="information-name">
          <Typography variant="subtitle1">{item.fullName}</Typography>
          <Typography variant="body2">{item.userName}</Typography>
        </Box>
      </Box>

      <InfoRow title={"Chức danh"} content={item.position} icon={<IconTag />} />
      <InfoRow title={"Email"} content={item.email} icon={<IconEmail />} />
      <InfoRow
        title={"Số điện thoại"}
        content={item?.phoneNumber ?? "Chưa có thông tin"}
        icon={<IconPhone />}
      />
      <InfoRow
        title={"Ngày sinh"}
        content={
          item?.birthDay
            ? CommonHandle.formatDate(item?.birthDay)
            : "Chưa có thông tin"
        }
        icon={<IconCake />}
      />
      <InfoRow
        title={"Địa chỉ"}
        content={item?.address ?? "Chưa có thông tin"}
        icon={<IconPlace />}
      />
      <InfoRow
        title={"Quản lý trực tiếp"}
        content={item?.manager?.fullName ?? "Chưa có người quản lý"}
        icon={<IconManage />}
      />
      <InfoRow
        title={"Quyền tài khoản"}
        content={item.roles}
        icon={<IconAdmin />}
      />

      {actions.map((action, i) => (
        <Button key={i} onClick={_ => handleActionClick(i)} color="info">
          {action.title}
        </Button>
      ))}

      <Button onClick={handleDeleteUser} color="error">
        Vô hiệu hoá tài khoản
      </Button>

      <Modal
        title={Object.values(UserAction)[actionIndex]}
        textSubmit="Cập nhật"
        textClose="Huỷ bỏ"
        open={openCreate}
        onSubmit={handleSubmit(onSubmit)}
        onClose={() => {
          setOpenCreate(false);
          reset();
        }}
        loadingState={updateUser.loading}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        >
          {actions[actionIndex].controls.map((ctrl, i) => (
            <ControlForm
              key={i}
              title={ctrl.title}
              isRequired={ctrl.isRequired}
              classname="brand-form"
            >
              {ctrl.child}
            </ControlForm>
          ))}
        </form>
      </Modal>
    </Wrapper>
  );
};

export default UserInfoContainer;
