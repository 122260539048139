import {
  InputBaseComponentProps,
  InputLabelProps,
  TextField
} from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";
import ImageCustom from "../../ImageCustom";
import CommonHandle, { safeRead } from "../../../common/handles/common.handles";
import { styled } from "@mui/material/styles";
import variableStyles from "../../../theme/variable-styles";
import { DATE_FORMAT2 } from "../../../config/constants";

const StartTextAndIcon = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  "& p": {
    color: variableStyles.NaturalColor700,
    fontWeight: variableStyles.fwMedium,
    fontSize: "13px",
    lineHeight: "18.2px14px"
  }
}));

export interface InputFormProps {
  name: string;
  defaultValue?: string | null;
  control: Control<any, object>;
  errors: any;
  label?: React.ReactNode;
  InputLabelProps?: Partial<InputLabelProps> | undefined;
  required?: boolean;
  onClick?: () => void;
  onChange?: (value: any) => void;
  disabled?: boolean;
  helperText?: any;
  placeholder?: string;
  classname?: string;
  isFormatNumber?: boolean;
  isFormatDate?: boolean;
  rules?: any;
  startIcon?: string;
  endText?: string;
  startText?: string;
  type?: React.HTMLInputTypeAttribute;
  size?: "small" | "medium" | "large";
  inputProps?: InputBaseComponentProps | undefined;
}
export default function InputForm({
  name,
  label,
  control,
  errors,
  InputLabelProps = {
    shrink: true
  },
  required,
  helperText,
  disabled,
  placeholder,
  onChange,
  onClick,
  isFormatNumber,
  rules,
  startIcon,
  startText,
  endText,
  type,
  size,
  defaultValue,
  inputProps,
  isFormatDate
}: InputFormProps) {
  const error = safeRead(errors, name);

  const getIconAdornment = () => {
    let adornments = {};

    if (startIcon) {
      const imageComponent = (
        <ImageCustom
          src={startIcon}
          width={size === "medium" ? 24 : 18}
          height={size === "medium" ? 24 : 18}
        />
      );

      if (startText) {
        adornments = {
          ...adornments,
          startAdornment: (
            <StartTextAndIcon className="text-icon-start">
              {imageComponent}
              <p>{startText}</p>
            </StartTextAndIcon>
          )
        };
      } else {
        adornments = {
          ...adornments,
          startAdornment: imageComponent
        };
      }
    }

    if (endText) {
      adornments = {
        ...adornments,
        endAdornment: (
          <p style={{ fontSize: "13px", fontWeight: 500, color: "#787878" }}>
            {endText}
          </p>
        )
      };
    }

    return adornments;
  };

  const iconAdornment = getIconAdornment();

  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field }) => (
        <TextField
          id={name}
          defaultValue={defaultValue}
          size={size}
          onClick={onClick}
          fullWidth
          label={label}
          error={!!error?.message || !!helperText}
          required={required}
          type={type}
          {...field}
          InputLabelProps={InputLabelProps}
          disabled={disabled}
          InputProps={iconAdornment}
          placeholder={placeholder && placeholder}
          value={
            isFormatNumber
              ? CommonHandle.formatNumber(field.value)
              : isFormatDate
                ? CommonHandle.formatDate(field.value, DATE_FORMAT2)
                : (field.value ?? undefined)
          }
          onChange={e => {
            const value = e.target.value;
            onChange ? onChange(value) : field.onChange(value);
          }}
          helperText={
            helperText
              ? helperText
              : !helperText && error?.message
                ? error?.message
                : null
          }
          inputProps={inputProps}
        />
      )}
      control={control}
    />
  );
}
