import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ControlForm, Modal } from "../../../components";
import { toggleMessage } from "../../../components/Toast/Toast";
import useAxios from "../../../components/UseAxios/useAxios";
import { ITaskCard, ITaskUpdateAssignRequest } from "../../../models/Task";
import { ITag } from "../../../models/user";
import TaskService from "../../../services/api/task.service";
import { TagInput } from "../../WorkflowManagement/components/TagInput/TagInput";

import * as Yup from "yup";

interface IProps {
  open: boolean;
  task: ITaskCard;
  onUpdateSuccess: (newTask: ITaskCard) => void;
  handleClose: () => void;
}

interface DataForm {
  assign: ITag[];
}

const AssignTaskModal = ({
  open,
  task,
  onUpdateSuccess,
  handleClose
}: IProps) => {
  const updateAssignAxios = useAxios<ITaskCard>({ loading: "OnRequest" });

  useEffect(() => {
    reset();
  }, [open]);

  useEffect(() => {
    if (updateAssignAxios)
      toggleMessage({
        type: "error",
        message: updateAssignAxios.error?.message ?? ""
      });
    if (updateAssignAxios.data) {
      onUpdateSuccess(updateAssignAxios.data);
      handleClose();
    }
  }, [updateAssignAxios.error, updateAssignAxios.isSuccess]);

  const onSubmit = async (event: DataForm) => {
    const assign = event.assign && event.assign[0];

    const request: ITaskUpdateAssignRequest = {
      id: task.id,
      personAssignedId: assign.id
    };

    updateAssignAxios.request(TaskService.updateAssign(request));
  };

  const validationSchema = Yup.object().shape({
    assign: Yup.array()
      .required(`${"Vui lòng chọn người phụ trách"}`)
      .min(1, `${"Vui lòng chọn người phụ trách"}`)
  }) as any;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });

  return (
    <Modal
      title="Lựa chọn một người phụ trách nhiệm vụ này"
      textSubmit="Giao"
      textClose="Huỷ bỏ"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      loadingState={updateAssignAxios.isLoading}
      disabledSubmit={updateAssignAxios.isLoading}
      onClose={
        !updateAssignAxios.isLoading
          ? () => {
              handleClose();
            }
          : undefined
      }
      width="500px"
    >
      <form
        style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlForm title="Giao cho" classname="brand-form">
          <TagInput
            name={"assign"}
            control={control}
            store="stage"
            storeId={task.workflowId}
            limit={1}
            errors={errors}
            isError={!!errors.assign}
            placeholder={"Sử dụng @ để tag thành viên phụ trách"}
          />
        </ControlForm>
      </form>
    </Modal>
  );
};

export default AssignTaskModal;
