import {
  ICreateDepartmentModel,
  ICreateDepartmentReq,
  IDepartment
} from "./../../department/index";
import { IGetUsersReq } from "../../user";

export interface IReduxDepartmentState {
  data?: IDepartment | null;
  listData?: IDepartment[] | null;
  error?: string | null;
  success?: boolean | null;
}

export enum EDepartmentType {
  //*Get
  SET_PAGE_STATE = "SET_PAGE_STATE",
  SET_DEPARTMENT = "SET_DEPARTMENT",
  GET_PAGE = "GET_PAGE",
  GET_SINGLE = "GET_SINGLE",
  CREATE_DEPARTMENT = "CREATE_DEPARTMENT",
  CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS",
  CREATE_DEPARTMENT_FAILED = "CREATE_DEPARTMENT_FAILED",
  UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT",
  UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS",
  UPDATE_DEPARTMENT_FAILED = "UPDATE_DEPARTMENT_FAILED"
}

export interface IActionSetPage {
  type: EDepartmentType.SET_PAGE_STATE;
  payload: { listData: IDepartment[] };
}

export interface IActionSetDepartment {
  type: EDepartmentType.SET_DEPARTMENT;
  payload: { data: IDepartment };
}

export interface IActionGetDepartments {
  type: EDepartmentType.GET_PAGE;
  payload: { request: IGetUsersReq };
}

export interface IActionGetSingleDepartment {
  type: EDepartmentType.GET_SINGLE;
  payload: { request: string };
}

export interface IActionCreateDepartment {
  type: EDepartmentType.CREATE_DEPARTMENT;
  payload: { request: ICreateDepartmentModel };
}
export interface IActionCreateDepartmentSuccess {
  type: EDepartmentType.CREATE_DEPARTMENT_SUCCESS;
  payload: {};
}

export interface IActionCreateDepartmentFailed {
  type: EDepartmentType.CREATE_DEPARTMENT_FAILED;
  payload: { message: string };
}

export interface IActionUpdateDepartment {
  type: EDepartmentType.UPDATE_DEPARTMENT;
  payload: { request: IDepartment };
}

export interface IActionUpdateDepartmentSuccess {
  type: EDepartmentType.UPDATE_DEPARTMENT_SUCCESS;
  payload: {};
}

export interface IActionUpdateDepartmentFailed {
  type: EDepartmentType.UPDATE_DEPARTMENT_FAILED;
  payload: { message: string };
}

export type IActionDepartment =
  | IActionGetDepartments
  | IActionSetPage
  | IActionSetDepartment
  | IActionCreateDepartmentSuccess
  | IActionCreateDepartmentFailed
  | IActionGetSingleDepartment
  | IActionUpdateDepartment
  | IActionUpdateDepartmentSuccess
  | IActionUpdateDepartmentFailed
  | IActionCreateDepartment;
