import React, { useCallback, useEffect, useState } from "react";
import { ControlForm, Modal, DragAndDropFile } from "../../../components";
import {
  IAttachmentFile,
  ITaskCard,
  ITaskCardUpdateRequest
} from "../../../models/Task";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAxios from "../../../components/UseAxios/useAxios";
import TaskService from "../../../services/api/task.service";
import { toggleMessage } from "../../../components/Toast/Toast";
import UploadFileService from "../../../services/api/uploadFile.service";

interface EditTaskAttachmentProps {
  open: boolean;
  onClose: () => void;
  onUpdateSuccess: () => void;
  task: ITaskCard;
}

interface DataForm {
  files: File[];
}

const EditTaskAttachmentModal: React.FC<EditTaskAttachmentProps> = ({
  open,
  onClose,
  onUpdateSuccess,
  task
}) => {
  const [isloaded, setIsLoaded] = useState(false);
  const updateAttachmentAxios = useAxios<ITaskCard>({ loading: "OnRequest" });
  const uploadFileAxios = useAxios<IAttachmentFile[]>({ loading: "OnRequest" });
  const deleteFileAxios = useAxios<string>({ loading: "OnRequest" });
  const [files, setFiles] = useState<IAttachmentFile[]>([]);
  const [filesDeleted, setFilesDeleted] = useState<File[]>([]);

  const onSubmit = useCallback(
    (data: DataForm) => {
      //delete server files Deleted
      const serverFilesDeleted = [...files].filter(file =>
        filesDeleted
          .filter(e => e.size === 0)
          .map(e => e.type)
          .includes(file.path.toLowerCase())
      );
      if (filesDeleted.length > 0 && serverFilesDeleted.length > 0) {
        let newFiles = [...files];
        newFiles = newFiles.filter(e => !serverFilesDeleted.includes(e));
        setFiles(newFiles);

        deleteFileAxios.request(
          UploadFileService.deleteFiles(serverFilesDeleted)
        );
      }
      // upload file
      if (data.files.filter(e => e.size > 0).length > 0) {
        const filesData = new FormData();
        filesData.append("FolderName", "Task");

        data.files
          .filter(e => e.size > 0)
          .forEach(file => filesData.append("Files", file));

        uploadFileAxios.request(UploadFileService.uploadFile(filesData));
      } else {
        let newFiles = [...files];
        const serverFilesDeleted = [...files].filter(file =>
          filesDeleted
            .filter(e => e.size === 0)
            .map(e => e.type)
            .includes(file.path.toLowerCase())
        );
        newFiles = newFiles.filter(e => !serverFilesDeleted.includes(e));

        handleUpdateTask(newFiles);
      }
    },
    [files, filesDeleted]
  );

  const handleUpdateTask = (files: IAttachmentFile[]) => {
    const request: ITaskCardUpdateRequest = {
      id: task.id,
      title: task.title,
      workflowId: task.workflowId,
      boardId: task.boardId,
      attachmentFiles: files
    };

    updateAttachmentAxios.request(TaskService.updateTask(request));
  };

  const onUploaded = (data: File[], name: string) => {
    setValue("files", data);
  };

  const handleRemoveFile = (value: File) => {
    const newFilesDeleted = [...filesDeleted];
    newFilesDeleted.push(value);
    setFilesDeleted(newFilesDeleted);
  };

  const validationSchema = Yup.object().shape({}) as any;

  const {
    handleSubmit,
    getValues,
    setValue,

    reset
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (open === true) {
      setFiles(task.attachmentFiles ?? []);
      setFilesDeleted([]);

      reset({
        files: task.attachmentFiles.map(
          file => new File([], file.fileName, { type: file.path })
        )
      });
      setIsLoaded(true);
    }
    return () => {
      setIsLoaded(false);
      updateAttachmentAxios.reset();
      uploadFileAxios.reset();
      deleteFileAxios.reset();
    };
  }, [open]);

  useEffect(() => {
    if (uploadFileAxios.error)
      toggleMessage({
        type: "error",
        message: uploadFileAxios.error?.message ?? ""
      });
    if (open && uploadFileAxios.isSuccess && uploadFileAxios.data) {
      const newFiles = [...files, ...(uploadFileAxios.data ?? [])];

      handleUpdateTask(newFiles);
    }
  }, [uploadFileAxios.error, uploadFileAxios.isSuccess]);

  useEffect(() => {
    if (updateAttachmentAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: updateAttachmentAxios.message ?? "Cập nhật thành công"
      });
      onUpdateSuccess();
      onClose();
    } else if (updateAttachmentAxios.error) {
      toggleMessage({
        type: "error",
        message: updateAttachmentAxios.error?.message ?? ""
      });
      if (uploadFileAxios.data) {
        deleteFileAxios.request(
          UploadFileService.deleteFiles(uploadFileAxios.data!)
        );
      }
    }
  }, [updateAttachmentAxios.error, updateAttachmentAxios.isSuccess]);

  return (
    <Modal
      title="Chỉnh sửa tài liệu đính kèm"
      open={open}
      onClose={onClose}
      loadingState={
        updateAttachmentAxios.isLoading ||
        uploadFileAxios.isLoading ||
        deleteFileAxios.isLoading
      }
      disabledSubmit={
        updateAttachmentAxios.isLoading ||
        uploadFileAxios.isLoading ||
        deleteFileAxios.isLoading
      }
      onSubmit={handleSubmit(onSubmit)}
      textSubmit="Cập nhật"
      textClose="Huỷ"
      width="580px"
    >
      {isloaded && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlForm
            title="Tài liệu đính kèm"
            infor="Đính kèm tối đa 10 tài liệu"
            classname="brand-form"
          >
            <DragAndDropFile
              title="Tài liệu đính kèm"
              limitFile={10}
              onUploaded={onUploaded}
              name="attach"
              data={getValues("files")}
              handleRemoveFile={handleRemoveFile}
            />
          </ControlForm>
        </form>
      )}
    </Modal>
  );
};

export default EditTaskAttachmentModal;
