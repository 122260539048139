import { Box } from "@mui/material";
import { ProjectManagerialWrapper } from "./styles";
import MemberCard from "./components/MemberCard";
import { useApi } from "../../../hooks/useApi.hook";
import { ITrackersManagements } from "../../../models/workflow";
import WorkflowService from "../../../services/api/workflow.service";
import { useEffect } from "react";
import { type } from "os";

const ProjectMagagerial = (props: { id: string }) => {
  const getData = useApi<ITrackersManagements>({});

  useEffect(() => {
    getData.request(WorkflowService.getTrackerManagement(props.id));
  }, []);


  return (
    <ProjectManagerialWrapper>
      <div className="ManagerialTitle">Người giám sát workflow</div>
      <Box className="ManagerialSession">
        {getData.data && getData.data.trackers.map(tracker => (
          <MemberCard
            key={tracker.id}
            id={tracker.id}
            avatar={tracker.avatar}
            fullName={tracker.fullName}
            userName={tracker.userName}
            position={tracker.position}
            workflowId={null}
            nameWorkflow={tracker.nameWorkflow} />
        ))}
      </Box>

      <div className="ManagerialTitle">Thành viên quản trị quy trình</div>
      <Box className="ManagerialSession">
        {getData.data && getData.data.managements.map(management => (
          <MemberCard
            key={management.id}
            id={management.id}
            avatar={management.avatar}
            fullName={management.fullName}
            userName={management.userName}
            position={management.position}
            workflowId={null}
            nameWorkflow={management.nameWorkflow} />
        ))}
      </Box>
    </ProjectManagerialWrapper>
  );
};

export default ProjectMagagerial;
