import { Create } from "@mui/icons-material";

const AUTH_PATH = "/auth";
const USER_PATH = "/User";
const AUDIT_PATH = "/audit";
const WORKFLOW_PATH = "/Board";
const TASK_PATH = "/TaskCard";
const DEPARTMENT_PATH = "/Department";
const STAGE_PATH = "/Workflow";
const UPLOAD_FILE_PATH = "/api/FileUpload";
const COMMENT_PATH = "/Comment";
const NOTIFICATION_PATH = "/Firebase";

const AUTH = {
  LOGIN_WITH_PASSWORD: {
    url: `${AUTH_PATH}/login`
  },
  ADMIN_LOGIN_WITH_PASSWORD: {
    url: `${AUTH_PATH}/adminlogin`
  },
  UPDATE_FCM_TOKEN: {
    url: `${AUTH_PATH}/UpdateFireBaseToken`
  }
};

const USER = {
  CREATE: {
    url: `${USER_PATH}/Register`
  },
  GET_SIGNLE: {
    url: `${USER_PATH}/GetSingle`
  },
  UPDATE_PASSWORD_BY_ADMIN: {
    url: `${USER_PATH}/AdminChangePassword`
  },
  GET_USERS: {
    url: `${USER_PATH}/GetPage`
  },
  UPDATE_BY_ADMIN: {
    url: `${USER_PATH}/AdminUpdate`
  },
  GET_USER_OR_DEPARTMENT: {
    url: `${USER_PATH}/GetPageSearchUserOrDepartment`
  },
  GET_USER_OR_DEPARTMENT_BY_STAGE_ID: {
    url: `${USER_PATH}/GetPageUserOrDepartmentByWorkflowId`
  },
  GET_USER_OR_DEPARTMENT_BY_WORKFLOW_ID: {
    url: `${USER_PATH}/GetPageUserOrDepartmentByBoardId`
  },

  DELETE: {
    url: `${USER_PATH}/Delete`
  }
  // UPDATE_STATUS: {
  //   url: `${USER_PATH}/UpdateIsDelete`
  // }
};

const AUDIT = {
  POST_START_AUDIT: {
    url: `${AUDIT_PATH}/Task`
  },
  CANCEL_TASK: {
    url: `${AUDIT_PATH}/Task/cancel`
  },
  GET_STATUS_TASK: {
    url: `${AUDIT_PATH}/Task/status`
  },
  GET_DETAIL_TASK: {
    url: `${AUDIT_PATH}/Task/detail`
  },
  GET_LIST_TASK: {
    url: `${AUDIT_PATH}/Task/gets`
  },
  GET_LIST_SITEMAPS: {
    url: `${AUDIT_PATH}/Task/sitemaps`
  }
};

const WORKFLOW = {
  GET_PAGE: {
    url: `${WORKFLOW_PATH}/GetPage`
  },
  GET_SINGLE: {
    url: `${WORKFLOW_PATH}/GetById`
  },
  DELETE: {
    url: `${WORKFLOW_PATH}/Delete`
  },
  CREATE: {
    url: `${WORKFLOW_PATH}/Create`
  },
  UPDATE: {
    url: `${WORKFLOW_PATH}/Update`
  },
  UPDATE_RELATED: {
    url: `${WORKFLOW_PATH}/UpdateWorkflowRelated`
  },
  UPDATE_STATUS: {
    url: `${WORKFLOW_PATH}/UpdateStatus`
  },
  GET_TRACKER_MANAGEMENT: {
    url: `${WORKFLOW_PATH}/GetTrackerAndManagement`
  }
};
const TASK = {
  CREATE: {
    url: `${TASK_PATH}/Create`
  },
  UPDATE: {
    url: `${TASK_PATH}/Update`
  },
  DELETE: {
    url: `${TASK_PATH}/Delete`
  },
  GET_SINGLE: {
    url: `${TASK_PATH}/GetSingle`
  },
  GET_PAGE: {
    url: `${TASK_PATH}/GetPageMyTaskCards`
  },
  UPDATE_POSITION: {
    url: `${TASK_PATH}/UpdateTaskCardPosition`
  },
  UPDATE_ASSIGN: {
    url: `${TASK_PATH}/Assigntasks`
  },
  NEXT_STAGE: {
    url: `${TASK_PATH}/NextStage`
  },
  MARK_AS_FAILED: {
    url: `${TASK_PATH}/MarkAsFailed`
  },
  ROLL_BACK: {
    url: `${TASK_PATH}/RollBack`
  }
};
const STAGE = {
  GET_ALL: {
    url: `${STAGE_PATH}/GetAll`
  },
  GET_BY_WORKFLOWID: {
    url: `${STAGE_PATH}/GetByBoardID`
  },
  GET_SINGLE: {
    url: `${STAGE_PATH}/GetSingle`
  },
  CREATE_NEW_STAGE: {
    url: `${STAGE_PATH}/Create`
  },
  DELETE: {
    url: `${STAGE_PATH}/Delete`
  },
  UPDATE_POSITION: {
    url: `${STAGE_PATH}/UpdateWorkflowPosition`
  },
  UPDATE: {
    url: `${STAGE_PATH}/Update`
  },
  GET_LIST_WORKFLOW_BY_BOARD_ID: {
    url: `${STAGE_PATH}/GetListWorkflowByBoardId`
  }
};

const DEPARTMENT = {
  GET_DEPARTMENTS: {
    url: `${DEPARTMENT_PATH}/GetPage`
  },
  CREATE: {
    url: `${DEPARTMENT_PATH}/Create`
  },
  GET_SIGNLE: {
    url: `${DEPARTMENT_PATH}/GetSingle`
  },
  UPDATE: {
    url: `${DEPARTMENT_PATH}/Update`
  },
  DELETE: {
    url: `${DEPARTMENT_PATH}/Delete`
  }
};

const UPLOAD_FILE = {
  CREATE: {
    url: `${UPLOAD_FILE_PATH}/Upload`
  },
  DELETE: {
    url: `${UPLOAD_FILE_PATH}/DeleteFile`
  },
  DELETE_MUlTIPLE: {
    url: `${UPLOAD_FILE_PATH}/DeleteFileMultiple`
  },
  DOWNLOAD: {
    url: `${UPLOAD_FILE_PATH}/DownloadFile`
  }
};

const COMMENT = {
  GET_COMMENTS: {
    url: `${COMMENT_PATH}/GetPageByTaskCardId`
  },
  GET_CHILDREN_COMMENTS: {
    url: `${COMMENT_PATH}/GetPageByCommentParentId`
  },
  GET_PAGE_BY_COMMENT_PARENT_ID: {
    url: `${COMMENT_PATH}/GetPageByCommentParentId`
  },
  CREATE: {
    url: `${COMMENT_PATH}/Create`
  }
  // GET_SIGNLE: {
  //   url: `${DEPARTMENT_PATH}/GetSingle`
  // },
  // UPDATE: {
  //   url: `${DEPARTMENT_PATH}/Update`
  // },
  // DELETE: {
  //   url: `${DEPARTMENT_PATH}/Delete`
  // }
};

const NOTIFICATION = {
  GET_PAGE: {
    url: `${NOTIFICATION_PATH}/GetPageNotification`
  },
  UPDATE: {
    url: `${NOTIFICATION_PATH}/UpdateViewed`
  }
  // GET_SIGNLE: {
  //   url: `${DEPARTMENT_PATH}/GetSingle`
  // },
  // UPDATE: {
  //   url: `${DEPARTMENT_PATH}/Update`
  // },
  // DELETE: {
  //   url: `${DEPARTMENT_PATH}/Delete`
  // }
};

const ApiEndpoints = {
  AUTH,
  USER,
  AUDIT,
  WORKFLOW,
  TASK,
  DEPARTMENT,
  STAGE,
  UPLOAD_FILE,
  COMMENT,
  NOTIFICATION
};

export default ApiEndpoints;
