import axios, { AxiosRequestConfig } from "axios";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { envConfig } from "../../config/env.config";
import authTokens from "../local/auth-tokens";
import { ELanguage } from "../../models/common/models.enum";
import { toggleMessage } from "../../components/Toast/Toast";
import Lang, { CountryLanguage } from "../../i18n/i18n";

export enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,

}

export enum Message {
  NetworkError = "Network Error",
  NetworkTimeOut = "timeout of 20000ms exceeded"
}

type ErrorResponse = {
  Error: any;
  IsResponse: boolean;
};

export const replaceBaseUrl = (url: string) => {
  const matchHttps = !!window?.location?.href.match("https");
  return matchHttps ? url : url.replace("https", "http");
};

const baseApi = axios.create({
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});

baseApi.interceptors.request.use(
  async config => {
    config.timeout = 20000;
    try {
      const lang = Lang.language;
      const token = await authTokens.getAccessToken();
      if (token != null && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers["x-authKey"] = envConfig.API_ENDPOINT;
      config.headers["X-Api-Key"] = envConfig.API_KEY_ENDPOINT;
      config.headers["Accept-Language"] =
        lang === CountryLanguage.VI ? ELanguage.Vie : ELanguage.Eng;
      return config;
    } catch (error: any) {
      throw new Error(error);
    }
  },
  error => {
    return Promise.reject(error);
  }
);
baseApi.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    const errorResponse = handleDataError(error);

    handleError(errorResponse);

    const data: any = {
      data: null,
      success: false,
      message: errorResponse.Error.message,
      statusCode: error.code
    };
    return data;
  }
);

export function getAuthorizationToken() {
  return baseApi.defaults.headers.common.Authorization;
}

export function removeAuthorizationToken() {
  delete baseApi.defaults.headers.common.Authorization;
}

function handleDataError(error: any) {
  let errorResponse: ErrorResponse = {
    Error: error,
    IsResponse: false
  };
  // Error Response
  if (error.response && error.response.data) {
    errorResponse.Error = error.response.data;
    errorResponse.IsResponse = true;
  }
  // Error Handle
  else if (error.message && error.name === "Error") {

  }

  return errorResponse;
}

const handleError = async (errorResponse: ErrorResponse) => {
  const error = errorResponse.Error;
  const isResponse = errorResponse.IsResponse;
  const { code } = error;
  let isAlert = isResponse;
  let message = error.message;

  switch (code) {
    case StatusCode.InternalServerError: {
      message = "Server đang có lỗi. Vui lòng thử lại sau!";
      // Handle InternalServerError
      break;
    }
    case StatusCode.Forbidden: {
      message = "Bạn không có quyền thực hiện chức năng này!";
      // Handle Forbidden
      break;
    }
    case StatusCode.Unauthorized: {
      isAlert = false;

      await authTokens.clear();
      // redirect to login page
      if (window.location.href.indexOf(ROUTE_PATH.LOGIN) === -1) {
        window.location.href = ROUTE_PATH.LOGIN;
      }

      break;
    }
    case StatusCode.TooManyRequests: {
      // Handle TooManyRequests
      break;
    }
  }

  if (isAlert && message) {
    toggleMessage({
      type: "error",
      message: message || ""
    });
  }


};

const _request = <T = any, R = T>(
  baseURL: string,
  config: AxiosRequestConfig
): Promise<R> => {
  return baseApi.request({ baseURL: replaceBaseUrl(baseURL), ...config });
};

const _get = <T = any, R = T>(
  baseURL: string,
  url: string,
  config?: AxiosRequestConfig
): Promise<R> => {
  return baseApi.get<T, R>(url, {
    baseURL: replaceBaseUrl(baseURL),
    ...config
  });
};

const _post = <T = any, R = T>(
  baseURL: string,
  url: string,
  data?: T,
  config?: AxiosRequestConfig
): Promise<R> => {
  return baseApi.post<T, R>(url, data, {
    baseURL: replaceBaseUrl(baseURL),
    ...config
  });
};

const _put = <T = any, R = T>(
  baseURL: string,
  url: string,
  data?: T,
  config?: AxiosRequestConfig
): Promise<R> => {
  return baseApi.put<T, R>(url, data, {
    baseURL: replaceBaseUrl(baseURL),
    ...config
  });
};

const _delete = <T = any, R = T>(
  baseURL: string,
  url: string,
  config?: AxiosRequestConfig
): Promise<R> => {
  return baseApi.delete<T, R>(url, {
    baseURL: replaceBaseUrl(baseURL),
    ...config
  });
};

const api = {
  request: _request,
  get: _get,
  put: _put,
  post: _post,
  delete: _delete
};

export default api;
