import { useEffect, useState } from "react";
import { IBasePaging } from "../models/common/models.type";

type Props = {
  isFetch?: boolean;
  isPaging?: boolean;
};

export const useApi = <T>({ isFetch, isPaging }: Props) => {
  const [data, setdata] = useState<T | undefined>(undefined);
  const [paging, setPaging] = useState<IBasePaging | undefined>(undefined);
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [promise, request] = useState<any | null>(null);
  const getResponse = async () => {
    try {
      setIsSuccess(false);
      setIsLoading(true);
      setError(null);
      const res = await promise;
      if (res) {
        if (res.success) {
          if ("data" in res) setdata(res.data as T);
          else if ("items" in res) {
            setdata(res.items as T);
            if (isPaging) {
              setPaging({
                currentPage: res.currentPage,
                totalPages: res.totalPages,
                pageSize: res.pageSize,
                totalCount: res.totalCount
              });
            }
          }
          if (isFetch !== true) {
            setIsSuccess(true);
          }
        } else {
          setError(res.message);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };
  useEffect(() => {
    if (promise) {
      getResponse();
    }
  }, [promise]);
  return {
    data: data,
    request: request,
    loading: isLoading,
    success: isSuccess,
    error: error,
    pagging: paging
  };
};
