import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// MUI
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography
} from "@mui/material";
// models
import { ILoginRequest } from "../../models/auth";
// redux
import { loginWithPassword } from "../../redux/auth/auth.duck";
import InputPassword from "../../components/controls/InputPassword";
import InputForm from "../../components/controls/InputForm";
import { LoginWrapper } from "./styles";
import { ImageCustom } from "../../components";
import LogoIcon from "../../assets/images/login/login_logo_img_line_green.png";
import EmailIcon from "../../assets/images/login/login_icon_email_line_green.svg";
import PasswordIcon from "../../assets/images/login/login_icon_password_line_green.svg";
import Banner from "../../assets/images/login/login_img_banner.png";
import GoogleIcon from "../../assets/images/login/login_logo_google.svg";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (loginInfo: ILoginRequest) => {
    dispatch(loginWithPassword(loginInfo, navigate));
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(`${"Please enter email"}`),
    password: Yup.string().required(`${"Please enter password"}`)
  }) as any;

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ILoginRequest>({
    defaultValues: {
      email: null,
      password: null
    },
    resolver: yupResolver(validationSchema)
  });

  return (
    <>
      <Helmet>
        <title>{"Login"}</title>
      </Helmet>
      <LoginWrapper>
        <div className="login-box-left">
          <Link to="/" className="left-logo">
            <ImageCustom
              src={LogoIcon}
              alt="LOGIN_LOGO"
              width={"246px"}
              height={"36px"}
            />
          </Link>
          <h2>Sign in</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputForm
              placeholder="Your email"
              required
              name="email"
              errors={errors}
              control={control}
              startIcon={EmailIcon}
            />
            <InputPassword
              placeholder="Your password"
              name="password"
              errors={errors}
              control={control}
              startIcon={PasswordIcon}
            />
            <Button
              color="primary"
              fullWidth
              size="medium"
              type="submit"
              variant="contained"
            >
              {"LOGIN"}
            </Button>
          </form>
          {/* <Button size="large" variant="text" className="forgot-button">
            Forgot password?
          </Button> */}
          {/* <Divider className="left-divider">or</Divider>
          <Button
            size="medium"
            variant="outlined"
            className="left-google-button"
          >
            <ImageCustom
              src={GoogleIcon}
              alt="Google_Icon"
              width={"24px"}
              height={"24px"}
            />
            Đăng nhập bằng Google
          </Button> */}
          <div className="sign-up-question">
            <p>Don’t have an account?</p>
            <Button size="large" variant="text">
              Sign Up
            </Button>
          </div>
        </div>
        <div className="login-box-right">
          <p className="right-text">
            We’re here to Increase
            <br />
            your Productivity
          </p>
          <ImageCustom
            src={Banner}
            alt="LOGIN_BANNER"
            width={"726px"}
            height={"479px"}
            className="right-banner"
          />
        </div>
      </LoginWrapper>
    </>
  );
};

export default Login;
