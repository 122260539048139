import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Toolbar,
  Typography
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/ModeEditRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import { Wrapper } from "./styles";
import UserTable from "../../../Member/components/UserTable/UserTable";
import { ControlForm, Modal } from "../../../../components";
import AccountLayout from "../../../Member/components/AccountLayout";
import {
  ICreateDepartmentModel,
  IDepartment
} from "../../../../models/department";
import { TagInput } from "../../../WorkflowManagement/components/TagInput/TagInput";
import { useForm } from "react-hook-form";
import { IUser } from "../../../../models/user";
import DepartmentService from "../../../../services/api/department.service";
import { toggleMessage } from "../../../../components/Toast/Toast";
import GroupRemoveRoundedIcon from "@mui/icons-material/GroupRemoveRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import CircleIcon from "@mui/icons-material/Circle";
import authTokens from "../../../../services/local/auth-tokens";
import { toggleConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import {
  Roles,
  stringToRoles,
  TagType
} from "../../../../models/common/models.enum";
import { useApi } from "../../../../hooks/useApi.hook";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import InputForm from "../../../../components/controls/InputForm";

const DetailDepartment = () => {
  const params = useParams();
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const getSingle = useApi<IDepartment>({ isFetch: true });
  const updateDepartment = useApi<IDepartment>({});
  const deleteDepartment = useApi<IDepartment>({});
  const [item, setItem] = useState<IDepartment | undefined>(undefined);

  function fetchData() {
    if (params.id) {
      getSingle.request(DepartmentService.getSingle(params.id));
    }
    setCanEdit(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setItem(getSingle.data);
    setValue("name", getSingle.data?.name);
    setValue("userName", getSingle.data?.userName);
    // setValue("leaderId", getSingle.data?.leaderId);
  }, [getSingle.data]);

  // useEffect(() => {
  //      console.log(item);
  // }, [item]);

  const validationSchema = Yup.object().shape(
    canEdit
      ? checked
        ? {
            name: Yup.string().required(`${"Vui lòng nhập tên nhóm"}`),
            userName: Yup.string().required(`${"Vui lòng nhập username"}`),
            leaderId: Yup.array()
              .required(`${"Vui lòng chọn người quản trị nhóm"}`)
              .min(1, `${"Vui lòng chọn người quản trị nhóm"}`)
          }
        : {
            name: Yup.string().required(`${"Vui lòng nhập tên nhóm"}`),
            userName: Yup.string().required(`${"Vui lòng nhập username"}`)
          }
      : {
          memberIds: Yup.array()
            .required(`${"Vui lòng chọn thành viên"}`)
            .min(1, `${"Vui lòng chọn thành viên"}`)
        }
  ) as any;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<any>({
    defaultValues: useMemo(() => item, [item]),
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (updateDepartment.success) {
      toggleMessage({
        type: "success",
        message: "Cập nhật thành công"
      });
      setOpenCreate(false);
      setCanEdit(false);
      setChecked(false);
      reset();
      fetchData();
      if (canDelete) {
        setCanDelete(false);
      }
    }
    if (updateDepartment.error) {
      toggleMessage({
        type: "error",
        message: updateDepartment?.error ?? "Đã xảy ra lỗi"
      });
    }
  }, [updateDepartment.success, updateDepartment.error]);

  useEffect(() => {
    if (deleteDepartment.success) {
      toggleMessage({
        type: "success",
        message: "Xoá nhóm thành công"
      });
      setOpenCreate(false);
      navigate(-1);
    }
    if (deleteDepartment.error) {
      toggleMessage({
        type: "error",
        message: updateDepartment?.error ?? "Đã xảy ra lỗi"
      });
    }
  }, [deleteDepartment.success, deleteDepartment.error]);

  const onSubmit = (request: ICreateDepartmentModel) => {
    if (item) {
      const old = item?.infoMembers?.map(member => member.id);
      const news = !canEdit ? request.memberIds?.map(member => member.id) : [];
      const memberIds = openCreate ? [...old, ...news] : item.memberIds;
      const name = canEdit ? request.name : item.name;
      const userName = canEdit ? request.userName : item.userName;
      const leaderId =
        canEdit && checked ? request.leaderId[0].id : item.leaderId;
      const req: IDepartment = {
        id: item.id,
        name: name,
        userName: userName,
        leaderId: leaderId,
        memberIds: memberIds,
        infoMembers: [],
        infoLeader: item.infoLeader
      };
      updateDepartment.request(DepartmentService.update(req));
    }
  };

  const onDeleteGroup = () => {
    toggleConfirmModal({
      title: `Bạn muốn xoá?`,
      content: `Bạn có chắc chắn muốn xoá nhóm này?`,
      onSubmit: async () => {
        if (item) {
          deleteDepartment.request(DepartmentService.deleteGroup(item.id));
        }
      },
      submitText: "Xác nhận",
      type: "warning-red"
    });
  };

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
  }, []);

  const handleDelete = (list: IUser[]) => {
    if (item) {
      const items = list.map(item => item.id);
      const req: IDepartment = {
        id: item.id,
        name: item.name,
        userName: item.userName,
        leaderId: item.leaderId,
        memberIds: items,
        infoMembers: [],
        infoLeader: item.infoLeader
      };
      updateDepartment.request(DepartmentService.update(req));
    }
  };

  const getDisable = (): IUser[] | undefined => {
    if (item?.infoMembers && item?.infoLeader) {
      return [...item?.infoMembers, item?.infoLeader];
    } else {
      return undefined;
    }
  };

  return (
    <Wrapper>
      <AppBar position="static" color="default">
        <Toolbar>
          <Box className="header-page">
            <Button onClick={() => navigate(-1)}>
              <KeyboardBackspaceRoundedIcon />
            </Button>
            <Box>
              <Typography variant="subtitle1">{item?.name}</Typography>
              <Typography variant="body2">
                {item?.userName}
                <span>
                  <CircleIcon
                    sx={{
                      width: "4px",
                      height: "4px",
                      margin: "2px 6px",
                      color: "GrayText"
                    }}
                  />
                </span>
                <span>{item?.infoMembers?.length} thành viên</span>
              </Typography>
            </Box>
          </Box>
          <Box
            className="admin-action"
            display={userInfo?.roles === Roles.SuperAdmin ? "flex" : "none"}
          >
            <Button
              variant="outlined"
              color="info"
              size="small"
              startIcon={<EditIcon />}
              disabled={canDelete}
              onClick={() => {
                setCanEdit(true);
                setOpenCreate(true);
              }}
            >
              Chỉnh sửa thông tin
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<GroupAddRoundedIcon />}
              disabled={canDelete}
              onClick={() => setOpenCreate(true)}
            >
              Thêm thành viên
            </Button>
            {!!item?.infoMembers.length && (
              <Button
                variant="outlined"
                color="error"
                size="small"
                startIcon={<GroupRemoveRoundedIcon />}
                disabled={canDelete}
                onClick={() => setCanDelete(!canDelete)}
              >
                Xoá thành viên
              </Button>
            )}
            <Button
              variant="contained"
              color="error"
              size="small"
              startIcon={<DeleteIcon />}
              disabled={canDelete}
              onClick={onDeleteGroup}
            >
              Xoá nhóm
            </Button>
            <Modal
              title={canEdit ? "Chỉnh sửa thông tin" : "Thêm thành viên"}
              textSubmit="Xác nhận"
              textClose="Huỷ bỏ"
              open={openCreate}
              onSubmit={handleSubmit(onSubmit)}
              onClose={() => {
                setCanEdit(false);
                setOpenCreate(false);
              }}
            >
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px"
                }}
              >
                {canEdit && (
                  <Box>
                    <ControlForm
                      title="Tên nhóm"
                      classname="brand-form"
                      isRequired
                    >
                      <InputForm
                        placeholder={"Tên nhóm"}
                        required
                        name={"name"}
                        errors={errors}
                        control={control}
                        size="small"
                        // defaultValue={item?.name}
                      />
                    </ControlForm>
                    <ControlForm
                      title="Username"
                      isRequired
                      classname="brand-form"
                    >
                      <InputForm
                        placeholder={"tennhom"}
                        required
                        name={"userName"}
                        errors={errors}
                        control={control}
                        size="small"
                        // defaultValue={item?.userName}
                      />
                    </ControlForm>
                    <ControlForm
                      title="Quản trị nhóm"
                      isRequired
                      classname="brand-form"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        margin="0 4px"
                        alignItems="center"
                      >
                        <Typography variant="body2" sx={{ flexGrow: 1 }}>
                          {item?.infoLeader?.fullName}
                        </Typography>
                        <Typography variant="body2" color="GrayText">
                          {" "}
                          Sửa
                        </Typography>
                        <Checkbox
                          title="d"
                          checked={checked}
                          onChange={_ => setChecked(!checked)}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                        />
                      </Box>
                      {checked && (
                        <TagInput
                          name={"leaderId"}
                          control={control}
                          errors={errors}
                          isError={!!errors.leaderId}
                          placeholder={"Gõ để tìm người quản trị mới"}
                          limit={1}
                          type={TagType.User}
                        />
                      )}
                    </ControlForm>
                  </Box>
                )}
                {openCreate && !canEdit && (
                  <ControlForm
                    title="Thành viên"
                    infor="Có thể thêm nhiều thành viên một lúc"
                    classname="brand-form"
                  >
                    <TagInput
                      name={"memberIds"}
                      control={control}
                      errors={errors}
                      isError={!!errors.memberIds}
                      placeholder={"Thêm thành viên"}
                      optionDisabled={getDisable()}
                      type={TagType.User}
                    />
                  </ControlForm>
                )}
              </form>
            </Modal>
          </Box>
        </Toolbar>
      </AppBar>

      <Box className="admin-container">
        <Box>
          <Typography variant="overline">Quản trị viên</Typography>
          <Box marginTop="8px">
            <AccountLayout
              name={item?.infoLeader?.fullName ?? ""}
              tag={item?.infoLeader!?.userName ?? ""}
              position={item?.infoLeader!?.position ?? ""}
              avatar={item?.infoLeader?.avatar}
            />
          </Box>
        </Box>
        {/* <Box display={stringToRoles(userInfo?.roles!) === Roles.Admin ? "flex" : "none"}>
                         {!!item?.infoMembers.length && <Button color="info" size="small" disabled={canDelete} startIcon={<EditIcon />}>Chỉnh sửa thông tin</Button>}
                         <Button color="primary" size="small" disabled={canDelete} startIcon={<GroupAddRoundedIcon />} onClick={() => setOpenCreate(true)}>
                              Thêm thành viên</Button>
                         
                         {!!item?.infoMembers.length && <Button color="error" size="small" disabled={canDelete} startIcon={<GroupRemoveRoundedIcon />}
                              onClick={() => setCanDelete(!canDelete)}>Xoá thành viên</Button>}
                    </Box> */}
      </Box>

      <Box className="main-container">
        {item && (
          <UserTable
            data={item?.infoMembers}
            canEdit={canDelete}
            cancel={() => setCanDelete(!canDelete)}
            deleted={handleDelete}
          />
        )}
      </Box>
    </Wrapper>
  );
};

export default DetailDepartment;
