import styled from "@emotion/styled";
import { Box } from "@mui/material";
import LoadingOverlayWrapper from "react-loading-overlay-ts";

export const Wrapper = styled(LoadingOverlayWrapper)(() => ({
  fontSize: "12px",
  display: "flex",
  height: "auto",
  minHeight: "100%",
  backgroundColor: "#dfdfdf",
  "& .content-container": {
    flexGrow: 1,
    backgroundColor: "white",
    padding: "12px 18px",
    "& .content-header": {
      display: "flex",

      "& .header-title": {
        flexGrow: 1,
        margin: "0 12px"
      }
    }
  },
  "& .container-progress": {
    width: "33%",
    margin: "10px  12px 0px 10px"
  }

}));
