import { IBasePagingReq } from "../../common/models.type";
import { ICreateWorkflowRequest, IGetWorkflowsRequest } from "../../workflow";

export enum EWorkflowType {
    SET_TYPEBOARDS = "SET_TYPEBOARDS",
    GET_WORKFLOWS = "GET_WORKFLOWS",
    CREATE_WORKFLOW = "CREATE_WORKFLOW"
}

export interface IReduxTypeBoardState {
    data: string[];


}

export interface IActionSetTypeBoardState {
    type: EWorkflowType.SET_TYPEBOARDS;
    payload: { data: string[] };
}
export interface IActionGetWorkflows {
    type: EWorkflowType.GET_WORKFLOWS;
    payload: { workflowRequest: IGetWorkflowsRequest };
}
export interface IActionCreateWorkflow {
    type: EWorkflowType.CREATE_WORKFLOW;
    payload: { workflowRequest: ICreateWorkflowRequest };
}

export type IActionWorkflow =
    | IActionGetWorkflows | IActionCreateWorkflow | IActionSetTypeBoardState;


