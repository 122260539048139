import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { ETaskStatus } from "../../../../../models/common/models.enum";


interface IProps {
    type: string | undefined;
}

export const TaskBoxWrapper = styled(Box)<IProps>(({ theme, type }) => {


    return ({
        color: type === `${ETaskStatus.Inprogress}`
            ? "unset"
            : "white !important",
        backgroundColor: type === `${ETaskStatus.Cancel}` || type === `${ETaskStatus.Failed}`
            ? "#CC4444"
            : type === `${ETaskStatus.Complete}`
                ? "#2BBF3D"
                : "white",
        minHeight: 116,
        borderBottom: type === `${ETaskStatus.Cancel}` || type === `${ETaskStatus.Failed}`
            ? "1.5px solid #C24141"
            : type === `${ETaskStatus.Complete}`
                ? "1.5px solid #2BA83B"
                : "1.5px solid #dbdbdb",
        // borderTop: "1px solid white",
        // borderRight: "1px solid white",
        // borderLeft: "1px solid white",
        padding: 10,
        display: "flex", flexDirection: "column",
        gap: 4,
        cursor: "pointer",
        alignItems: "flex-start",
        // "&:visited": {
        //     color: "unset"
        // },
        // "&:hover": {
        //     border: "1.5px solid #9fb6b8ad",
        //     // borderRadius: 2
        // },
        "& .Overflow2Line": {
            lineHeight: "16.8px",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
        },

        "& .TaskCardName": {
            // height: 47.6,
            fontSize: 14, fontWeight: 500,

        },
        "& .TaskShortDesc": {
            // height: 46.6,
            fontSize: 13, fontWeight: 300,

        },
        "& .MuiChip-root": {
            height: "unset", color: "white", fontWeight: 500, paddingLeft: 6, paddingRight: 6, "& span": {
                paddingLeft: 0, paddingRight: 0,
            }
        },
        "& .TaskStatus": {
            marginTop: "auto",
            alignItems: "center",
            gap: 2,
            color: "#aa0000",
            fontSize: 12,
            "& .MuiSvgIcon-root": {
                width: 16, height: 16
            },
        },
        "& .TaskDeadline": {
            color: type === `${ETaskStatus.Inprogress}`
                ? "#818181"
                : "white !important",
        },

        "& .MuiButton-root": {
            fontSize: 13
        }
    })
});