import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from "@mui/material";
import InputForm from "../../../../components/controls/InputForm";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CreateWorkflowFormItem from "./components/FormItem/form_item";
import { CreateWorkflowDialogWrapper } from "./styles";
import TextEditorQuill from "../../../../components/TextEditorQuill";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import Dropdown, { DropdownParams } from "./components/Dropdown";
import { TagInput } from "../TagInput/TagInput";
import WorkflowService from "../../../../services/api/workflow.service";
import { ICreateWorkflowRequest } from "../../../../models/workflow";
import { ITag } from "../../../../models/user";
import { toggleMessage } from "../../../../components/Toast/Toast";
import useAxios from "../../../../components/UseAxios/useAxios";
import { IWorkflow } from "../../../../models/workflow";

export interface DataForm {
  workflowName: string;
  typeBoard: string;
  management: ITag[];
  assignTasks: ITag[];
  viewAllTasks: ITag[];
  viewTasks: ITag[];
  permission: DropdownParams[];
  display: DropdownParams[];
  description: string;
  reasonFailure: string;
}

interface CreateWorkflowDialogProps {
  open: boolean;
  handleSuccess: () => void;
  handleClose: () => void;
  workflowId?: string;
}

const CreateWorkflowDialog = ({
  open,
  handleSuccess,
  handleClose,
  workflowId
}: CreateWorkflowDialogProps) => {
  const quillRef = useRef<ReactQuill>(null);

  const createWorkflowAxios = useAxios<string>({ loading: "OnRequest" });
  const updateWorkflowAxios = useAxios<string>({ loading: "OnRequest" });
  const getWorkflowAxios = useAxios<IWorkflow>({ loading: "OnRequest" });
  const [updateLoaded, setUpdateLoaded] = useState<boolean>(false);

  const permissionOptions: DropdownParams[] = [
    {
      id: 1,
      label: "Chỉ cho những thành viên đang theo dõi nhìn thấy các nhiệm vụ",
      value: 1
    },
    { id: 2, label: "Tất cả mọi người", value: 2 }
  ];
  const displayOptions: DropdownParams[] = [
    { id: 1, label: "Tất cả nhiệm vụ", value: 1 },
    { id: 2, label: "Giới hạn", value: 2 }
  ];

  useEffect(() => {
    if (open === true) {
      reset();
      setUpdateLoaded(false);
      if (workflowId) {
        getWorkflowAxios.request(WorkflowService.getWorkflow(workflowId));
      }
    }
    return () => {
      createWorkflowAxios.reset();
      getWorkflowAxios.reset();
      updateWorkflowAxios.reset();
    };
  }, [open, workflowId]);

  useEffect(() => {
    if (getWorkflowAxios.data) {
      reset({
        workflowName: getWorkflowAxios.data.name,
        typeBoard: getWorkflowAxios.data.typeBoard,
        management: getWorkflowAxios.data.processManagements.map(e => ({
          id: e.id,
          username: e.userName,
          type: e.type
        })),
        assignTasks: getWorkflowAxios.data.assignTasks.map(e => ({
          id: e.id,
          username: e.userName,
          type: e.type
        })),
        viewAllTasks: getWorkflowAxios.data.viewAllTasks.map(e => ({
          id: e.id,
          username: e.userName,
          type: e.type
        })),
        viewTasks: getWorkflowAxios.data.viewTasks.map(e => ({
          id: e.id,
          username: e.userName,
          type: e.type
        })),
        description: getWorkflowAxios.data.description ?? undefined,
        reasonFailure: getWorkflowAxios.data.reasonForFailure
      });

      setUpdateLoaded(true);
    }

    if (getWorkflowAxios.error) {
      toggleMessage({
        type: "error",
        message: getWorkflowAxios.error.message ?? ""
      });
      handleClose();
    }
  }, [getWorkflowAxios.data, getWorkflowAxios.error]);

  useEffect(() => {
    if (createWorkflowAxios.error)
      toggleMessage({
        type: "error",
        message: createWorkflowAxios.error.message ?? ""
      });
    if (createWorkflowAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: createWorkflowAxios.message ?? "Tạo luồng công việc thành công"
      });
      handleSuccess();
      handleClose();
    }
  }, [createWorkflowAxios.error, createWorkflowAxios.isSuccess]);

  useEffect(() => {
    if (updateWorkflowAxios.error)
      toggleMessage({
        type: "error",
        message: updateWorkflowAxios.error.message ?? ""
      });
    if (updateWorkflowAxios.isSuccess) {
      toggleMessage({
        type: "success",
        message: updateWorkflowAxios.message ?? "Cập nhật thành công"
      });
      handleSuccess();
      handleClose();
    }
  }, [updateWorkflowAxios.error, updateWorkflowAxios.isSuccess]);

  const onSubmit = async (data: DataForm) => {
    const _req: ICreateWorkflowRequest = {
      TypeBoard: data.typeBoard,
      Name: data.workflowName,
      Description: data.description,
      ReasonForFailure: data.reasonFailure,
      ProcessManagements: data.management?.map(v => ({
        Id: v.id,
        Username: v.username,
        Type: v.type!
      })),
      AssignTasks: data.assignTasks?.map(v => ({
        Id: v.id,
        Username: v.username,
        Type: v.type!
      })),
      ViewAllTasks: data.viewAllTasks?.map(v => ({
        Id: v.id,
        Username: v.username,
        Type: v.type!
      })),
      ViewTasks: data.viewTasks?.map(v => ({
        Id: v.id,
        Username: v.username,
        Type: v.type!
      }))
    };

    if (workflowId) {
      updateWorkflowAxios.request(
        WorkflowService.updateWorkflow(workflowId, _req)
      );
    } else {
      createWorkflowAxios.request(WorkflowService.createWorkflow(_req));
    }
  };

  const validationSchema = Yup.object().shape({
    workflowName: Yup.string().required(
      `${"Vui lòng nhập tên luồng công việc"}`
    ),
    typeBoard: Yup.string().required(
      `${"Vui lòng chọn phân loại luồng công việc"}`
    )
  }) as any;

  const {
    control,
    reset,

    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });

  return (
    <CreateWorkflowDialogWrapper
      fullWidth
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ backgroundColor: "#ededed" }}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <p>
            {workflowId
              ? "CẬP NHẬT LUỒNG CÔNG VIỆC"
              : "TẠO LUỒNG CÔNG VIỆC MỚI"}
          </p>
          <Button onClick={handleClose} sx={{ color: "grey" }}>
            <Close />
          </Button>
        </Box>
      </DialogTitle>

      <DialogContent>
        {workflowId && getWorkflowAxios.isLoading && (
          <center>
            <CircularProgress />
          </center>
        )}
        {(!workflowId ||
          (workflowId && getWorkflowAxios.isSuccess && updateLoaded)) && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <CreateWorkflowFormItem
              name={"Tên luồng công việc"}
              desciption={"Tên luồng công việc"}
              input={
                <InputForm
                  placeholder="Tên luồng công việc"
                  required
                  name="workflowName"
                  errors={errors}
                  control={control}
                  size="small"
                />
              }
            />
            <CreateWorkflowFormItem
              name={"Phân loại luồng công việc"}
              desciption={"Phân loại luồng công việc"}
              input={
                <InputForm
                  placeholder="Phân loại (ví dụ: Phòng KD, Human Resource)"
                  required
                  name="typeBoard"
                  errors={errors}
                  control={control}
                  size="small"
                />
              }
            ></CreateWorkflowFormItem>
            <CreateWorkflowFormItem
              name={"Thành viên quản trị quy trình"}
              desciption={
                "Người quản trị có thể xem nhiệm vụ của mọi thành viên. Bạn được mặc định là người quản trị"
              }
              input={
                <TagInput
                  name={"management"}
                  control={control}
                  limit={1}
                  errors={errors}
                  defaultValues={getValues("management")}
                  isError={!!errors.management}
                  placeholder={"Tag thành viên quản trị"}
                />
              }
            ></CreateWorkflowFormItem>
            <CreateWorkflowFormItem
              name={"Nhóm Thành viên có quyền tạo các nhiệm vụ mới"}
              desciption={
                "Các thành viên hoặc nhóm thành viên có quyền tạo nhiệm vụ trong luồng công việc"
              }
              input={
                <TagInput
                  name={"assignTasks"}
                  control={control}
                  errors={errors}
                  isError={!!errors.assignTasks}
                  placeholder={"Viết tên thành viên hoặc phòng ban"}
                />
              }
            ></CreateWorkflowFormItem>

            <Divider>CÁC TUỲ CHỌN KHÁC</Divider>

            {/* <CreateWorkflowFormItem
              name={"Tuỳ chọn quyền xem các nhiệm vụ"}
              desciption={"Quyền xem các nhiệm vụ trong luồng công việc"}
              input={
                <Controller
                  control={control}
                  name={"permission"}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      options={permissionOptions}
                      values={field.value}
                    />
                  )}
                />
              }
            ></CreateWorkflowFormItem> */}
            {/* <CreateWorkflowFormItem
              name={"Tuỳ chọn hiển thị các nhiệm vụ"}
              desciption={
                "Tuỳ chọn hiển thị các nhiệm vụ trong luồng công việc"
              }
              input={
                <Controller
                  control={control}
                  name={"display"}
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      options={displayOptions}
                      values={field.value}
                    />
                  )}
                />
              }
            ></CreateWorkflowFormItem> */}
            <CreateWorkflowFormItem
              name={"Người giám sát workflow"}
              desciption={
                "Thành viên có quyền xem & review tất cả nhiệm vụ nhưng không có quyền chỉnh sửa"
              }
              input={
                <TagInput
                  name={"viewAllTasks"}
                  control={control}
                  errors={errors}
                  isError={!!errors.viewAllTasks}
                  placeholder={"Sử dụng @ để tag thành viên"}
                />
              }
            ></CreateWorkflowFormItem>
            <CreateWorkflowFormItem
              name={"Nhóm thành viên có thể xem được workflow"}
              desciption={
                "Các thành viên hoặc nhóm thành viên có thể nhìn thấy luồng công việc trong danh sách"
              }
              input={
                <TagInput
                  name={"viewTasks"}
                  control={control}
                  errors={errors}
                  isError={!!errors.viewTasks}
                  placeholder={"Viết tên phòng ban"}
                />
              }
            ></CreateWorkflowFormItem>
            <CreateWorkflowFormItem
              name={"Hướng dẫn làm việc với quy trình"}
              desciption={"Mô tả chi tiết về luồng công việcs"}
              input={
                <Controller
                  control={control}
                  name={"description"}
                  render={({ field }) => (
                    <TextEditorQuill
                      {...field}
                      placeholder="Mô tả chi tiết về luồng công việc"
                      toolbar={true}
                      editorStyle={{
                        border: "1px solid #D3DCDF",
                        borderRadius: "8px",
                        width: "100%",
                        height: "24vh"
                      }}
                      quillRef={quillRef}
                    />
                  )}
                />
              }
            ></CreateWorkflowFormItem>

            <Divider>LÝ DO THẤT BẠI</Divider>

            <CreateWorkflowFormItem
              name={"Lý do thất bại"}
              desciption={
                "Vui lòng ghi chi tiết các lí do thất bại có thể xảy ra, nếu có"
              }
              input={
                <InputForm
                  placeholder="Điền một lý do thất bại có thể có"
                  required
                  name="reasonFailure"
                  errors={errors}
                  control={control}
                  size="small"
                />
              }
            ></CreateWorkflowFormItem>
          </form>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          fullWidth
          variant="contained"
          color="inherit"
          onClick={
            createWorkflowAxios.isLoading === false &&
            updateWorkflowAxios.isLoading === false &&
            getWorkflowAxios.isLoading === false
              ? handleClose
              : undefined
          }
          sx={{ height: 40, borderRadius: 1.5 }}
        >
          Bỏ qua
        </Button>
        <Box width={24}></Box>
        <Button
          fullWidth
          sx={{ height: 40, borderRadius: 1.5 }}
          variant="contained"
          type="submit"
          onClick={
            createWorkflowAxios.isLoading === false &&
            updateWorkflowAxios.isLoading === false &&
            getWorkflowAxios.isLoading === false
              ? handleSubmit(onSubmit)
              : undefined
          }
        >
          {createWorkflowAxios.isLoading || updateWorkflowAxios.isLoading ? (
            <CircularProgress color="secondary" size={24} />
          ) : workflowId ? (
            "Cập nhật luồng công việc"
          ) : (
            "Tạo luồng công việc mới"
          )}
        </Button>
      </DialogActions>
    </CreateWorkflowDialogWrapper>
  );
};

export default CreateWorkflowDialog;
