import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

interface TitleWrapperProps {
  marginbottom?: number;
}

export const TitleWrapper = styled("div")<TitleWrapperProps>(
  ({ theme, marginbottom }) => ({
    marginBottom: !!marginbottom ? marginbottom : 0,
    "&.is-center": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      "& .title-text, & button.MuiButtonBase-root": {
        textAlign: "center"
      }
    },
    "& .title-text": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwSemiBold,
      fontSize: "28px",
      lineHeight: "33.89px",
      marginBottom: "12px",
      "& span": {
        color: variableStyles.GreenPrimaryColor500
      }
    },
    "& .children-wrapper": {
      color: variableStyles.NaturalColor950,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "19.5px",
      "& span": { color: variableStyles.blueSecondary600 },
      "& button.MuiButtonBase-root": {
        textDecoration: "underline",
        color: variableStyles.blueSecondary600,
        fontWeight: variableStyles.fwMedium,
        fontSize: "14px",
        lineHeight: "14px",
        marginBottom: "2px",
        "&:hover": {
          color: `${variableStyles.blueSecondary600} !important`,
          textDecoration: "none"
        }
      }
    }
  })
);
