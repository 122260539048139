import {
  ICreateDepartmentReq,
  IDepartment
} from "./../../models/department/index";
import { all, put, takeEvery } from "redux-saga/effects";
import { toggleMessage } from "../../components/Toast/Toast";
import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import {
  EDepartmentType,
  IActionCreateDepartment,
  IActionGetDepartments,
  IActionGetSingleDepartment,
  IActionUpdateDepartment
} from "./../../models/redux/department/index";
import DepartmentService from "../../services/api/department.service";
import {
  createDepartmentFailed,
  createDepartmentSuccess,
  setDepartment,
  setPage,
  updateDepartmentFailed,
  updateDepartmentSuccess
} from "./department.duck";
// function* getPage({ payload }: IActionGetDepartments) {
//   const { request } = payload;
//   try {
//     const resUser: IBasePagingRes<IDepartment> =
//       yield DepartmentService.getPage(
//         request ?? { currenPage: "1", pageSize: 10, pageIndex: 1 }
//       );
//     if (resUser.success && resUser.statusCode === 200) {
//       const data = resUser.items;
//       yield put(setPage(data));
//     } else {
//       toggleMessage({
//         type: "error",
//         message: "Đã xảy ra lỗi"
//       });
//     }
//   } catch (err) {}
// }

function* createDepartment({ payload }: IActionCreateDepartment) {
  const { request } = payload;
  try {
    const req: ICreateDepartmentReq = {
      name: request.name,
      userName: request.userName,
      leaderId: request.leaderId[0].id,
      memberIds: request.memberIds?.map(member => member.id)
    };
    console.warn("request create department", req);

    const res: IBaseResponseModel<IDepartment> =
      yield DepartmentService.create(req);
    if (res.success && res.statusCode === 201) {
      // const data = res.items;
      yield put(createDepartmentSuccess());
    } else {
      yield put(createDepartmentFailed(res?.message ?? "Đã xảy ra lỗi"));
      toggleMessage({
        type: "error",
        message: "Đã xảy ra lỗi"
      });
    }
  } catch (err) {
    console.error(err);
  }
}

function* getSingleDepartment({ payload }: IActionGetSingleDepartment) {
  const { request } = payload;
  try {
    //!Temp
    // const id = "";
    const res: IBaseResponseModel<IDepartment> =
      yield DepartmentService.getSingle(request);
    if (res.success && res.statusCode === 200) {
      const data = res.data;
      yield put(setDepartment(data));
    } else {
      toggleMessage({
        type: "error",
        message: "Đã xảy ra lỗi"
      });
    }
  } catch (err) {}
}

function* updateDepartment({ payload }: IActionUpdateDepartment) {
  const { request } = payload;
  try {
    const resUser: IBaseResponseModel<IDepartment> =
      yield DepartmentService.update(request);
    if (resUser.success && resUser.statusCode === 200) {
      yield put(updateDepartmentSuccess());
      toggleMessage({
        type: "success",
        message: "Cập nhật thành công"
      });
    } else {
      toggleMessage({
        type: "error",
        message: resUser?.message ?? "Đã xảy ra lỗi"
      });
    }
  } catch (err) {
    yield put(updateDepartmentFailed("Đã xảy ra lỗi"));
  }
}

export default function* rootSaga() {
  yield all([
    // takeEvery(EDepartmentType.GET_PAGE, getPage),
    takeEvery(EDepartmentType.CREATE_DEPARTMENT, createDepartment),
    // takeEvery(
    //   [
    //     EDepartmentType.CREATE_DEPARTMENT_SUCCESS
    //     // EDepartmentType.UPDATE_DEPARTMENT_SUCCESS
    //   ],
    //   getPage
    // ),
    takeEvery(EDepartmentType.GET_SINGLE, getSingleDepartment),
    takeEvery(EDepartmentType.UPDATE_DEPARTMENT, updateDepartment)
  ]);
}
