import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const ControlFormWrapper = styled("div")(({ theme }) => ({
  "& .form-title-wrapper": {
    gap: "4px",
    marginBottom: "4px",
    padding: "2px 4px",
    "& .form-title": {
      // color: variableStyles.NaturalColor900,
      fontWeight: variableStyles.fwMedium,
      fontSize: "14px",
      lineHeight: "18.2px"
    },
    "& .require-icon": {
      color: variableStyles.Warning700
    }
  }
}));
