import styled from "@emotion/styled";
import { Box } from "@mui/material";

interface StageInfoWrapperProps {
    isdragging: string | undefined;
}

export const StageInfoWrapper = styled(Box)<StageInfoWrapperProps>(({ theme, isdragging }) => ({
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderTop: "1.5px solid #dbdbdb",
    borderBottom: "1.5px solid #dbdbdb",
    borderLeft: isdragging === 'true' ? "1px solid #dbdbdb" : "none",
    borderRight: "1.5px solid #dbdbdb",
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#F6F6F6",

    "& .MuiAvatar-root": {
        width: 16, height: 16,
        border: "unset !important",
        fontSize: 12,
        marginTop: "auto",

    },
    "& .MuiLinearProgress-root": {
        margin: "4px 0",
    },

    "::before": {

        background: "white",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%", height: "5em",
    }


}));