import styled from "@emotion/styled";
import { Box } from "@mui/material";
import variableStyles from "../../../theme/variable-styles";

export const ProjectManagerialWrapper = styled(Box)((theme) => ({
    display: "flex",
    flexDirection: "column",


    "& .ManagerialTitle": {
        padding: "24px 0 24px 16px",
        fontSize: 22, color: "#8e8e8e", fontWeight: 300

    },

    "& .ManagerialSession": {
        padding: "8px 16px 24px 16px",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        gap: 16,

    }


}));