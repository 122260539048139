import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

export const CreateWorkflowDialogWrapper = styled(Dialog)((theme) => ({
    "& .MuiPaper-root": {
        maxWidth: 700,
        "& .MuiDialogContent-root": {
            msOverflowStyle: "none",
            "::-webkit-scrollbar": {
                display: "none",
            },
            padding: "8px 16px 20px 16px"
        },
        "& .MuiDivider-root": {
            padding: "16px 0px 0px 0px",
            "& span": {
                fontSize: 13, fontWeight: 500
            }

        },
        "& .MuiDialogActions-root": {
            padding: "24px 16px 24px 16px"
        }
    }

}));