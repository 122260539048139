import { styled } from "@mui/material";
import variableStyles from "../../../../theme/variable-styles";

export const Wrapper = styled("div")(() => ({
  "& .MuiTableCell-head": {
    backgroundColor: "white",
    padding: "8px 16px "
  },
  // "& .MuiTableRow-root:hover": {
  //   backgroundColor: variableStyles.NaturalColor50
  // },
  // "& .MuiTableRow-root.Mui-selected": {
  //   backgroundColor: "#FEFAFA",
  //   borderLeft: "2px solid #ED3E47"
  // },
  // "& .MuiTableRow-root.Mui-selected:hover": {
  //   backgroundColor: variableStyles.Error50
  // },
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #F2F2F2"
  }
}));
