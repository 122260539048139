import { styled } from "@mui/material/styles";
import { FormControl, FormControlProps } from "@mui/material";
import variableStyles from "../../../theme/variable-styles";

interface InputSearchProps extends FormControlProps {
  width?: string;
  typestyle?: "outlined" | "contained";
}

export const InputSearchWrapper = styled(FormControl)<InputSearchProps>(
  ({ theme, width, typestyle }) => ({
    position: "relative",
    width: !!width ? width : "100%",
    "& .MuiInputBase-root": {
      height: 32,
      backgroundColor:
        typestyle === "contained"
          ? variableStyles.blueSecondary50
          : variableStyles.colorWhite,
      borderRadius: "6px",
      paddingLeft: "4px",
      "&.Mui-focused": {
        "& fieldset": {
          border:
            typestyle === "contained"
              ? "none"
              : `1px solid ${variableStyles.blueSecondary200}`
        }
      },
      "& .MuiInputAdornment-root": {
        marginRight: "6px"
      },
      "& .MuiInputBase-input": {
        fontFamily: variableStyles.Inter,
        fontWeight: variableStyles.fwRegular,
        fontSize: "14px",
        lineHeight: "24px",
        color: variableStyles.NaturalColor800,
        padding: "6px 0",
        "&::placeholder": {
          color: variableStyles.NaturalColor700,
          opacity: "1"
        }
      },
      "& fieldset": {
        borderRadius: "6px",
        border: "none"
      },
      "&:hover": {
        "& fieldset": {
          border:
            typestyle === "contained"
              ? "none"
              : `1px solid ${variableStyles.blueSecondary200}`
        }
      }
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      margin: "0",
      fontFamily: variableStyles.Inter,
      fontWeight: variableStyles.fwRegular,
      fontSize: "11px",
      lineHeight: "15.4px",
      color: variableStyles.Warning900,
      top: "45px"
    }
  })
);
