import { Box, Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { IUser } from "../../../../models/user";
import AccountLayout from "../AccountLayout";
import IconWarning from "../../../../assets/images/common/icon_shield_warning.svg";
import { stringToRoles } from "../../../../models/common/models.enum";
import { Wrapper } from "./styles";
import IconCheckBox from "../../../../assets/images/common/check_square_error.svg";
import IconCheckiIndeterminate from "../../../../assets/images/common/square_indeterminate.svg";
import InitImg from "../../../../assets/images/common/init.png";
import IconUnCheck from "../../../../assets/images/common/square.svg";
import DeleteIcon from '@mui/icons-material/DeleteRounded';
import RolesTag from "../RolesTag";
import _, { differenceBy } from "lodash";
import { toggleConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import variableStyles from "../../../../theme/variable-styles";

type Props = {
     data: IUser[],
     canEdit?: boolean,
     cancel?: Function,
     deleted?: Function,
     selected?: Function,
     selectedIndex?: number | null,
};

const UserTable = (props: Props) => {
     const { data, canEdit, cancel, deleted, selected, selectedIndex } = props;
     const [selectedList, setSelectedList] = useState<IUser[]>([]);

     function handleCancel() {
          if (cancel) {
               setSelectedList([]);
               cancel();
          }
     }

     function handleDelete() {
          if (deleted) {
               toggleConfirmModal({
                    title: `Bạn có muốn xoá?`,
                    content: `Bạn có chắc chắc muốn xoá thành viên khỏi nhóm?`,
                    onSubmit: async () => {
                         const remainingList = differenceBy(data, selectedList);
                         deleted(remainingList);
                         setSelectedList([]);
                    },
                    submitText: "Xác nhận",
                    type: "warning-red"
               });
          }
     }

     const CheckBoxItem = (props: { indeterminate?: boolean, selected?: boolean, index: number }) => {
          return <Checkbox
               indeterminate={props.indeterminate}
               size="small"
               icon={<img
                    src={IconUnCheck}
                    width={"18px"}
                    height={"18px"}
               />}
               checkedIcon={<img
                    src={IconCheckBox}
                    width={"20px"}
                    height={"20px"}
               />}
               checked={props.selected}
               indeterminateIcon={<img
                    src={IconCheckiIndeterminate}
                    width={"20px"}
                    height={"20px"}
               />}
          />;
     }

     const InfoRow = (props: { content?: string | undefined | null }) => {
          return <Box marginTop="4px">
               {!props?.content?.length && <img
                    src={IconWarning}
                    width={"14px"}
                    height={"14px"}
               />}
               <Typography variant="caption">
                    {props?.content?.length
                         ? props.content
                         : "Chưa nhập thông tin"}
               </Typography>
          </Box>;
     };

     function handleSelectedRow(item: IUser, index: number) {
          if (canEdit) {
               var list = selectedList;
               if (list.includes(item)) list = selectedList.filter(r => r.id !== item.id)
               else list = [...list, item];
               setSelectedList(list);
          } else {
               if (selected) selected(index, item);
          }
     }

     return (
          <Wrapper>
               {!data.length
                    ? <Typography variant="body2" sx={{ textAlign: "center", alignContent: "center", height: "75vh", width: "90vw", }}>Danh sách trống</Typography>
                    : <TableContainer component={Paper} sx={{ height: "calc(100vh - 140px)" }}>
                         <Table stickyHeader sx={{
                              "& .MuiTableRow-root:hover": {
                                   backgroundColor: canEdit ? "#fceeef" : variableStyles.NaturalColor100
                              },
                              "& .MuiTableRow-root.Mui-selected": {
                                   backgroundColor: canEdit ? variableStyles.Error50 : variableStyles.NaturalColor200,
                                   borderLeft: "2px solid #ED3E47"
                              },
                              "& .MuiTableRow-root.Mui-selected:hover": {
                                   backgroundColor: canEdit ? variableStyles.Error100 : variableStyles.NaturalColor300,
                              },
                         }}>
                              <TableHead>
                                   <TableRow >
                                        {canEdit && <TableCell><Typography color="error" variant="subtitle2">Chọn {selectedList.length}</Typography></TableCell>}
                                        <TableCell>HỌ VÀ TÊN</TableCell>
                                        <TableCell>TÀI KHOẢN</TableCell>
                                        <TableCell>THÔNG TIN LIÊN LẠC</TableCell>
                                        <TableCell align={canEdit ? "right" : "left"}>{canEdit ? (<Box>
                                             <Button color="error" sx={{ marginRight: "28px" }} onClick={handleCancel}>Huỷ</Button>
                                             <Button
                                                  color="error"
                                                  variant="contained"
                                                  size="small"
                                                  disabled={selectedList.length <= 0}
                                                  endIcon={<DeleteIcon />}
                                                  sx={{ borderRadius: "8px" }}
                                                  onClick={handleDelete}>
                                                  Xoá
                                             </Button>
                                        </Box>) : <p>QUẢN LÝ</p>}</TableCell>
                                   </TableRow>
                              </TableHead>

                              <TableBody>
                                   {data.map((item, index) => (
                                        <TableRow key={index} selected={selectedIndex === index || selectedList.includes(item)} onClick={() => handleSelectedRow(item, index)}>
                                             {canEdit && <TableCell>
                                                  <CheckBoxItem index={index} selected={selectedList.includes(item)} />
                                             </TableCell>}
                                             <TableCell>
                                                  <AccountLayout name={item.fullName} tag={item.userName} position={item.position} avatar={item.avatar} />
                                             </TableCell>
                                             <TableCell>
                                                  <RolesTag roles={stringToRoles(item.roles)} />
                                             </TableCell>
                                             <TableCell sx={{ display: "flex" }}>
                                                  <Box >
                                                       <Typography variant="caption">
                                                            {item.email}
                                                       </Typography>
                                                       <InfoRow content={item.phoneNumber} />
                                                  </Box>
                                             </TableCell>
                                             <TableCell>
                                                  {item?.manager
                                                       ? <AccountLayout name={item.manager.fullName} tag={item.manager.userName} position={item.manager.position} avatar={item.manager?.avatar} />
                                                       : <p>Chưa có người quản lý</p>}
                                             </TableCell>
                                        </TableRow>
                                   ))}
                              </TableBody>
                         </Table>
                    </TableContainer>}
          </Wrapper>
     );
};

export default UserTable;
