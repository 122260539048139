import { FileViewer } from "react-file-viewer-v2";
import useAxios from "../../../components/UseAxios/useAxios";
import UploadFileService from "../../../services/api/uploadFile.service";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IDownloadFile } from "../../../models/Comment";
import { b64toBlob } from "../../../common/handles/common.handles";
import { Box, Button } from "@mui/material";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import Typography from "../../../theme/overrides/Typography";
import { envConfig } from "../../../config/env.config";

const FileReviewPage = () => {
  const [searchParams] = useSearchParams();
  const downloadFileAxios = useAxios<IDownloadFile>({ loading: "OnRequest" });
  const [file, setFile] = useState<Blob | null>(null);

  const path = searchParams.get("path") as string;
  const fileName = searchParams.get("name") as string;
  const type = searchParams.get("type") as string;

  const dowloadFile = () => {
    downloadFileAxios.request(
      UploadFileService.downloadFile({
        path: path,
        fileName: fileName
      })
    );
  };

  useEffect(() => {
    dowloadFile();
  }, []);

  console.log(type.toLowerCase().replaceAll(".", ""));

  useEffect(() => {
    if (downloadFileAxios.isSuccess && downloadFileAxios.data) {
      const blob = b64toBlob(
        downloadFileAxios.data.fileContent,
        downloadFileAxios.data.fileName
      );
      setFile(blob);
    }
  }, [downloadFileAxios.isSuccess, downloadFileAxios.error]);

  return (
    <LoadingOverlayWrapper active={downloadFileAxios.isLoading} spinner>
      {file && (
        <>
          <Box position={"relative"} height={"100%"} width={"100%"}>
            <FileViewer
              unsupportedComponent={<div>Tài liệu không hỗ trợ</div>}
              file={file}
              fileType={type.toLowerCase().replaceAll(".", "")}
            />
          </Box>
          <Button
            sx={{
              position: "fixed",
              top: 30,
              right: 30,
              zIndex: 3000,
              padding: "8px !important",
              backgroundColor: "white",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}
            onClick={() => {
              const url = window.URL.createObjectURL(file);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
              link.parentNode!.removeChild(link);
              window.URL.revokeObjectURL(url);
            }}
          >
            DOWNLOAD
          </Button>
        </>
      )}
    </LoadingOverlayWrapper>
  );
};
export default FileReviewPage;
