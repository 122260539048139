import storage from "redux-persist/lib/storage";
import { Roles } from "../../models/common/models.enum";
import { IUser } from "../../models/user";

export const USER_TOKEN_KEY = "token";
export const USER_ROLE_KEY = "role";
export const USER_FCM_TOKEN_KEY = "fcm_token";
const USER_KEY = "user";

const getExpiryEpoch = (seconds: number) => {
  var t = new Date();
  t.setSeconds(t.getSeconds() + seconds);
  return t.getSeconds();
};

const storeToken = async (token: string) => {
  await storage.setItem(USER_TOKEN_KEY, token);
};
const storeRole = async (role: Roles) => {
  await storage.setItem(USER_ROLE_KEY, role);
};
const storeFcmToken = async (token: string) => {
  await storage.setItem(USER_FCM_TOKEN_KEY, token);
};

const getAccessToken = async () => {
  const tokenString = await storage.getItem(USER_TOKEN_KEY);

  return tokenString;
};



const clear = async () => {
  await storage.removeItem(USER_TOKEN_KEY);
  await storage.removeItem(USER_KEY);
  await storage.removeItem(USER_FCM_TOKEN_KEY);

};

const hasValidToken = async () => {
  const tokenString = await storage.getItem(USER_TOKEN_KEY);
  return Boolean(tokenString);
};

const storeUser = async (user: IUser) => {
  await storage.setItem(USER_KEY, JSON.stringify(user));
};

const getUser = async () => {
  const user = await storage.getItem(USER_KEY);
  if (!user) {
    return null;
  }
  return JSON.parse(user) as IUser;
};

const authTokens = {
  getExpiryEpoch,
  storeToken,
  getAccessToken,
  hasValidToken,
  clear,
  storeUser,
  getUser,
  storeRole,
  storeFcmToken
};

export default authTokens;
