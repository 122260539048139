import React, { useState } from "react";

import CreateTimeOffModal from "./components/CreateTimeOffModal/CreateTimeOffModal";
import {
  Box,
  Button,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography
} from "@mui/material";
import TimeOffTable from "./components/DataTable/TimeOffDataTable";

const TimeOffPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTabChange = (event: any, tab: string) => {
    setActiveTab(tab);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const onCreateSuccess = () => setIsModalOpen(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%"
      }}
    >
      <div style={{ backgroundColor: "white" }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ padding: "12px 18px 8px 26px" }}
        >
          <h1>Danh sách đề xuất</h1>
          <Button
            onClick={openModal}
            variant="contained"
            sx={{ height: 40, borderRadius: 1, backgroundColor: "#267CDE" }}
          >
            Tạo đề xuất
          </Button>
        </Box>

        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="secondary"
          sx={{
            paddingLeft: 1,

            "& .Mui-selected": {
              color: "#267CDE !important"
            }
          }}
        >
          <Tab
            value="all"
            icon={<Typography variant="overline">Tất cả</Typography>}
          />
          <Tab
            value="pending_approval"
            icon={<Typography variant="overline">Đến lượt duyệt</Typography>}
          />
          <Tab
            value="overdue"
            icon={<Typography variant="overline">Qua hạn duyệt</Typography>}
          />
          <Tab
            value="waiting_approval"
            icon={<Typography variant="overline">Đang chờ duyệt</Typography>}
          />
          <Tab
            value="approved"
            icon={<Typography variant="overline">Đã phê duyệt</Typography>}
          />
          <Tab
            value="rejected"
            icon={<Typography variant="overline">Đã từ chối</Typography>}
          />
          <Tab
            value="canceled"
            icon={<Typography variant="overline">Đã huỷ bỏ</Typography>}
          />
        </Tabs>
      </div>
      <Box
        sx={{
          bgcolor: "#2e1534",
          margin: 2,
          flexGrow: 1,
          height: "100%"
        }}
      >
        <TimeOffTable tab={activeTab}></TimeOffTable>
      </Box>

      <CreateTimeOffModal
        open={isModalOpen}
        handleClose={closeModal}
        onCreateSuccess={onCreateSuccess}
      />
    </Box>
  );
};

export default TimeOffPage;
