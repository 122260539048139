import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

type AvatarProps = {
  borderWidth?: number;
  borderColor?: string;
  width?: string | number;
  height?: string | number;
};
export const AvatarWrapper = styled("div")<AvatarProps>(
  ({ theme, borderColor, borderWidth, width, height }) => ({
    borderRadius: "50%",
    border: `${
      !borderWidth
        ? "none"
        : `${borderWidth != 0 ? borderWidth : 1}px solid ${
            !!borderColor ? borderColor : "#0D0D0D66"
          }`
    }`,
    overflow: "hidden",
    width: width,
    height: height
  })
);
