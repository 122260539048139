import { styled } from "@mui/material";
import variableStyles from "../../theme/variable-styles";

export const Wrapper = styled("div")(() => ({
  minHeight: "100vh",
  "& .input-file-upload": {
    display: "none"
  },
  "& .MuiTableRow-root.MuiTableRow-hover:hover": {
    backgroundColor: variableStyles.NaturalColor100
  },
  "& .MuiTableRow-root.Mui-selected": {
    backgroundColor: variableStyles.NaturalColor200
  },
  "& .MuiTableCell-head": {
    backgroundColor: "transparent"
  },
  "& .MuiTableCell-sizeSmall": {
    padding: "16px 14px"
  }
}));
