import styled from "@emotion/styled";
import LoadingOverlayWrapper from "react-loading-overlay-ts";


export const ManageProjectWrapper = styled(LoadingOverlayWrapper)((theme) => ({
    width: "100%",
    height: "100%",
    "& .board-project-header": {
        "backgroundColor": "white",
        "height": 80,
        "padding": "10px 15px 0px 0px",
        "boxSizing": "border-box",
        "& .board-project-header-info": {
            "display": "flex",
            "flexDirection": "column",
            "justifyContent": "space-between",

        },
        "& .board-project-icon": {
            "width": "50px",
            "textAlignLast": "center",

        },
        "& .board-project-title": {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: 2,
                },
            }

        },


    },
    "& .board-project-tabs": {
        "height": "calc(50% - 4px)",
        " & .tab-list": {
            "li": {
                "display": "inline",
                'padding': '9px 0px',
                'marginRight': '15px',
                " fontSize": "13px",
                "cursor": "pointer",

                "&.active": {
                    "a": {
                        "color": "black",
                    },

                    "borderBottom": 'solid 2px #267CDE'
                },
                "a": {
                    "color": "#888",
                }


            },

        }
    }
}));