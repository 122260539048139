import {
  IActionChangePasswordByAdmin,
  IActionCreateUserFailed,
  IActionCreateUserSuccess,
  IActionGetUserOrDepartment,
  IActionIncrementNotification,
  IActionSetItag,
  IActionSetNotificationCount,
  IActionUpdateUserByAdmin,
  IActionUpdateUserByAdminFailed,
  IActionUpdateUserByAdminSuccess
} from "./../../models/redux/user/index";
import {
  IChangePasswordByAdminReq,
  ICreateUserRequest,
  IGetUsersReq,
  ITag,
  ITagReq,
  IUpdateByAdminRequest
} from "./../../models/user/index";
import {
  EUserType,
  IActionClearUser,
  IActionCreateUser,
  IActionGetUser,
  IActionGetUsers,
  IActionSetUsersState,
  IActionSetUserState,
  IActionUser,
  IReduxUserState
} from "../../models/redux/user";
import { IUser } from "../../models/user";
import { Roles } from "../../models/common/models.enum";

export const setUserState = (data: IUser): IActionSetUserState => ({
  type: EUserType.SET_USER_STATE,
  payload: { data }
});

export const setUsersState = (listData: IUser[]): IActionSetUsersState => ({
  type: EUserType.SET_USERS_STATE,
  payload: { listData }
});

export const getUser = (): IActionGetUser => ({
  type: EUserType.GET_USER,
  payload: {}
});

export const incrementNotification = (): IActionIncrementNotification => ({
  type: EUserType.INCREMENT_NOTIFICATION,
  payload: {}
});

// export const setNotificationCountState = () // notification: number
// : IActionSetNotificationCount => ({
//   type: EUserType.SET_NOTIFICATION_COUNT,
//   payload: {}
// });

export const setNotificationCountState = (): IActionSetNotificationCount => ({
  type: EUserType.SET_NOTIFICATION_COUNT,
  payload: {}
});

export const getUsers = (request: IGetUsersReq): IActionGetUsers => ({
  type: EUserType.GET_USERS,
  payload: { request }
});

export const getUserOrDepartment = (
  request: ITagReq
): IActionGetUserOrDepartment => ({
  type: EUserType.GET_USER_OR_DEPARTMENT,
  payload: { request }
});

export const setItag = (itags: ITag[]): IActionSetItag => ({
  type: EUserType.SET_ITAG,
  payload: { itags }
});

export const clearUser = (): IActionClearUser => ({
  type: EUserType.CLEAR_USER,
  payload: {}
});

export const createUser = (request: ICreateUserRequest): IActionCreateUser => ({
  type: EUserType.CREATE_USER,
  payload: { request }
});

export const updateUserByAdmin = (
  request: IUpdateByAdminRequest
): IActionUpdateUserByAdmin => ({
  type: EUserType.ADMIN_UPDATE_USER,
  payload: { request }
});

export const createUserSucess = (): IActionCreateUserSuccess => ({
  type: EUserType.CREATE_SUCCESS,
  payload: {}
});

export const updateUserByAdminSucess = (): IActionUpdateUserByAdminSuccess => ({
  type: EUserType.ADMIN_UPDATE_SUCCESS,
  payload: {}
});

export const createUsersFailed = (
  message: string
): IActionCreateUserFailed => ({
  type: EUserType.CREATE_USER_FAILED,
  payload: { message }
});

export const updateUserByAdminFailed = (
  message: string
): IActionUpdateUserByAdminFailed => ({
  type: EUserType.ADMIN_UPDATE_FAILED,
  payload: { message }
});

export const changePasswordByAdmin = (
  request: IUpdateByAdminRequest
): IActionChangePasswordByAdmin => ({
  type: EUserType.ADMIN_CHANGE_PASSWORD,
  payload: { request }
});

const defaultState: IReduxUserState = {
  data: {
    id: "",
    firstName: "",
    lastName: "",
    fullName: "",
    avatar: "",
    email: "",
    phoneNumber: null,
    userName: "",
    roles: Roles.Member,
    position: "",
    address: null,
    managerId: null,
    birthDay: null,
    manager: null
  },
  listData: [],
  error: null,
  success: null
};

export default function userReducer(
  state: IReduxUserState = defaultState,
  action: IActionUser
): IReduxUserState {
  switch (action.type) {
    case EUserType.SET_USER_STATE:
      return {
        ...state,
        data: action.payload.data
      };

    case EUserType.SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notification: (state.notification ?? 0) + 1
      };

    case EUserType.SET_ITAG:
      return {
        ...state,
        itags: action.payload.itags
      };

    case EUserType.SET_USERS_STATE:
      return {
        ...state,
        listData: action.payload.listData
      };

    case EUserType.CREATE_USER_FAILED:
      return {
        ...state,
        error: action.payload.message
      };

    case EUserType.CLEAR_USER:
      return {
        data: defaultState.data
      };

    case EUserType.CREATE_USER:
      return {
        ...state,
        data: defaultState.data
      };

    case EUserType.CREATE_SUCCESS:
      return {
        ...state,
        success: true
      };

    case EUserType.ADMIN_UPDATE_SUCCESS:
      return {
        ...state,
        success: true
      };

    default:
      return state;
  }
}
