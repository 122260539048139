
import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StageAreaWrapper = styled(Box)((theme) => ({
    display: "flex", flexDirection: "column",
    borderRight: "1px solid #dbdbdb",
    height: "100%",
    "& .hidden": {
        display: "none"
    },
    backgroundColor: "#F6F6F6",
}));