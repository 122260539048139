import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Modal from "../../../components/Modal";
import * as Yup from "yup";
import ControlForm from "../../../components/ControlForm";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface IProps {
  open: boolean;
  handleClose: () => void;
}

interface DataForm {
  option: string;
}

const ManageDragModal = ({ open, handleClose }: IProps) => {
  const options = [
    {
      title: "Không thể kéo ngược",
      value: "Không thể kéo ngược"
    },
    {
      title: "Chỉ có thể di chuyển về giai đoạn gần nhất(giai đoạn trước)",
      value: "Chỉ có thể di chuyển về giai đoạn gần nhất(giai đoạn trước)"
    },
    {
      title: "Có thể quay lại các giai đoạn cụ thể (được chọn)",
      value: "Có thể quay lại các giai đoạn cụ thể (được chọn)"
    },
    {
      title: "Có thể quay lại bất kỳ giao đoạn nào trước đó",
      value: "Có thể quay lại bất kỳ giao đoạn nào trước đó"
    }
  ];
  const onSubmit = (event: DataForm) => {
    console.log(event);
  };

  const validationSchema = Yup.object().shape({}) as any;
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });

  return (
    <Modal
      title="QUẢN LÍ TUỲ CHỌN KÉO NGƯỢC"
      textSubmit="Cập nhật"
      textClose="Bỏ qua"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => {
        reset();
        handleClose();
      }}
      width={"unset"}
    >
      <form
        style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlForm title="Các tuỳ chọn kéo ngược" classname="brand-form">
          <Controller
            control={control}
            name={"option"}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  labelId="simple-select-label"
                  id="option-select"
                  IconComponent={ExpandLessIcon}
                  sx={{ fontSize: 13, height: "100%" }}
                  size="small"
                >
                  {options.map((value, index) => (
                    <MenuItem
                      sx={{ fontSize: 13 }}
                      value={value.value}
                      key={index}
                    >
                      {value.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </ControlForm>
      </form>
    </Modal>
  );
};
export default ManageDragModal;
