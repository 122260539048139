import { Box, Button, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";

type Props = {
     isSuccess?: boolean,
     placeholder?: string,
     input?: string,
     countResult?: number,
     clear?: Function,
     search?: Function,
     value?: string,
     onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
};

const SearchInput = (props: Props) => {
     const { isSuccess, placeholder, countResult, clear, search, value, onChange } = props;

     function handleSearch() {
          console.error("Search");
          if (search) search();
     }

     function handleClear() {
          if (clear) clear();
     }

     return (
          <Box display="flex" alignItems="center">
               <TextField
                    placeholder={placeholder}
                    size="small"
                    variant="outlined"
                    type="search"
                    InputProps={{
                         endAdornment: <InputAdornment position="end">
                              <Button sx={{ marginLeft: "8px" }} onClick={handleSearch}>
                                   <SearchIcon />
                              </Button>
                              {
                                   isSuccess && <Box display="flex">
                                        <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "0 8px" }} />
                                        <Button size="small" color="error" onClick={handleClear}>
                                             Xoá tìm kiếm
                                        </Button>
                                   </Box>
                              }
                         </InputAdornment>,
                    }}
                    value={value}
                    onChange={onChange}
               />
               {isSuccess &&
                    <Typography
                         variant="caption"
                         color="GrayText"
                         fontStyle="italic"
                         marginLeft="10px">
                         Tìm thấy {countResult ?? 0} kết quả
                    </Typography>}
          </Box >
     );
};

export default SearchInput;
