import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  PlanPopoverWrapper,
  ProjectPopoverWrapper,
  SidebarWrapper
} from "./styles";
import { Avatar, ControlForm, ImageCustom, Modal, PlanBox } from "..";
import AvatarImage from "../../assets/images/temp/temp_avatar.png";
import { Button, Divider, Tooltip } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ArrowLeft from "../../assets/images/common/icon_arrow_left_line_black.svg";
import IconPlan from "../../assets/images/common/icon_plan_fill_blue.svg";
import IconCheck from "../../assets/images/common/icon_check_line_green.svg";
import IconPlus from "../../assets/images/common/icon_plus_line_white.svg";
import IconLink from "../../assets/images/common/icon_link_line_grey.svg";

import { sidebarItems } from "../../routes";
import InputForm from "../controls/InputForm";

type Props = {
  onMobileClose: () => void;
  openMobile: boolean | undefined;
};

const DashboardSidebar = ({ onMobileClose, openMobile }: Props) => {
  const location = useLocation();
  const [notExpanded, setNotExpanded] = useState<boolean>(false);
  const [openCreatePJ, setOpenCreatePJ] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElProject, setAnchorElProject] =
    useState<HTMLButtonElement | null>(null);
  const [isHasPJ, setIsHasPJ] = useState<boolean>(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const sidebarData = sidebarItems();

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(`${"Please enter username"}`),
    password: Yup.string().required(`${"Please enter password"}`)
  }) as any;

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    defaultValues: {
      username: null,
      password: null
    },
    resolver: yupResolver(validationSchema)
  });

  const projectData: {
    name: string;
    domain: string;
    href: string;
    avatar: string;
  }[] = [
    {
      name: "Project Name fix-one -line fix-one-line fix-one-line fix-one-line fix-one-line",
      domain:
        "mikotech.vn fix-one -line fix-one-linef ix-one-linef ix-one-li nefix-one-line",
      href: "/",
      avatar: AvatarImage
    },
    {
      name: "Project Name",
      domain: "mikotech.vn",
      href: "/",
      avatar: AvatarImage
    },
    {
      name: "Project Name",
      domain: "mikotech.vn",
      href: "/",
      avatar: AvatarImage
    }
  ];

  return (
    <>
      <PlanPopoverWrapper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <PlanBox />
      </PlanPopoverWrapper>

      {/* <ProjectPopoverWrapper
        open={Boolean(anchorElProject)}
        anchorEl={anchorElProject}
        onClose={() => setAnchorElProject(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        {projectData.length > 0 && (
          <>
            <ul className="project-list">
              {projectData.map(pj => (
                <li key={pj.domain}>
                  <Link to={pj.href}>
                    <div className="infor-box">
                      <Avatar
                        src={pj.avatar}
                        alt="Avatar-Image"
                        width={"36px"}
                        height={"36px"}
                        className="project-avatar"
                      />
                      <div className="project-name">
                        <p className="name-project fit-one-line">{pj.name}</p>
                        <p className="name-domain fit-one-line">{pj.domain}</p>
                      </div>
                    </div>
                    <ImageCustom
                      src={IconCheck}
                      alt="Icon-Check"
                      width={"24px"}
                      height={"24px"}
                      className="icon-check"
                    />
                  </Link>
                </li>
              ))}
            </ul>
            <Divider />
          </>
        )}
        <Button
          size="xsmall"
          variant="contained"
          className="create-button"
          onClick={_ => setOpenCreatePJ(true)}
        >
          <ImageCustom
            src={IconPlus}
            alt="Plus-Icon"
            width={"18px"}
            height={"18px"}
          />
          <p>Tạo dự án mới</p>
        </Button>
      </ProjectPopoverWrapper> */}

      {/* <Modal
        title="Create new Project"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  eiusmo"
        open={openCreatePJ}
        onSubmit={() => {}}
        onClose={() => setOpenCreatePJ(false)}
      >
        <form style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <ControlForm
            title="Name"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            classname="brand-form"
          >
            <InputForm
              placeholder="Your project name "
              required
              name="username"
              errors={errors}
              control={control}
              size="small"
            />
          </ControlForm>
          <ControlForm
            title="Your Domain"
            infor="Làm nổi bật sản phẩm/dịch vụ bằng tiêu đề thu hút người dùng."
            classname="brand-form"
          >
            <InputForm
              placeholder="Your Project Domain"
              required
              name="username"
              errors={errors}
              control={control}
              size="small"
              startIcon={IconLink}
              startText="https://"
            />
          </ControlForm>
        </form>
      </Modal> */}

      <SidebarWrapper notexpanded={notExpanded}>
        <Button
          className={`expanded-button ${notExpanded ? "notExpanded" : ""}`}
          size="large"
          variant="text"
          onClick={() => setNotExpanded(!notExpanded)}
        >
          <ImageCustom
            src={ArrowLeft}
            alt="Arrow-Left"
            width={"24px"}
            height={"24px"}
          />
        </Button>
        <div className="sidebar-box">
          <div className="project-infor-wrapper">
            {isHasPJ ? (
              <Button
                className={`project-infor ${notExpanded ? "notExpanded" : ""} ${!!anchorElProject ? "active" : ""}`}
                size="large"
                variant="text"
                onClick={event => setAnchorElProject(event.currentTarget)}
              >
                <div className="infor-box">
                  <Avatar
                    src={AvatarImage}
                    alt="Avatar-Image"
                    width={"36px"}
                    height={"36px"}
                    className="project-avatar"
                  />
                  <div className="project-name">
                    <p className="name-project fit-one-line">
                      Project Name fix-one -line fix-one-line fix-one-line
                      fix-one-line fix-one-line
                    </p>
                    <p className="name-domain fit-one-line">
                      mikotech.vn Project Name fix-one -line fix-one-line
                      fix-one-line fix-one-line fix-one-line
                    </p>
                  </div>
                </div>
                <ImageCustom
                  src={ArrowLeft}
                  alt="Arrow-Left"
                  width={"24px"}
                  height={"24px"}
                  className="arrow-right"
                />
              </Button>
            ) : (
              <></>
              // <Button
              //   size="xsmall"
              //   variant="contained"
              //   className={`create-project-button ${notExpanded ? "notExpanded" : ""}`}
              //   onClick={_ => setOpenCreatePJ(true)}
              // >
              //   <ImageCustom
              //     src={IconPlus}
              //     alt="Plus-Icon"
              //     width={"18px"}
              //     height={"18px"}
              //   />
              //   <p>Tạo dự án mới</p>
              // </Button>
            )}
          </div>
          <ul
            className={`sidebar-menu ${notExpanded ? "notExpanded" : ""} scrollbar-small`}
          >
            {sidebarData.map(sidebar => (
              <li
                key={sidebar.title}
                className={`sidebar-item ${notExpanded ? "notExpanded" : ""}`}
              >
                <p
                  className={`sidebar-title ${notExpanded ? "notExpanded" : ""}`}
                >
                  {sidebar.title}
                </p>
                <ul className="sidebar-children">
                  {sidebar.children &&
                    sidebar.children.map((child, idx) => {
                      let isActive = location.pathname
                        .split("/")
                        .splice(1)
                        .includes(
                          (child?.href ?? "6321v3f2112fee").replace("/", "")
                        );
                      return (
                        <li
                          key={idx}
                          className={`children-item ${isActive ? "active" : ""}`}
                        >
                          <Tooltip
                            title={!notExpanded ? "" : child?.title}
                            placement="right"
                          >
                            <Link to={child.href || "/"}>
                              <div className="icon-box">
                                <ImageCustom
                                  src={child?.icon?.default}
                                  alt="Sidebar-Icon"
                                  width={"20px"}
                                  height={"20px"}
                                  className={`box-default ${isActive ? "active" : ""}`}
                                />
                                <ImageCustom
                                  src={child?.icon?.active}
                                  alt="Sidebar-Icon"
                                  width={"20px"}
                                  height={"20px"}
                                  className={`box-active ${isActive ? "active" : ""}`}
                                />
                              </div>
                              <p
                                className={`${notExpanded ? "notExpanded" : ""}`}
                              >
                                {child?.title}
                              </p>
                            </Link>
                          </Tooltip>
                        </li>
                      );
                    })}
                </ul>
              </li>
            ))}
          </ul>
          {/* <div
            className={`plan-box-wrapper ${notExpanded ? "notExpanded" : ""}`}
          >
            <PlanBox className="plan-box" />
            <Button
              size="xsmall"
              variant="contained"
              className="not-expanded-button"
              onClick={event => setAnchorEl(event.currentTarget)}
              color="secondary"
            >
              <ImageCustom
                src={IconPlan}
                alt="Plan-Icon"
                width={"18px"}
                height={"18px"}
              />
            </Button>
          </div> */}
        </div>
      </SidebarWrapper>
    </>
  );
};

export default DashboardSidebar;
