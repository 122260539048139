import { yupResolver } from "@hookform/resolvers/yup";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { ControlForm, Modal } from "../../../components";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import variableStyles from "../../../theme/variable-styles";
import { IValue } from "../../../models/common/models.type";
import palette from "../../../theme/palette";

interface IProps {
  open: boolean;
  handleClose: () => void;
}

interface DataForm {
  duration1: boolean;
  duration2: boolean;
  requiredComplete: IValue;
  allowAdd: boolean;
}

const AdvancedOptionsModal = ({ open, handleClose }: IProps) => {
  const requiredCompleteOptions: IValue[] = [
    {
      title: "Không bắt buộc",
      value: false
    },
    {
      title: "Bắt buộc",
      value: true
    }
  ];
  const onSubmit = (event: DataForm) => {
    console.log(event);
  };

  const validationSchema = Yup.object().shape({}) as any;
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    register,
    formState: { errors }
  } = useForm<DataForm>({
    defaultValues: {
      duration1: false,
      duration2: false,
      allowAdd: false
    },
    resolver: yupResolver(validationSchema)
  });

  return (
    <Modal
      title="TUỲ CHỌN NÂNG CAO"
      textSubmit="Cập nhật"
      textClose="Bỏ qua"
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      onClose={() => {
        reset();
        handleClose();
      }}
      width={"500px"}
    >
      <form
        style={{ display: "flex", flexDirection: "column", gap: "16px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlForm title="Thời hạn" classname="brand-form">
          <Controller
            control={control}
            name={"duration1"}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                control={
                  <Checkbox
                    {...register("duration1")}
                    sx={{ color: variableStyles.NaturalColor500 }}
                  />
                }
                sx={{
                  marginRight: 0,
                  marginLeft: "-7px",
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: variableStyles.NaturalColor900
                  }
                }}
                label="Cho phép người thực thi cập nhật thời giạn cho giai đoạn tiếp theo"
              />
            )}
          />
          <Controller
            control={control}
            name={"duration2"}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                sx={{
                  marginRight: 0,
                  marginLeft: "-7px",
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: variableStyles.NaturalColor900
                  }
                }}
                control={
                  <Checkbox
                    {...register("duration1")}
                    sx={{ color: variableStyles.NaturalColor500 }}
                  />
                }
                label="Bỏ qua ngày chủ nhật"
              />
            )}
          />
        </ControlForm>
        <ControlForm
          title="Yêu cầu hoàn thành các công việc trứơc khi chuyển giai đoạn"
          classname="brand-form"
        >
          <Controller
            control={control}
            name={"requiredComplete"}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  size="small"
                  labelId="simple-select-label"
                  id="requiredComplete-select"
                  IconComponent={ExpandLessIcon}
                  sx={{
                    fontSize: 13,
                    height: "100%"
                  }}
                >
                  {requiredCompleteOptions.map((value, index) => (
                    <MenuItem
                      sx={{ fontSize: 13 }}
                      value={value.value}
                      key={index}
                    >
                      {value.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </ControlForm>
        <Controller
          control={control}
          name={"allowAdd"}
          render={({ field }) => (
            <FormControlLabel
              {...field}
              control={
                <Checkbox
                  {...register("allowAdd")}
                  sx={{ color: variableStyles.NaturalColor500 }}
                />
              }
              sx={{
                marginRight: 0,
                marginLeft: "-7px",
                "& .MuiTypography-root": {
                  fontSize: 13,
                  color: variableStyles.NaturalColor900
                }
              }}
              label="Cho phép thành viên có thể xem nhiệm vụ hoặc người theo dõi nhiệm vụ được thêm công việc tại giai đoạn này"
            />
          )}
        />
      </form>
    </Modal>
  );
};

export default AdvancedOptionsModal;
