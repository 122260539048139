import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { createRoot } from "react-dom/client";
import reduxStore from "./redux/store";
import { Provider } from "react-redux";
// Import Swiper styles

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <Provider store={reduxStore.store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register({
//   onUpdate: sw => {
//     if (sw.waiting) {
//       sw.waiting.postMessage({ type: "SKIP_WAITING" });
//     }
//   }
// });
