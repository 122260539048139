import { UniqueIdentifier } from "@dnd-kit/core";
import { envConfig } from "../../config/env.config";
import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import {
  ICreateWorkflowRequest,
  IGetWorkflowsRequest,
  IWorkflowByTypeResponse,
  IWorkflow,
  IWorkflowSearchParams,
  ITrackersManagements
} from "../../models/workflow";
import ApiEndpoints from "../remote/apiEndpoints";
import api from "../remote/baseApi";

const API_WORKFLOW = ApiEndpoints.WORKFLOW;
const baseURL = envConfig.API_ENDPOINT;

const getWorkflows = (
  params: IGetWorkflowsRequest
): Promise<IBasePagingRes<IWorkflowByTypeResponse>> => {
  const workflows = api.get(baseURL, API_WORKFLOW.GET_PAGE.url, {
    params: params
  });

  return workflows;
};

const getWorkflow = (id: string): Promise<IBaseResponseModel<IWorkflow>> => {
  const workflow = api.get(baseURL, API_WORKFLOW.GET_SINGLE.url + `/${id}`);
  return workflow;
};

const createWorkflow = (
  params: ICreateWorkflowRequest
): Promise<IBaseResponseModel<string>> => {
  return api.post(baseURL, API_WORKFLOW.CREATE.url, params);
};

const updateWorkflow = (
  id: UniqueIdentifier,
  params: ICreateWorkflowRequest
): Promise<IBaseResponseModel<string>> => {
  return api.put(baseURL, API_WORKFLOW.UPDATE.url, { id, ...params });
};

const closeWorkflow = (
  id: UniqueIdentifier
): Promise<IBaseResponseModel<string>> => {
  return api.put(baseURL, API_WORKFLOW.UPDATE_STATUS.url, { Id: id, Status: true });
};

const deleteWorkflow = (id: string): Promise<IBaseResponseModel<string>> => {
  return api.delete(baseURL, API_WORKFLOW.DELETE.url + `/${id}`);
};

const getTrackerManagement = (
  id: string
): Promise<IBaseResponseModel<ITrackersManagements>> => {
  const workflow = api.get(
    baseURL,
    API_WORKFLOW.GET_TRACKER_MANAGEMENT.url + `/${id}`
  );
  return workflow;
};

const WorkflowService = {
  getWorkflows,
  getWorkflow,
  createWorkflow,
  deleteWorkflow,
  updateWorkflow,
  closeWorkflow,
  getTrackerManagement
};

export default WorkflowService;
