import { Chip } from "@mui/material";
import { Roles, rolesColor, rolesTitle } from "../../../models/common/models.enum";

type Props = {
     roles: Roles
};

const RolesTag = (props: Props) => {
     return <Chip
          label={rolesTitle(props.roles)}
          size="small"
          sx={{
               color: rolesColor(props.roles).color,
               backgroundColor: rolesColor(props.roles).bgcolor,
               fontSize: "12px",
               fontWeight: "500",
               padding: "0 4px"
          }}
     />;
};

export default RolesTag;
