import styled from "@emotion/styled";
import { Box } from "@mui/material";



export const StageColumnWrapper = styled(Box)(({ theme }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    flex: "1 1 auto",
    transition: "opacity 0.2s, min-width 300ms, max-width 300ms ease-in",
    maxWidth: 260,
    minWidth: 260,
    minHeight: "calc(100% - 80px - 52px)",


    "& .StageArea-Cancel": {
        backgroundColor: "#FFE8E8",
    },
    "& .StageHeader-Cancel": {
        backgroundColor: "#FFE8E8",
        color: "#C34343 !important",
        "& p": {
            color: "#C34343 !important"
        }
    },
    "& .StageHeader-Complete": {
        backgroundColor: "#DEFFDB",

    },
    "& .StageArea-Complete": {
        backgroundColor: "#ffffff",

    },
    "& .StageHeader-Failed": {
        backgroundColor: "#FFE8E8",

    },
    "& .StageArea-Failed": {
        backgroundColor: "#FFE8E8",

    },

}));