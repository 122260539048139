import { alpha } from "@mui/material";

const variableStyles = {
  colorWhite: "#FFFFFF",
  colorBlack: "#000000",
  primaryBackgroundColor: "#FFFFFF",

  // Primary Color (Design Primary Green)
  GreenPrimaryColor950: "#09251F",
  GreenPrimaryColor900: "#114335",
  GreenPrimaryColor800: "#14513F",
  GreenPrimaryColor700: "#16664E",
  GreenPrimaryColor600: "#1C7F5E",
  GreenPrimaryColor500: "#2A9F76",
  GreenPrimaryColor400: "#54BD95",
  GreenPrimaryColor300: "#81D4B2",
  GreenPrimaryColor200: "#B3E7CE",
  GreenPrimaryColor100: "#D7F4E5",
  GreenPrimaryColor50: "#EFFAF5",

  // Natural Color (Design Natural)
  NaturalColor1000: "#0A0B0B",
  NaturalColor950: "#2D393E",
  NaturalColor900: "#48555B",
  NaturalColor800: "#596970",
  NaturalColor700: "#697F87",
  NaturalColor600: "#7E969F",
  NaturalColor500: "#9DB1B8",
  NaturalColor400: "#B5C6CB",
  NaturalColor300: "#D3DCDF",
  NaturalColor200: "#E6ECEE",
  NaturalColor100: "#F1F5F6",
  NaturalColor50: "#F9FBFB",
  NaturalColor0: "#FFFFFF",

  // Secondary Color (Design Blue Secondary)
  blueSecondary950: "#030b14",
  blueSecondary900: "#09213c",
  blueSecondary800: "#0f3765",
  blueSecondary700: "#154d8d",
  blueSecondary600: "#1c63b5",
  blueSecondary500: "#2279dd",
  blueSecondary400: "#4a92e3",
  blueSecondary300: "#72aaea",
  blueSecondary200: "#9ac2f0",
  blueSecondary100: "#c3dbf6",
  blueSecondary50: "#ebf3fc",

  Info900: "#1A4760",
  Info800: "#195573",
  Info700: "#1A648A",
  Info600: "#1F7EAA",
  Info500: "#2E9CC9",
  Info400: "#54B4DC",
  Info300: "#90CEE9",
  Info200: "#CDE8F5",
  Info100: "#E4F1FA",
  Info50: "#F2F9FD",
  InfoA100: alpha("#122E3F", 0.4),
  InfoA200: alpha("#122E3F", 0.6),
  InfoA400: alpha("#122E3F", 0.8),
  InfoA700: alpha("#122E3F", 1),

  Success900: "#14532B",
  Success800: "#166533",
  Success700: "#15803C",
  Success600: "#16A34A",
  Success500: "#22C55E",
  Success400: "#4ADE80",
  Success300: "#86EFAD",
  Success200: "#BBF7D1",
  Success100: "#DCFCE8",
  Success50: "#F0FDF5",
  SuccessA100: alpha("#052E14", 0.4),
  SuccessA200: alpha("#052E14", 0.6),
  SuccessA400: alpha("#052E14", 0.8),
  SuccessA700: alpha("#052E14", 1),

  Error950: "#44080B",
  Error900: "#7E191F",
  Error800: "#97151E",
  Error700: "#B71221",
  Error600: "#DA1B2B",
  Error500: "#ED3E47",
  Error400: "#F66E73",
  Error300: "#FAA4A6",
  Error200: "#FDC9CA",
  Error100: "#FDE2E2",
  Error50: "#FEF2F2",
  ErrorA100: alpha("#371A18", 0.4),
  ErrorA200: alpha("#371A18", 0.6),
  ErrorA400: alpha("#371A18", 0.8),
  ErrorA700: alpha("#371A18", 1),

  Warning900: "#79470A",
  Warning800: "#905804",
  Warning700: "#AE7000",
  Warning600: "#DB9F00",
  Warning500: "#FDCF00",
  Warning400: "#FFEA02",
  Warning300: "#FFF838",
  Warning200: "#FEFF80",
  Warning100: "#FCFFBE",
  Warning50: "#FEFFE6",
  WarningA100: alpha("#472401", 0.4),
  WarningA200: alpha("#472401", 0.6),
  WarningA400: alpha("#472401", 0.8),
  WarningA700: alpha("#472401", 1),

  // Grey (Design Natural)
  grey50: "#F9FBFB",
  grey100: "#F1F5F6",
  grey200: "#E6ECEE",
  grey300: "#D3DCDF",
  grey400: "#B5C6CB",
  grey500: "#9DB1B8",
  grey600: "#7E969F",
  grey700: "#697F87",
  grey800: "#596970",
  grey900: "#48555B",
  greyA100: alpha("#0A0B0B", 0.4),
  greyA200: alpha("#0A0B0B", 0.7),
  greyA400: alpha("#2D393E", 1),
  greyA700: alpha("#0A0B0B", 1),

  // Font Style
  fsNormal: "normal",
  fsItalic: "italic",

  // Font Weight
  fwThin: 100,
  fwExtraLight: 200,
  fwLight: 300,
  fwRegular: 400,
  fwMedium: 500,
  fwSemiBold: 600,
  fwBold: 700,
  fwExtraBold: 800,
  fwBlack: 900,

  // Font Family
  Inter: "Inter",
  Poppins: "Poppins"
};

export default variableStyles;
