import { Avatar, AvatarGroup, Box, Button, Divider } from "@mui/material";
import { StageInfoWrapper } from "./styles";
import { BorderLinearProgress } from "../../../../WorkflowManagement/components/WorkflowItem/border_linear_progress";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BasicMenu, { IMenuItem } from "../../../../../components/Menu/BasicMenu";
import { IRoleBoard, IStage } from "../../../../../models/Stage";
import { EStageType } from "../../../../../models/common/models.enum";
import { IRoleWorkflow } from "../../../../../models/workflow";

export interface StageInfoProps {
  stage: IStage;
  roleWorkflow: IRoleBoard;
  name: string;
  isDragging?: boolean;
  isShowMenu?: boolean;
  onLeftAdd?: () => void;
  onRightAdd?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
  onCreate?: () => void;
  onManageDrag?: () => void;
  onAdvancedOptions?: () => void;
}

const StageInfo = (props: StageInfoProps) => {
  const hasPermission =
    props.roleWorkflow.isCreator ||
    props.roleWorkflow.isProcessManagement ||
    props.stage.roleWorkflowByUser?.isStageManagement;

  const itemsPadding = { width: "100%", padding: "11px 12px" };
  const menuItems: IMenuItem[] = [
    {
      value: "edit",
      label: (
        <Box sx={itemsPadding} onClick={props.onUpdate}>
          Chỉnh sửa giai đoạn
        </Box>
      )
    },
    // {
    //   value: "edit-guideline",
    //   label: (
    //     <Box sx={itemsPadding}>
    //       Hướng dẫn hoàn thành các nhiệm vụ trong giai đoạn
    //     </Box>
    //   )
    // },
    // {
    //   value: "edit-task",
    //   label: (
    //     <Box sx={itemsPadding}>
    //       Tuỳ chỉnh các công việc tạo sẵn trong giai đoạn
    //     </Box>
    //   )
    // },
    // {
    //   value: "edit-drag",
    //   label: (
    //     <Box sx={itemsPadding} onClick={props.onManageDrag}>
    //       Quản lý tuỳ chọn kéo ngược
    //     </Box>
    //   )
    // },
    // {
    //   value: "advanced",
    //   label: (
    //     <Box sx={itemsPadding} onClick={props.onAdvancedOptions}>
    //       Tuỳ chọn nâng cao
    //     </Box>
    //   )
    // },
    {
      value: "delete",
      label: (
        <Box
          sx={{ ...itemsPadding, color: "#ff4e4e" }}
          onClick={props.onDelete}
        >
          Xoá giai đoạn
        </Box>
      )
    },
    {
      value: "divider",
      label: (
        <Divider
          sx={{
            height: "1px",
            width: "90%",
            marginInline: 1
          }}
        />
      )
    },
    {
      value: "left_add",
      label: (
        <Box sx={itemsPadding} onClick={props.onLeftAdd}>
          Thêm 1 giai đoạn bên trái
        </Box>
      )
    },
    {
      value: "right_add",
      label: (
        <Box sx={itemsPadding} onClick={props.onRightAdd}>
          Thêm 1 giai đoạn bên phải
        </Box>
      )
    }
  ];

  return (
    <StageInfoWrapper
      isdragging={props.isDragging === true ? "true" : undefined}
      className={
        "StageHeader " +
        (props.stage.type === EStageType.Cancel ? "StageHeader-Cancel" : "") +
        (props.stage.type === EStageType.Complete ? "StageHeader-Complete" : "")
        // +(props.stage.type === 3 ? "FailedStage" : "")
      }
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          "& #basic-button": {
            "& .menu-button": {
              padding: 0
            }
          }
        }}
      >
        <Box flexGrow={1}>{props.name}</Box>
        {/* <AvatarGroup total={7}>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
          <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
          <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
          <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
        </AvatarGroup> */}
        {props.isShowMenu && hasPermission && (
          <BasicMenu
            key={props.name}
            onChange={() => {}}
            label={<ArrowDropDownIcon sx={{ padding: 0 }} />}
            options={menuItems}
            padding={0}
          />
        )}
        {!props.isShowMenu && <Box height={24} />}
      </Box>
      <BorderLinearProgress
        variant="determinate"
        value={0}
        height={4}
        barcolor="#308fe8"
        background="#dbdbdb"
      />
      <Box
        display={"flex"}
        flexDirection={"row"}
        color={"#8e8e8e"}
        fontSize={10}
      >
        <p>
          {props.stage.taskCards.length}/{props.stage.taskCards.length} NV
        </p>
        {/* <Box sx={{ margin: "0 2px" }}> {"-"} </Box>
        <p>0 Q.HAN</p> */}
      </Box>
    </StageInfoWrapper>
  );
};

export default StageInfo;
