// ----------------------------------------------------------------------

import variableStyles from "../variable-styles";

export default function Button(theme: any) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: variableStyles.GreenPrimaryColor500
          },
          "&.MuiButton-text": {
            padding: "0",
            width: "fit-content",
            height: "unset",
            minWidth: "0",
            "&:hover": {
              boxShadow: "none",
              color: `${variableStyles.GreenPrimaryColor500} !important`,
              backgroundColor: "transparent"
            }
          },
          "&.MuiButton-outlined": {
            "&:hover": {
              boxShadow: "none",
              backgroundColor: variableStyles.NaturalColor50
            }
          },
          "&.MuiButton-containedSecondary": {
            color: variableStyles.blueSecondary700,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: variableStyles.blueSecondary100
            }
          }
        },
        sizeLarge: {
          height: 56
        },
        sizeSmall: {
          height: 40
        },
        sizeXsmall: {
          height: 36,
          borderRadius: 10,
          lineHeight: "14px",
          fontWeight: variableStyles.fwSemiBold,
          padding: "9px"
        },
        sizeMedium: {
          height: 50,
          borderRadius: "10px",
          boxShadow: "none"
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: variableStyles.GreenPrimaryColor500
          }
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary
        },
        containedSecondary: {
          boxShadow: theme.customShadows.blueSecondary
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500]}`,
          "&:hover": {
            backgroundColor: variableStyles.GreenPrimaryColor500
          }
        },
        textInherit: {
          "&:hover": {
            color: variableStyles.GreenPrimaryColor500
          }
        }
      }
    }
  };
}
